import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { BiRightArrowAlt } from 'react-icons/bi';
import { BsArrowLeftCircle, BsSave } from 'react-icons/bs';
import ImagesDnDGrid from '../../../../../../components/imagesDragGrid/ImagesDnDGrid';
import { get } from '../../../../../../utils/localStorage';
import { storeNewAd } from '../../../../../../store/adSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAdCreateApi from '../../useAdCreateApi';

const StepThreePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [date, setDate] = useState({
  //   startDate: '',
  //   endDate: '',
  // });

  // PLEASE REMOVE LINE BELOW AS SOON AS YOU USE VARIABLE POSITION
  // eslint-disable-next-line no-unused-vars
  const [position, setPosition] = useState([]);
  const [images, setImages] = useState([]);
  const { hasFourthStep, createAd } = useAdCreateApi();

  const getImages = async () => {
    const adImages = await get('er_ad');
    setImages(adImages?.images ?? []);
  };

  useEffect(() => {
    getImages();
  }, []);

  // const handleDate = (newDateRange) => setDate(newDateRange);

  // const handleWarning = () => toast.warning(t('mustSelectDate'));

  const handleNextStep = async () => {
    try {
      const newCategory = await get('er_ad');
      dispatch(storeNewAd({ ...newCategory, images })); //, startDate: date.startDate, endDate: date.endDate,

      if (hasFourthStep) {
        navigate('/dashboard/addAds/stepFour');
      } else {
        createAd();
      }
    } catch (error) {
      console.log(error);
      toast.error(t('somethingWentWrong'));
    }
  };

  return (
    <div className='grid min-h-screen pb-4'>
      <div>
        <nav className='grid w-full place-items-center py-[1.625rem] pb-6 xl:flex  xl:items-center'>
          <div className='grid place-items-center text-center xl:place-items-start'>
            <div className='relative w-48 bg-primary-yellow text-left'>
              <h1 className='pl-3  text-[2rem] font-semibold uppercase leading-[2.8125rem] text-[#000000]'>{t('step')} 3</h1>
              <div className='absolute -right-[2rem] top-0 h-[2.8125rem] w-[2.8125rem] rotate-45 bg-[#FAF9F5]'></div>
            </div>
            <h1 className='mt-2  text-[1.625rem] font-medium uppercase leading-[2.25rem] text-[#5A5A5A]'>{t('adImagesAndCalendar')}</h1>
          </div>
          <h1 className='grow text-center  text-[2.625rem] font-medium leading-[3.6875rem] text-[#000000] xl:pr-[19.6875rem]'>
            {t('addAds')}
          </h1>
        </nav>
        <hr />
        {/* Form section */}
        <section className='grid place-items-center gap-8 pb-4 pt-4 xl:pb-20 xl:pt-12'>
          <div className='w-full'>
            <p className='text-[1rem leading-5] mb-4  font-medium text-[#1B1310]'>{t('imagesMaximumFileSize')}</p>
            <p className='text-[1rem leading-5] mb-4  font-medium text-[#1B1310]'>{t('imagesLimit')}</p>
            <div className='flex  flex-grow place-items-center rounded-xl border-[1px] border-[#707070] bg-[#FFFFFF]'>
              <ImagesDnDGrid
                images={images}
                setPosition={setPosition}
                removeImage={() => {
                  console.log('CHECK IF THIS IS NEEDED');
                }}
                setImages={setImages}
              ></ImagesDnDGrid>
            </div>
            <div className='mt-4 w-full'>
              <p className='text-[1rem leading-5] mb-4  font-medium text-[#1B1310]'>{t('firstUploadedPhoto')}</p>
              <p className='text-[1rem leading-5] mb-4  font-medium text-[#1B1310]'>{t('youCanChangeOrder')}</p>
            </div>
          </div>
        </section>
      </div>
      {/* Footer section */}
      <section className='grid place-items-center gap-4 self-end md:flex md:items-center md:justify-between'>
        <Link to='/dashboard/addAds/stepTwo' className='flex items-center justify-end gap-2'>
          <BsArrowLeftCircle className='text-[1.875rem]' />
          <p className=' text-[1.3125rem] font-medium leading-[1.8125rem]'>{t('goBack')}</p>
        </Link>
        <button type='button' onClick={handleNextStep}>
          <div className='flex items-center justify-center gap-2 rounded-[1.25rem] bg-[#FED400] px-10 py-2 text-[1.25rem] leading-6 text-black transition-all duration-200 hover:bg-[#fed400ce]'>
            {!hasFourthStep ? (
              <>
                <span className='text-[2rem]'>
                  <BsSave />
                </span>
                {t('saveAd')}
              </>
            ) : (
              <>
                {t('nextStep')}{' '}
                <span className='text-[2rem]'>
                  <BiRightArrowAlt />
                </span>
              </>
            )}
          </div>
        </button>
      </section>
    </div>
  );
};

export default StepThreePage;
