import { useTranslation } from 'react-i18next';
import first from '@/assets/aboutUs/1.jpg';
import second from '@/assets/aboutUs/2.jpg';
import third from '@/assets/aboutUs/3.jpg';
import { FaCheck } from 'react-icons/fa';

const AboutPageDetails = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className='grid w-full place-items-center'>
        <div className='mt-12 lg:mt-24'>
          <div className='mb-4 block lg:hidden'>
            <p className='text-center text-3xl font-semibold lg:text-left'>{t('aboutPage.ourMissionTitle')}</p>
          </div>
          <div className='flex items-center justify-center'>
            <div className='grid max-w-full grid-cols-1 gap-8 overflow-hidden lg:grid-cols-2 xl:max-w-[1700px]'>
              <div className='flex items-center px-4 lg:px-0'>
                <img src={first} alt='office' className='lg:rounded-r-2xl break21:rounded-l-2xl' />
              </div>
              <div className='flex w-full items-start justify-center lg:justify-start'>
                <div className='pl-4 pr-4 break55:w-160'>
                  <div className='hidden lg:block'>
                    <p className='text-center text-3xl font-semibold lg:text-left'>{t('aboutPage.ourMissionTitle')}</p>
                  </div>
                  <p className='mt-8 text-left'>{t('aboutPage.ourMissionText')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-12 lg:mt-24'>
          <div className='mb-4 block lg:hidden'>
            <p className='text-center text-3xl font-semibold lg:text-left'>{t('aboutPage.whatWeOfferTitle')}</p>
          </div>

          <div className='flex items-center justify-center'>
            <div className='grid max-w-full grid-cols-1 gap-8 overflow-auto lg:grid-cols-2 xl:max-w-[1700px]'>
              <div className='order-last flex w-full items-start justify-center lg:order-first lg:justify-start'>
                <div className='pl-4 pr-4 break55:w-160'>
                  <div className='hidden lg:block'>
                    <p className='text-center text-3xl font-semibold lg:text-left'>{t('aboutPage.whatWeOfferTitle')}</p>
                  </div>
                  <div className='mt-8 flex items-center justify-start text-left'>
                    <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                      <FaCheck className='text-black' size={16} />
                    </div>
                    {t('aboutPage.whatWeOffer1')}
                  </div>
                  <div className='mt-8 flex items-center justify-start text-left'>
                    <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                      <FaCheck className='text-black' size={16} />
                    </div>
                    {t('aboutPage.whatWeOffer2')}
                  </div>
                  <div className='mt-8 flex items-center justify-start text-left'>
                    <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                      <FaCheck className='text-black' size={16} />
                    </div>
                    {t('aboutPage.whatWeOffer3')}
                  </div>
                  <div className='mt-8 flex items-center justify-start text-left'>
                    <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                      <FaCheck className='text-black' size={16} />
                    </div>
                    {t('aboutPage.whatWeOffer4')}
                  </div>
                </div>
              </div>
              <div className='flex items-center px-4 lg:px-0'>
                <img src={second} alt='office' className='lg:rounded-l-2xl break21:rounded-r-2xl' />
              </div>
            </div>
          </div>
        </div>

        <div className='mt-12 lg:mt-24'>
          <div className='mb-4 block lg:hidden'>
            <p className='text-center text-3xl font-semibold lg:text-left'>{t('aboutPage.ourTeamTitle')}</p>
          </div>
          <div className='flex items-center justify-center'>
            <div className='grid max-w-full grid-cols-1 gap-8 overflow-hidden lg:grid-cols-2 xl:max-w-[1700px]'>
              <div className='flex items-center px-4 lg:px-0'>
                <img src={third} alt='office' className='lg:rounded-r-2xl break21:rounded-l-2xl' />
              </div>
              <div className='flex w-full items-start justify-center lg:justify-start'>
                <div className='pl-4 pr-4 break55:w-160'>
                  <div className='hidden lg:block'>
                    <p className='text-center text-3xl font-semibold lg:text-left'>{t('aboutPage.ourTeamTitle')}</p>
                  </div>
                  <p className='mt-8 text-left'>
                    <span className={'font-bold'}>{t('aboutPage.ourTeamFounder')}</span> {t('aboutPage.ourTeam1')}
                  </p>
                  <p className='mt-8 text-left'>
                    <span className={'font-bold'}>{t('aboutPage.ourTeamProgrammers')}</span> {t('aboutPage.ourTeam2')}
                  </p>
                  <p className='mt-8 text-left'>
                    <span className={'font-bold'}>{t('aboutPage.ourTeamMarketing')}</span> {t('aboutPage.ourTeam3')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mx-auto my-40 max-w-4xl text-center xl:max-w-5xl'>
        <p className='my-4 text-xl font-bold'>{t('aboutPage.footerText1')}</p>
        <p className='my-4 text-xl font-bold'>{t('aboutPage.footerText2')}</p>
        <p className='my-4 text-xl font-medium'>{t('aboutPage.yourTeam')}</p>
      </section>
    </>
  );
};

export default AboutPageDetails;
