import ContactPageHeader from './components/contactPageHeader/ContactPageHeader';
import ContactPageForm from './components/contactPageForm/ContactPageForm';
import Layout from '@/components/Layout/Layout.jsx';

const ContactPage = () => {
  return (
    <Layout>
      <main className='w-full'>
        <ContactPageHeader />
        <ContactPageForm />
      </main>
    </Layout>
  );
};

export default ContactPage;
