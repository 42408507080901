import { useTranslation } from 'react-i18next';

const NoResultMessage = () => {
  const { t } = useTranslation();

  return (
    <div className='mx-auto flex h-32 w-full items-center justify-center'>
      <p className='text-xl text-gray-500'>{t('noResults')}</p>
    </div>
  );
};

export default NoResultMessage;
