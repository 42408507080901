import { BsArrowLeftCircle, BsCardList } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import FormRow from '../../../../../components/formRow/FormRow';
import { MdOutlineCreditCard } from 'react-icons/md';
import PaymentsService from '../../../../../services/PaymentService';
import base64ToBlob from '../../../../../utils/base64ToBlob';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const StepTwo = () => {
  const location = useLocation();

  const ipayButton = useRef();

  const { t } = useTranslation();
  let user = JSON.parse(localStorage.getItem('er_user'));
  const [packagesInfo] = useState([
    { id: 1, name: t('standardAd30'), price: t('standardAd30Price'), type: "standard" },
    { id: 2, name: t('prominentAd7'), price: t('prominentAd7Price'), type: "prominent" },
    { id: 3, name: t('prominentAd360'), price: t('prominentAd360Price'), type: "prominent" },
    { id: 4, name: t('standardAd90'), price: t('standardAd90Price'), type: "standard", },
    { id: 5, name: t('prominentAd15'), price: t('prominentAd15Price'), type: "prominent" },
    { id: 6, name: t('premiumAd15'), price: t('premiumAd15Price'), type: "premium" },
    { id: 7, name: t('standardAd180'), price: t('standardAd180Price'), type: "standard" },
    { id: 8, name: t('prominentAd30'), price: t('prominentAd30Price'), type: "prominent" },
    { id: 9, name: t('premiumAd30'), price: t('premiumAd30Price'), type: "premium" },
    { id: 10, name: t('standardAd365'), price: t('standardAd365Price'), type: "standard" },
    { id: 11, name: t('prominentAd180'), price: t('prominentAd180Price'), type: "prominent" },
  ]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [pibNumber, setPibNumber] = useState('');
  const [adsIds, setAdsIds] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const [paymentConfig, setPaymentConfig] = useState(null);

  const [labelTextColor, setLabelTextColor] = useState('text-red-500');
  let minValue = 9;
  let maxValue = 13;

  useEffect(() => {
    setAdsIds(location.state);
    window.scrollTo(0, 0);
  }, [location.state]);

  const getConfig = async () => {
    try {
      if (adsIds.length === 0 || adsIds === null) {
        toast.warn(t('goBackSelectAds'));
        return false;
      }
      if (selectedPackage === null) {
        toast.warn(t('selectPackage'));
        return false;
      }
      const response = await PaymentsService.getConfig({ packageId: selectedPackage, adsIds: adsIds });
      setPaymentConfig(response.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handlePackage = (id) => {
    setSelectedPackage(id);
  };

  const handleTINChange = (event) => {
    setPibNumber(event.target.value.replace(/\D/g, ''));

    const value = event.target.value;
    if (value.length >= minValue && value.length <= maxValue) {
      event.target.classList.remove('border-red-500');
      event.target.classList.add('border-green-500');
      event.target.classList.remove('text-red-500');
      event.target.classList.add('text-green-500');

      setLabelTextColor('text-green-500');
    } else {
      event.target.classList.remove('border-green-500');
      event.target.classList.add('border-red-500');
      event.target.classList.remove('text-green-500');
      event.target.classList.add('text-red-500');

      setLabelTextColor('text-red-500');
    }
  };

  const handleClickButton = async () => {
    setDisableButton(true);
    if (await getConfig()) setTimeout(() => ipayButton.current.click(), 100);
    setDisableButton(false);
  };

  const handleOfflinePaymentClick = async () => {
    const response = await PaymentsService.getOfflinePayment({ packageId: selectedPackage, adsIds: adsIds });

    var downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = `${response.data.fileName}.pdf`;

    const blobPDF = base64ToBlob(response.data.pdfData, 'application/pdf');
    var blob = new Blob([blobPDF], { type: 'application/pdf' });
    var URL = window.URL || window.webkitURL;
    var downloadUrl = URL.createObjectURL(blob);

    downloadLink.href = downloadUrl;
    document.body.append(downloadLink); // THIS LINE ISN'T NECESSARY
    downloadLink.click();
    document.body.removeChild(downloadLink); // THIS LINE ISN'T NECESSARY
    URL.revokeObjectURL(downloadUrl);
  };

  const standardPacks = packagesInfo.filter(p => p.type === "standard");
  const prominentPacks = packagesInfo.filter(p => p.type === "prominent");
  const premiumPacks = packagesInfo.filter(p => p.type === "premium");

  return (
    <main>
      {/* KORAK 2 */}
      <div className='flex w-full flex-col py-[1.625rem] xl:flex-row xl:items-center'>
        <div className='flex w-full flex-col gap-2 text-left  xl:w-[45%]'>
          <div className='relative w-48 bg-primary-yellow '>
            <h1 className='pl-3  text-[2rem] font-semibold uppercase leading-[2.8125rem] text-[#000000]'>{t('step')} 2</h1>
            <div className='absolute -right-[2rem] top-0 h-[2.8125rem] w-[2.8125rem] rotate-45 bg-[#FAF9F5]' />
          </div>
          <p className='text-md font-medium text-gray-600'>{t('chooseAdPackage')}</p>
        </div>
        <p className='pt-4 text-xl font-medium md:text-2xl xl:text-3xl'>{t('choosePackage')}</p>
      </div>
      <hr />
      <h2 className='pt-2 text-center text-lg font-medium md:text-left'>{t('adPackage')}</h2>

      {/* packages box */}
      <div className='flex w-full gap-x-24 justify-between pt-4'>
        <div className='flex flex-col w-full gap-y-4'>
          {standardPacks.map((pack) => (
            <div
              key={pack.id}
              className={`flex w-full items-center justify-between rounded-md border border-gray-500 px-3 py-2 ${
                selectedPackage === pack.id ? 'bg-[#FFFAE3]' : ''
              }`}
            >
              <div className='grid gap-2'>
                <p className='text-sm'>{pack.name}</p>
                <p className='text-sm'>{pack.price}</p>
              </div>
              <button onClick={() => handlePackage(pack.id)} className='rounded-lg bg-light-yellow border-[1px] border-black px-3 py-1 font-medium text-black'>
                {selectedPackage === pack.id ? t('selected') : t('select')}
              </button>
            </div>
          ))}
        </div>

        <div className='flex flex-col w-full gap-y-4'>
          {prominentPacks.map((pack) => (
            <div
              key={pack.id}
              className={`flex w-full items-center justify-between rounded-md border border-gray-500 px-3 py-2 ${
                selectedPackage === pack.id ? 'bg-[#FFFAE3]' : ''
              }`}
            >
              <div className='grid gap-2'>
                <p className='text-sm'>{pack.name}</p>
                <p className='text-sm'>{pack.price}</p>
              </div>
              <button onClick={() => handlePackage(pack.id)} className='rounded-lg bg-primary-yellow px-3 py-1 font-medium text-black'>
                {selectedPackage === pack.id ? t('selected') : t('select')}
              </button>
            </div>
          ))}
        </div>

        <div className='flex flex-col w-full gap-y-4'>
          {premiumPacks.map((pack) => (
            <div
              key={pack.id}
              className={`flex w-full items-center justify-between rounded-md border border-gray-500 px-3 py-2 ${
                selectedPackage === pack.id ? 'bg-[#FFFAE3]' : ''
              }`}
            >
              <div className='grid gap-2'>
                <p className='text-sm'>{pack.name}</p>
                <p className='text-sm'>{pack.price}</p>
              </div>
              <button onClick={() => handlePackage(pack.id)} className='rounded-lg bg-[#FF4343] px-3 py-1 font-medium text-white'>
                {selectedPackage === pack.id ? t('selected') : t('select')}
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className='flex w-full flex-col place-items-center py-[1.625rem] pb-4 pt-16 xl:items-center'>
        <div className='flex w-full flex-col gap-2 text-left'>
          <div className='relative w-48 bg-primary-yellow '>
            <h1 className='pl-3  text-[2rem] font-semibold uppercase leading-[2.8125rem] text-[#000000]'>{t('step')} 3</h1>
            <div className='absolute -right-[2rem] top-0 h-[2.8125rem] w-[2.8125rem] rotate-45 bg-[#FAF9F5]' />
          </div>
          <p className='text-md font-medium text-gray-600'>{t('choosePaymentMethod')}</p>
        </div>

        <p className='pt-4 text-xl font-medium md:text-2xl xl:text-4xl'>{t('choosePayment')}</p>
      </div>
      <hr />

      {/* INPUTS */}

      <div className='flex w-full flex-col gap-4 pt-2 xl:flex-row'>
        <div className='flex w-full flex-col xl:w-3/5'>
          <div className='flex w-full flex-col gap-4 md:flex-row  '>
            <FormRow
              value={user?.firstName}
              labelText={t('firstName')}
              type='text'
              labelClassName='text-black lg:text-lg pb-1 text-sm md:text-base font-medium'
              className='pointer-events-none m-0 rounded-md border border-black p-1 placeholder:text-sm  xl:w-56  xl:flex-auto'
              readOnly
            />
            <FormRow
              labelClassName='text-black text-sm lg:text-lg pb-1 md:text-base font-medium'
              value={user?.lastName}
              labelText={t('lastName')}
              type='text'
              className='pointer-events-none m-0 rounded-md border border-black p-1 placeholder:text-sm  xl:w-56  xl:flex-auto'
              readOnly
            />
            <FormRow
              labelClassName={`text-black text-sm lg:text-lg pb-1 ${labelTextColor} md:text-base font-medium`}
              placeholder={t('companyTIN')}
              value={pibNumber}
              type='string'
              minValue={9}
              maxValue={13}
              labelText={t('enterTIN')}
              className={`m-0 flex w-32 rounded-md border  border-red-500 p-1 placeholder:text-sm  placeholder:text-red-500 xl:w-32 xl:flex-auto`}
              onChange={handleTINChange}
            />
          </div>
          <p className='w-full pt-4 text-sm text-red-600'>{t('TINInfo')}</p>
        </div>
        <div className='flex w-full flex-col xl:w-2/5'>
          <span className='text-center font-medium md:text-lg xl:text-left'>{t('choosePaymentMethodLower')}:</span>
          <div className='flex w-full flex-col gap-3 pt-3 md:flex-row md:justify-center xl:justify-start'>
            <form action={paymentConfig?.FORM_URL} method='post'>
              <input type='hidden' readOnly name='txntype' value='sale' />
              <input type='hidden' readOnly name='timezone' value='Europe/Belgrade' />
              <input type='hidden' readOnly name='txndatetime' value={paymentConfig?.txndatetime} />
              <input type='hidden' readOnly name='hash_algorithm' value='SHA256' />
              <input type='hidden' readOnly name='hash' value={paymentConfig?.hash} />
              <input type='hidden' readOnly name='storename' value={paymentConfig?.storename} />
              <input type='hidden' readOnly name='mode' value='payonly' />
              <input type='hidden' readOnly name='paymentMethod' value='M' />
              <input type='hidden' readOnly name='chargetotal' value={paymentConfig?.chargetotal} />
              <input type='hidden' readOnly name='currency' value={paymentConfig?.currency} />
              <input type='hidden' readOnly name='responseFailURL' value={paymentConfig?.responseFailURL} />
              <input type='hidden' readOnly name='responseSuccessURL' value={paymentConfig?.responseSuccessURL} />
              <input type='hidden' readOnly name='customParam_adids' value={paymentConfig?.customParam_adids} />
              <input type='hidden' readOnly name='customParam_days' value={paymentConfig?.customParam_days} />
              <input type='hidden' readOnly name='customParam_desc' value={paymentConfig?.customParam_desc} />
              <input type='hidden' readOnly name='customParam_ord' value={paymentConfig?.customParam_ord} />
              <input type='hidden' readOnly name='NUMORD' value={paymentConfig?.NUMORD} />
              <input type='hidden' readOnly name='URLBACK' value={paymentConfig?.URLBACK} />
              <button
                ref={ipayButton}
                disabled={disableButton}
                className={`${
                  disableButton ? 'disabled' : ''
                } md:text-md hidden w-auto items-center  justify-center rounded-full bg-primary-yellow px-3 py-2 text-sm font-medium text-black`}
              >
                <MdOutlineCreditCard size={22} />
                <span className='text-[12px]'>{t('cardPayment')}</span>
              </button>
            </form>

            <button
              onClick={() => handleClickButton()}
              className='md:text-md flex w-auto items-center  justify-center rounded-full bg-primary-yellow px-3 py-2 text-sm font-medium text-black'
            >
              <MdOutlineCreditCard size={22} />
              <span className='text-[12px]'>{t('cardPayment')}</span>
            </button>
            <button
              onClick={() => handleOfflinePaymentClick()}
              className='md:text-md flex w-auto items-center justify-center gap-1 rounded-full bg-primary-yellow px-3 py-2 text-sm font-medium text-black '
            >
              <BsCardList size={22} />
              <span className='text-[12px]'>{t('paymentSlip')}</span>
            </button>
          </div>
        </div>
      </div>
      <div className='flex w-full justify-start pb-2 pt-24'>
        <Link to='/dashboard/payments' className='flex items-center justify-center gap-1'>
          <BsArrowLeftCircle className='text-xl' />
          <p className='text-lg font-medium'>{t('returnBack')}</p>
        </Link>
      </div>
    </main>
  );
};

export default StepTwo;
