import { twMerge } from 'tailwind-merge';

const AboutPageHowSingleCart = ({
  cardClass,
  icon,
  title,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  iconClass,
  titleClass,
  firstClass,
  secondClass,
  thirdClass,
}) => {
  return (
    <article
      className={twMerge(
        `h-full  rounded-[0.6875rem] bg-[#1B1310] px-[1.75rem] pb-[5.5625rem] pt-[3.75rem] text-center shadow-md ${cardClass}`
      )}
    >
      <div
        className={twMerge(
          `mx-auto flex h-[4rem] w-[4rem] items-center justify-around rounded-full bg-[#FFFFFF] text-[2rem] sm:h-[6.25rem] sm:w-[6.25rem] sm:text-[4rem] ${iconClass}`
        )}
      >
        {icon}
      </div>
      <h1 className={twMerge(`mt-8 text-center font-bold  text-[#FED400]  md:text-lg  ${titleClass}`)}>{title}</h1>
      <div className='flex flex-col'>
        <p
          className={twMerge(`mt-8 text-center text-sm leading-8 text-[#FFFFFF] md:mt-[2.4375rem] md:text-base md:leading-8 ${firstClass}`)}
        >
          {firstParagraph}
        </p>
        <p
          className={twMerge(`mt-8 text-center text-sm font-bold uppercase  text-[#FED400] md:mt-[2.4375rem] md:leading-8 ${secondClass}`)}
        >
          {secondParagraph}
        </p>
        <p
          className={twMerge(
            `mt-8 text-center text-sm   leading-8 text-[#FFFFFF] md:mt-[2.4375rem] md:text-base md:leading-8 ${thirdClass}`
          )}
        >
          {thirdParagraph}
        </p>
      </div>
    </article>
  );
};

export default AboutPageHowSingleCart;
