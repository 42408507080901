import { FaArrowRight, FaFacebook, FaInstagram } from 'react-icons/fa';

import FormRow from '../formRow/FormRow';
import { IoMailOpenOutline } from 'react-icons/io5';
import { MdOutlineTimer } from 'react-icons/md';
import NewsLetterService from '../../services/NewsLettersService';
import logo from '../../assets/eRentWhite.png';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  let user = JSON.parse(localStorage.getItem('er_user'));

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    const regex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(email)) {
      try {
        await NewsLetterService.subscribe({ email });
        toast.info(t('successfulNewsLetterSubscribe'));
      } catch (error) {
        console.log(error);
      }
    } else toast.warning(t('emailNotValid'));
  };

  return (
    <footer className='bottom-0 m-auto flex h-full w-full justify-center bg-[#131313] px-4 py-8  text-white lg:px-10 xl:px-20'>
      <div className='flex max-w-8xl flex-col px-6 '>
        {/*Logo, Newsletter*/}
        <div className='flex w-full flex-col items-center justify-center lg:flex-row '>
          <div className='flex w-full flex-col items-center justify-center gap-2 pb-4 sm:flex-row sm:gap-2 md:w-3/4 lg:w-[70%]  lg:pb-0 xl:w-3/5'>
            <a
              onClick={() => {
                document.getElementById('navbar').scrollIntoView({ behavior: 'smooth' });
              }}
              // onClick={() => {
              //   location.pathname === '/' ? '#navbar' : null;
              // }}
              className='flex w-auto'
            >
              <img src={logo} alt='logo' className='w-32 object-cover sm:w-36 md:w-52 lg:w-48 ' />
            </a>

            <div className='flex w-3/5 gap-4 lg:gap-3 xl:w-3/4 xl:gap-8'>
              <div className='mr-0 h-0.5 bg-primary-yellow sm:h-full sm:w-[3px] sm:py-10  xl:h-full'></div>

              <span className='m-0 my-auto w-full pt-2 text-center text-[15px] sm:pt-0 md:pl-2 md:text-start md:text-xl lg:text-lg xl:text-xl  '>
                {t('subscribe')}
                <p className='text-[15px] font-bold md:text-2xl  lg:text-2xl xl:text-3xl'> {t('newsletter')}</p>
              </span>
            </div>
          </div>
          <div className='flex  flex-col items-center justify-center gap-2 sm:flex-row md:w-3/4 xl:pl-1.5 '>
            <FormRow
              type='email'
              name='email'
              placeholder={t('enterYourEmail')}
              value={email}
              onChange={handleEmail}
              className='m-0 h-12 w-full border-2 border-white bg-[#131313] px-0 pl-2 text-center text-base text-white placeholder:text-start  placeholder:font-light placeholder:text-white md:h-16 lg:text-lg'
            />

            <p
              onClick={handleSubmit}
              className='sm:w-22 flex h-12 w-26 cursor-pointer items-center justify-center gap-2 rounded-lg bg-[#FED400] px-1.5 py-[0.6rem] text-black hover:bg-[#E5BF01] md:h-16 md:w-[40%] lg:w-[40%] xl:rounded-xl xl:py-4  xl:font-semibold'
            >
              {t('signIn')} {<FaArrowRight />}
            </p>
          </div>
        </div>
        <hr className='m-auto my-8 h-0.5 w-full  border-t-0 bg-[#4A4A4A] ' />
        {/*Link lists*/}
        <div className='mx-auto flex w-full justify-center  lg:mx-auto'>
          <div className='hidden md:flex md:w-full md:flex-col md:text-center lg:text-left '>
            <label className='text-lg font-bold '>{t('aboutUsERent')}</label>
            <div className='mb-8 h-0.5 w-[31%] bg-amber-300 md:mx-auto lg:mx-0'></div>
            <div className=' mx-auto flex w-4/5 justify-center lg:mx-0 lg:justify-start'>
              <span className='text-left text-base text-white'>
                <p className='mb-4'>
                  {t('everythingThatIs')} <span className='font-bold'>{t('rented')}</span> <br />
                  {t('inOnePlace')}
                </p>
                <p>Solus DOO Beograd</p>
                <p>
                  <span className='font-bold'>PIB</span>: 112360879
                </p>
                <p>
                  <span className='font-bold'>MB</span>: 21658154
                </p>
              </span>
            </div>
            <div className='flex gap-3 pt-3 text-3xl md:justify-center lg:justify-start xl:text-4xl'>
              <a href='https://www.facebook.com/erentoglasi/' name='facebook' aria-label='facebook' target='_blank' rel='noreferrer'>
                <FaFacebook className='text-primary-yellow' />
              </a>

              <a
                href='https://www.instagram.com/erentoglasi?igsh=MXdjc2c2MWU1Z2Rxcg=='
                name='instagram'
                aria-label='instagram'
                target='_blank'
                rel='noreferrer'
              >
                <FaInstagram className='text-primary-yellow' />
              </a>
            </div>
          </div>
          <div className=' hidden lg:flex lg:w-full lg:flex-col'>
            <label className='text-lg font-bold'>{t('usefulLinks')}</label>
            <div className='mb-8 h-0.5 w-[36%] bg-primary-yellow'></div>
            <div className=''>
              <ul className='list-item text-white'>
                <li>
                  <a href='about'>- {t('aboutUsERentLowerCase')}</a>
                </li>
                <li>
                  <a href='learn-more#price-list' rel='noopener noreferrer' className=''>
                    - <span>{t('pricelistNormal')}</span>
                  </a>
                </li>
                <li>
                  <a target='_blank' href='terms-and-conditions'>
                    - {t('termsOfUse')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='hidden lg:flex lg:w-full lg:flex-col'>
            <label className='text-lg font-bold'>{t('exploreERent')}</label>
            <div className='mb-8 h-0.5 w-[34%] bg-primary-yellow'></div>
            <div className=''>
              <ul className='list-item text-white'>
                <li>
                  <span>
                    - <a href='/login'>{t('logInFooter')}</a> {t('or')} <a href='/register'>{t('registerFooter')}</a>
                  </span>
                </li>
                <li>
                  <a href='/products'>- {t('advertisements')}</a>
                </li>
                <li>
                  <a href={`${user ? '/dashboard/addAds' : '/login'}`}>- {t('placeAd')}</a>
                </li>
                <li>
                  <a href='/products'>- {t('searchAd')}</a>
                </li>
                <li className='w-3/4'>
                  <a href='/contact#faq' className='flex gap-1'>
                    - <p>{t('readTheFrequentlyAskedQuestions')}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='justify-center text-center lg:hidden'>
            <div className='items-center pb-4 lg:flex lg:w-full lg:flex-col'>
              <label className='text-lg font-bold'>{t('usefulLinks')}</label>
              <div className='mx-auto mb-8 h-0.5 w-[36%]  bg-primary-yellow text-center'></div>
              <div className=''>
                <ul className='list-item text-white'>
                  <li className='py-1'>
                    <a href='about'>- {t('aboutUsERentLowerCase')}</a>
                  </li>
                  <li className='py-1'>
                    <a href='learn-more#price-list' rel='noopener noreferrer' className=''>
                      - <span>{t('pricelistNormal')}</span>
                    </a>
                  </li>

                  <li className='py-1'>
                    <a target='_blank' href='terms-and-conditions'>
                      - {t('termsOfUse')}
                    </a>
                  </li>
                  <li>
                    <a href='/contact#faq' className='flex justify-center gap-1'>
                      - <p>{t('readTheFrequentlyAskedQuestions')}</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <label className=' text-lg font-bold'>{t('getInTouchUpperCase')}</label>
            <div className='md:[35%] mx-auto mb-3 h-0.5 w-[40%] bg-amber-300 lg:mx-0'></div>
            <div className='flex flex-col items-center pt-1 text-sm md:text-base lg:text-start'>
              <div className='flex w-full flex-col gap-4 pt-2'>
                <div className='flex w-full items-center justify-center gap-2 pt-1 lg:justify-start'>
                  {<MdOutlineTimer className='flex h-full w-14 ' />}
                  <span className='w-[60%] text-start text-sm text-white lg:text-base'>
                    {t('workHour')} <span className='font-bold text-primary-yellow'>24/7</span>
                  </span>
                </div>
                <div className='mx-auto h-0.5 w-4/5 bg-[#4A4A4A] lg:mx-0'></div>
              </div>
              <div className='flex w-full flex-col gap-4 pt-4'>
                <div className='flex w-full items-center justify-center gap-3 pt-1 lg:justify-start xl:pl-1'>
                  {<IoMailOpenOutline className='flex h-full w-12 ' />}
                  <span className='w-[60%] text-start text-sm text-white lg:text-base'>
                    {t('sendUsAnEmail')}{' '}
                    <a href='mailto:info@e-rent.rs' className='font-bold text-primary-yellow'>
                      info@e-rent.rs
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='my-8 flex h-0.5 w-full border-t-0 bg-[#4A4A4A]  md:mt-7' />
        <div className='w-full pt-1'>
          <div className='flex flex-col text-center text-sm md:text-base lg:flex-row lg:items-center lg:justify-center lg:gap-3 lg:text-lg'>
            <span className='text-lg text-white'>© 2023 </span>
            <span className='flex flex-col gap-1 font-semibold text-white md:justify-center lg:flex-row'>
              <span className='text-primary-yellow'>
                eRent <span className='text-white'>-</span>
              </span>{' '}
              <span>{t('rentalOfAllTypesOfServices')}</span>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
