import exchangeIcon from '@/assets/icons/homePageCategories/homePageExchangeGiveAway.svg';
import otherIcon from '@/assets/icons/homePageCategories/homePageOther.svg';
import turizamIcon from '@/assets/icons/homePageCategories/homePageTourismAndVacation.svg';
import ugostiteljstvoIcon from '@/assets/icons/homePageCategories/homePageCatering.svg';
import uslugeIcon from '@/assets/icons/homePageCategories/homePageServices.svg';
import vozilaIcon from '@/assets/icons/homePageCategories/homePageVehiclesMachinesTools.svg';
import exchangeImage from '@/assets/homePageExchangeGiveAway.png';
import otherImage from '@/assets/homePageOther.png';
import turizamImage from '@/assets/homePageTourismAndVacation.png';
import ugostiteljstvoImage from '@/assets/homePageCatering.png';
import uslugeImage from '@/assets/homePageServices.png';
import vozilaImage from '@/assets/homePageVehiclesMachinesTools.png';

export const getCategoryKey = (name) => {
  let text = '';
  switch (name) {
    case 'Turizam i odmor':
      text = 'tourismAndVacation';
      break;
    case 'Ugostiteljstvo':
      text = 'catering';
      break;
    case 'Vozila, mašine i alati':
      text = 'vehiclesMachinesTools';
      break;
    case 'Servisi':
      text = 'services';
      break;
    case 'Menjam/Poklanjam':
      text = 'exchangeGiveAway';
      break;
    case 'Razno':
      text = 'other';
      break;
  }
  return text;
};

export const getCategoryIcon = (name) => {
  let icon = '';
  switch (name) {
    case 'Turizam i odmor':
      icon = turizamIcon;
      break;
    case 'Ugostiteljstvo':
      icon = ugostiteljstvoIcon;
      break;
    case 'Vozila, mašine i alati':
      icon = vozilaIcon;
      break;
    case 'Servisi':
      icon = uslugeIcon;
      break;
    case 'Menjam/Poklanjam':
      icon = exchangeIcon;
      break;
    case 'Razno':
      icon = otherIcon;
      break;
  }
  return icon;
};

export const getCategoryImage = (name) => {
  let image = '';
  switch (name) {
    case 'Turizam i odmor':
      image = turizamImage;
      break;
    case 'Ugostiteljstvo':
      image = ugostiteljstvoImage;
      break;
    case 'Vozila, mašine i alati':
      image = vozilaImage;
      break;
    case 'Servisi':
      image = uslugeImage;
      break;
    case 'Menjam/Poklanjam':
      image = exchangeImage;
      break;
    case 'Razno':
      image = otherImage;
      break;
  }
  return image;
};
