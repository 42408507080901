import AboutPageHowSingleCart from './AboutPageHowSingleCart';
import { CgSoftwareUpload } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { GiMagnifyingGlass } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';

const AboutPageHowToUse = () => {
  const { t } = useTranslation();
  return (
    <section className='mb-[5.8125rem] mt-[5rem] grid place-items-center px-[1rem]'>
      <h1 className=' text-[1rem] font-bold uppercase leading-[1.3125rem] tracking-[0.1875rem] text-primary-yellow'>E-RENT</h1>
      <h1 className='mt-[0.75rem] max-w-[30.125rem] text-center  text-[1.8rem] font-semibold leading-[2.40625rem] leading-[2.75rem] text-[#391400] sm:text-[2.25rem]'>
        {t('howToUse')}
      </h1>
      <div className='mt-[1.8125rem] grid w-full max-w-[82.5625rem] place-items-center gap-2 sm:grid-cols-2 lg:grid-cols-3'>
        <AboutPageHowSingleCart
          icon={<CgSoftwareUpload />}
          title={t('placeAnAd')}
          firstParagraph={t('erentIsEasyToUse')}
          secondParagraph={t('theFirstAd6monthsFree')}
          thirdParagraph={t('importantNote')}
        />
        <AboutPageHowSingleCart
          icon={<GiMagnifyingGlass />}
          title={t('findAnAd')}
          firstParagraph={t('ifYouAreLooking')}
          thirdParagraph={t('typeTheDesiredWord')}
          cardClass={'bg-[#FED400]'}
          iconClass={'bg-[#1B1310] text-[#FED400]'}
          titleClass={'text-[#1B1310]'}
          firstClass={'text-[#1B1310]'}
          thirdClass={'text-[#1B1310]'}
        />
        <AboutPageHowSingleCart
          icon={<FaCheck />}
          title={t('rentExchangeGiveAway')}
          firstParagraph={t('erentIsNotIntermediary')}
          thirdParagraph={t('erentPlaceForFreeExchangeAndGiveAway')} /*t('onOurCalendar') */
          cardClass={'bg-[#EFEFEF] sm:col-span-2 lg:col-span-1'}
          iconClass={'bg-[#FED400] text-[#FFFFFF]'}
          titleClass={'text-[#1B1310]'}
          firstClass={'text-[#1B1310]'}
          thirdClass={'text-[#1B1310]'}
        />
      </div>
    </section>
  );
};

export default AboutPageHowToUse;
