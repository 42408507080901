import axios from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';

const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

axiosInstance.interceptors.request.use((req) => {
  // eslint-disable-next-line no-prototype-builtins
  if (!req.headers.hasOwnProperty('authorization')) {
    req.headers.authorization = localStorage.getItem('er_token');
  }
  req.headers['Content-Type'] = 'application/json';
  if (isDevelopment) req.headers['logging'] = true;
  return req;
});

const errorComposer = (error) => {
  return () => {
    const statusCode = error.response ? error.response.status : null;

    if (statusCode === 404) {
      toast.error(t(error.response.data.message, { defaultValue: t('unexpectedError') }));
    }
    if (statusCode === 400) {
      toast.error(t(error.response.data.message, { defaultValue: t('unexpectedError') }));
    }
    if (statusCode === 401) {
      toast(t('pleaseLogin'));
      window.location = '/';
      localStorage.removeItem('er_token');
      localStorage.removeItem('er_user');
      // TODO: CLEAR ALL FORM LOCAL STORAGE
    }
    if (statusCode === 500) {
      toast.error(t('serverError'));
    }
    if (error.code === 'ERR_NETWORK') {
      toast(t('serverIsNotAvailableError'), { type: 'error', toastId: 'serverNotAvailable' });
    }
  };
};

axiosInstance.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorComposer(error);
  error.handleGlobally();

  return Promise.reject(error);
});

export default axiosInstance;
