import SavedAdsContent from './components/SavedAdsContent';
import SavedAdsPageHeader from './components/SavedAdsPageHeader';

export default function SavedAdsPage() {
  return (
    <div>
      <SavedAdsPageHeader />
      <SavedAdsContent />
    </div>
  );
}
