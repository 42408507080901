import { createSlice } from '@reduxjs/toolkit';

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    category: {},
    subCategories: {},
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
      localStorage.setItem('er_categories', JSON.stringify(action.payload));
    },
    storeCategory: (state, action) => {
      state.category = action.payload;
      localStorage.setItem('er_category', JSON.stringify(action.payload));
    },
    removeCategory: (state) => {
      state.category = {};
      localStorage.removeItem('er_category');
    },
    storeSubCategories: (state, action) => {
      state.subCategories = action.payload;
      localStorage.setItem('er_subcategory', JSON.stringify(action.payload));
    },
    removeSubCategories: (state) => {
      state.subCategories = {};
      localStorage.removeItem('er_subcategory');
    },
  },
});

export const { setCategories, storeCategory, removeCategory, storeSubCategories, removeSubCategories } = categorySlice.actions;
export default categorySlice.reducer;
