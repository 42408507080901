import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import errorImage from '@/utils/errorImage';
import imageMissing from '../../../../../assets/imageMissing.png';
import { t } from 'i18next';
import { twMerge } from 'tailwind-merge';
import { useSelector } from 'react-redux';

const SingleAdTable = ({
  id,
  adName,
  image,
  handleAds,
  check,
  category,
  created,
  paid,
  endDate,
  actions,
  isPaused,
  className,
  openModal,
  setModalFlag,
  uniqueId,
  email,
  role,
  selectedAd,
}) => {
  const { user } = useSelector((state) => state.userStore);
  return (
    <tbody className={twMerge(`font-medium text-[#272D3BE3] ${className}`)}>
      {/* Name of ad */}
      <tr>
        <td className='flex w-full items-center gap-1'>
          {check && user.role !== 'admin' ? (
            <input type='checkbox' checked={selectedAd} onClick={() => handleAds(id)} name='singleAd' className='h-3 w-4' />
          ) : null}

          <img src={image ? image : imageMissing} onError={errorImage} alt='image' className='h-16 w-16 rounded-lg object-contain pl-2' />

          <h1 className='break-all font-semibold '>{adName}</h1>
        </td>

        {/* Email */}
        {role === 'admin' ? (
          <td className='w-[12%]'>
            <h1 className='break-all'>{email}</h1>
          </td>
        ) : null}
        {/* Category */}
        <td>
          <h1>{category}</h1>
        </td>
        {/* Created */}
        <td>
          <h1>{created}</h1>
        </td>
        {/* End date */}
        {endDate ? (
          <td>
            <h1>{endDate}</h1>
          </td>
        ) : (
          <td></td>
        )}
        {/* Paid */}
        <td>
          <h1>{paid}</h1>
        </td>
        {/* Status */}
        <td>
          <div
            onClick={() => {
              // openModal(id);
              // setModalFlag(isPaused ? 'active' : 'inactive');
            }}
            className={`grid place-items-center rounded-xl border px-2 py-0.5 text-base font-light 
            ${isPaused === false ? 'border-[#1BC532] text-[#1BC532]' : 'border-[#C51B1B] text-[#C51B1B] opacity-80'}
            `}
          >
            {!isPaused ? t('active') : t('inactive')}
          </div>
        </td>

        {/* UNIQUE ID oglasa */}
        <td>
          <h1 className=''>{uniqueId}</h1>
        </td>

        {/* Actions */}
        {actions ? (
          <td className=''>
            <button
              className='p-2 text-[#E5BF01]  '
              onClick={() => {
                openModal(id);
                setModalFlag('editAd');
              }}
            >
              <FiEdit className='text-xl' />
            </button>
            <button
              className='p-2'
              onClick={() => {
                openModal(id);
                setModalFlag('deleteAd');
              }}
            >
              <RiDeleteBin2Fill className='text-xl' />
            </button>
            <button className='p-2' onClick={() => window.open(`/products/details/${id}`, '_blank')} target='_blank'>
              <FaArrowUpRightFromSquare className='text-lg' />
            </button>
          </td>
        ) : (
          <td></td>
        )}
      </tr>
    </tbody>
  );
};

export default SingleAdTable;
