import { useTranslation } from 'react-i18next';

const AdTable = ({ title, items = null, sections = null, text = null, className = '' }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className={'shadow-xl'}>
        <div className='bg-primary-yellow px-4 py-1 text-xl font-semibold uppercase text-black'>{title}</div>

        <div className='px-2 py-4'>
          {items &&
            items.map((item) => (
              <div key={item.days} className='flex items-center justify-between px-6 py-1'>
                <div className='flex items-center justify-start'>
                  <div className='mr-2 inline-block h-1.5 w-1.5 rounded-full bg-black'></div>
                  <span className='font-semibold'>{item.days}</span>&nbsp;{t('days')}
                </div>
                <div>
                  <span className='font-semibold'>{item.price}</span>RSD
                </div>
              </div>
            ))}

          {sections &&
            sections.map((section) => (
              <div key={section.title} className={'mb-3'}>
                <div className='mb-1 ml-6 inline-block rounded-full bg-black px-2 py-1 text-xs uppercase text-primary-yellow'>
                  {section.title}
                </div>
                {section.items.map((item) => (
                  <div key={item.days} className='flex items-center justify-between px-6'>
                    <div className='flex items-center justify-start'>
                      <div className='mr-2 inline-block h-1.5 w-1.5 rounded-full bg-black'></div>
                      <span className='font-semibold'>{item.days}</span>&nbsp;{t('days')}
                    </div>
                    <div>
                      <span className='font-semibold'>{item.price}</span> RSD
                    </div>
                  </div>
                ))}
              </div>
            ))}

          {text && (
            <div className='flex items-center justify-between px-6'>
              <div className='flex items-center justify-start'>
                <div className='mr-2 inline-block h-1.5 w-1.5 rounded-full bg-black'></div>
                <span className='font-semibold'>{text}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdTable;
