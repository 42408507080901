/* eslint-disable react/display-name */
import { forwardRef } from 'react';

export const Item = forwardRef(({ id, listeners, attributes, index, imageUrl, style, ...props }, ref) => {
  const { ...otherProps } = { ...props };
  if (!imageUrl) return;
  return (
    <div
      className={
        'bg-base-100 hover:bg-primary h-28 flex-grow rounded-sm text-xl drop-shadow-[0_1px_2px_rgba(0,0,0,0.25)] md:h-28  ' +
        (props.activeid == 'true' ? 'opacity-50 ' : undefined) +
        (props.drag ? 'shadow-2xl ' : undefined)
      }
      ref={ref}
      style={style}
      {...attributes}
      key={index}
    >
      {imageUrl?.uploaded === true ? (
        <div className='relative flex h-full w-full'>
          <div
            key={index}
            {...otherProps}
            {...listeners}
            alt='image1'
            className='relative h-full w-full  bg-contain bg-center bg-no-repeat object-contain'
            style={{ backgroundImage: `url(${imageUrl.result})` }}
          >
            {' '}
          </div>
        </div>
      ) : (
        <div className='relative flex h-full w-full'>
          <div
            className='h-full w-full  bg-contain bg-center bg-no-repeat object-contain'
            style={{ backgroundImage: `url(${import.meta.env.VITE_IMAGE_URL}${imageUrl})` }}
            key={id}
            {...otherProps}
            {...listeners}
          ></div>
        </div>
      )}
    </div>
  );
});
