/* eslint-disable react/no-unknown-property */
import { BsArrowLeftCircle, BsSave } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { get } from '../../../../../../utils/localStorage';
import { useEffect, useState } from 'react';

import ProductService from '../../../../../../services/ProductService';
import { storeNewAd } from '../../../../../../store/adSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAdCreateApi from '../../useAdCreateApi';

const StepFourPage = () => {
  const { t } = useTranslation();
  const [subCategoryFields, setSubCategoryFields] = useState([]);
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const { createAd } = useAdCreateApi();

  const getAllSubcategories = async () => {
    try {
      const newCategory = await get('er_ad');
      let subCategoriesFields = '';
      if (newCategory.subCategoryId !== '') {
        const response = await ProductService.getSubCategoriesFields(newCategory.subCategoryId);
        subCategoriesFields = response.data;
        setSubCategoryFields(subCategoriesFields);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSubcategories();
  }, []);

  const handleFields = async (e) => {
    try {
      const newCategory = await get('er_ad');

      if (e.target.attributes.inputtype.value === 'boolean') e.target.value = e.target.checked;

      const newField = { name: e.target.name, value: e.target.value, type: e.target.attributes.inputtype.value };
      const indexOfField = fields.findIndex((field) => field.name == e.target.name);

      if (indexOfField !== -1) {
        if (
          (newField.type === 'boolean' && e.target.checked === false) ||
          ((newField.type === 'string' || newField.type === 'number') && e.target.value === '')
        ) {
          fields.splice(indexOfField, 1);
        } else {
          fields[indexOfField] = newField;
        }
        setFields([...fields]);
        dispatch(storeNewAd({ ...newCategory, fields: JSON.stringify([...fields]) }));
      } else {
        setFields([...fields, newField]);
        dispatch(storeNewAd({ ...newCategory, fields: JSON.stringify([...fields, newField]) }));
      }
    } catch (error) {
      console.log(error);
      toast.error(t('somethingWentWrong'));
    }
  };

  return (
    <div className='grid min-h-screen pb-4'>
      <div>
        <nav className='grid w-full place-items-center py-[1.625rem] pb-6 xl:flex  xl:items-center'>
          <div className='grid place-items-center text-center xl:place-items-start'>
            <div className='relative w-48 bg-primary-yellow text-left'>
              <h1 className='pl-3  text-[2rem] font-semibold uppercase leading-[2.8125rem] text-[#000000]'>{t('step')} 4</h1>
              <div className='absolute -right-[2rem] top-0 h-[2.8125rem] w-[2.8125rem] rotate-45 bg-[#FAF9F5]'></div>
            </div>
            <h1 className='mt-2  text-[1.625rem] font-medium uppercase leading-[2.25rem] text-[#5A5A5A]'>{t('additionalAdFields')}</h1>
          </div>
          <h1 className='grow text-center  text-[2.625rem] font-medium leading-[3.6875rem] text-[#000000] xl:pr-[19.6875rem]'>
            {t('addAds')}
          </h1>
        </nav>
        <hr />
        {/* Form section */}
        <section className='grid w-full place-items-center gap-5 pb-8 pt-6 xl:pb-28'>
          {/* string and number */}
          <div className='grid w-full gap-5 sm:grid-cols-2 break9:grid-cols-3 2xl:grid-cols-4'>
            {subCategoryFields.map((subCategoryField) => {
              // dropdown
              if (subCategoryField.type === 'dropdown') {
                return (
                  <div
                    className=' grid w-full rounded-[4px] bg-[#FFFFFF] px-4 py-5 shadow-md shadow-[#00000017]'
                    key={subCategoryField.name}
                  >
                    <label className=' font-medium leading-[1.1875rem] text-[#1B1310]'>{t(subCategoryField.name)}</label>
                    <div className='mt-4 rounded-[4px] border-[1px] border-[#707070] bg-white px-5 py-[10px]'>
                      <select name={subCategoryField.name} className='w-full bg-white'>
                        {subCategoryField.options.map((field) => {
                          return (
                            <option value={field} key={field}>
                              {t(field)}
                            </option>
                          );
                        })}{' '}
                      </select>
                    </div>
                  </div>
                );
              }
              // number
              if (subCategoryField.type === 'number') {
                return (
                  <div
                    className='grid w-full rounded-[4px] bg-[#FFFFFF] px-4 py-5 shadow-md shadow-[#00000017]'
                    key={subCategoryField.name}
                  >
                    <label className=' font-medium leading-[1.1875rem] text-[#1B1310]'>{t(subCategoryField.name)}</label>

                    <input
                      type='number'
                      inputtype={subCategoryField.type}
                      name={subCategoryField.name}
                      onChange={handleFields}
                      className='mt-4 w-full rounded-[4px] border-[1px] border-[#707070] bg-white px-4 py-[10px]'
                    />
                  </div>
                );
              }
              // string
              if (subCategoryField.type === 'string') {
                return (
                  <div
                    className='grid w-full rounded-[4px] bg-[#FFFFFF] px-4 py-5 shadow-md shadow-[#00000017]'
                    key={subCategoryField.name}
                  >
                    <label className=' font-medium leading-[1.1875rem] text-[#1B1310]'>{t(subCategoryField.name)}</label>
                    <input
                      type='text'
                      inputtype={subCategoryField.type}
                      name={subCategoryField.name}
                      onChange={handleFields}
                      className='mt-4 w-full rounded-[4px] border-[1px] border-[#707070] bg-white px-4 py-[10px]'
                    />
                  </div>
                );
              }
            })}
          </div>
          {/* boolean */}
          <div className='w-full columns-1 gap-5 sm:columns-2 break9:columns-3 2xl:columns-4'>
            <div className='w-full rounded-[4px] bg-[#FFFFFF] px-4 shadow-sm shadow-[#00000017]'>
              {subCategoryFields.map((subCategoryField) => {
                if (subCategoryField.type === 'boolean') {
                  return (
                    <div className='flex items-center gap-2 py-3' key={subCategoryField.name}>
                      <input
                        type='checkbox'
                        inputtype={subCategoryField.type}
                        name={subCategoryField.name}
                        onChange={handleFields}
                        className='h-6 w-6'
                      />
                      <label className=' text-[1rem] font-medium leading-6 text-[#1B1310]'>{t(subCategoryField.name)}</label>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </section>
        {subCategoryFields.length === 0 && (
          <div className='items-center justify-center text-center text-xl font-semibold'>{t('noAdditionalFieldsInAds')}</div>
        )}
      </div>

      {/* Footer section */}
      <section className='grid place-items-center gap-4 self-end md:flex md:items-center md:justify-between'>
        <Link to='/dashboard/addAds/stepThree' className='flex items-center justify-end gap-2'>
          <BsArrowLeftCircle className='text-[1.875rem]' />
          <p className=' text-[1.3125rem] font-medium leading-[1.8125rem]'>{t('goBack')}</p>
        </Link>
        <button
          type='button'
          onClick={createAd} //handleSubmit
          className='flex items-center justify-center gap-2 rounded-[1.25rem] bg-[#FED400] px-10 py-2  text-[1.25rem] font-medium leading-6 text-black transition-all duration-200 hover:bg-[#fed400ce]'
        >
          <span className='text-[2rem]'>
            <BsSave />
          </span>
          {t('saveAd')}
        </button>
      </section>
    </div>
  );
};

export default StepFourPage;
