import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProductService from '../../../../services/ProductService';
import dataURLtoFile from '../../../../utils/dataURLtoFile';
import { get, del } from '../../../../utils/localStorage';

const useAdCreateApi = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [hasFourthStep, setHasFourthStep] = useState(false);

  const getAllSubcategorieFields = async () => {
    try {
      const newCategory = await get('er_ad');
      if (newCategory.subCategoryId !== '') {
        const response = await ProductService.getSubCategoriesFields(newCategory.subCategoryId);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateFourthStepState = async () => {
    const subcategoriesFields = await getAllSubcategorieFields();
    setHasFourthStep(subcategoriesFields?.length > 0);
  };

  const createAd = async () => {
    const newAd = await get('er_ad');
    const formData = new FormData();

    const filesToUpload = newAd.images.map((i) => {
      if (i.file) return dataURLtoFile(i.result, i.name);
    });

    Object.entries(newAd).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (newAd.subCategoryId === '') {
      formData.set('subCategoryId', null);
    }

    for (const element of filesToUpload) {
      formData.append('file', element, element.name);
    }

    formData.delete('images');
    try {
      await ProductService.createAd(formData);
      toast.success(t('adSuccessfullyAdded'));
      await del('er_ad');
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateFourthStepState();
  }, []);

  return {
    hasFourthStep,
    createAd,
  };
};

export default useAdCreateApi;
