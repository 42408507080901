import { BsCardHeading, BsFillChatLeftDotsFill } from 'react-icons/bs';
import { FaHome, FaRegRegistered, FaUsers } from 'react-icons/fa';

import { FiLogIn } from 'react-icons/fi';
import { IoIosLogOut } from 'react-icons/io';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const NavigationLinks = () => {
  let user = JSON.parse(localStorage.getItem('er_user'));
  const { t } = useTranslation();

  let logOrDash;

  if (!user) {
    logOrDash = [
      {
        id: 1,
        text: t('logIn'),
        path: 'login',
        icon: <FiLogIn size={24} color='#ffffff' className='mr-[10px]' />,
      },
      {
        id: 2,
        text: t('register'),
        path: 'register',
        icon: <FaRegRegistered size={24} color='#ffffff' className='mr-[10px]' />,
      },
    ];
  } else {
    logOrDash = [
      {
        id: 1,
        text: t('dashboard'),
        path: 'dashboard',
        icon: <MdOutlineSpaceDashboard size={24} color='#ffffff' className='mr-[10px]' />,
      },
      {
        id: 2,
        text: t('signOut'),
        path: '/',
        icon: <IoIosLogOut size={24} color='#ffffff' className='mr-[10px]' />,
      },
    ];
  }

  const navLinks = [
    {
      id: 1,
      text: t('home'),
      path: '/',
      icon: <FaHome size={24} color='#ffffff' className='mr-[10px]' />,
    },
    {
      id: 2,
      text: t('findAd'),
      path: 'products',
      icon: <BsCardHeading size={24} color='#ffffff' className='mr-[10px]' />,
    },
    {
      id: 3,
      text: t('aboutUs'),
      path: 'about',
      icon: <FaUsers size={24} color='#ffffff' className='mr-[10px]' />,
    },
    {
      id: 4,
      text: t('contact'),
      path: 'contact',
      icon: <BsFillChatLeftDotsFill size={24} color='#ffffff' className='mr-[10px]' />,
    },
  ];

  const navLinksDropDown = logOrDash;

  return {
    navLinks,
    navLinksDropDown,
  };
};

export default NavigationLinks;
