import * as Yup from 'yup';

import { AiFillEye, AiOutlineEye } from 'react-icons/ai';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import { BsArrowLeftCircle } from 'react-icons/bs';
import FormRow from '../../components/formRow/FormRow.jsx';
import { GiPadlock } from 'react-icons/gi';
import { MdEmail } from 'react-icons/md';
import SidePreview from '../../assets/side-preview.svg';
import UserService from '../../services/UserServices.js';
import logo from '../../assets/logoLogin1.png';
import { set } from '../../utils/localStorage.js';
import { storeUser } from '../../store/userSlice.jsx';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
  const [viewPass, setViewPass] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const refFocus = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (inputFocused) {
      refFocus.current.focus();
    }
  }, [inputFocused]);

  const handleRememberMe = () => {
    setRememberMe((prev) => !prev);
  };

  const handleViewPass = () => {
    setViewPass((prevViewPass) => !prevViewPass);
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t('yupEmail')).required(t('yupRequired')),
    password: Yup.string().min(5, t('passwordLength')).required(t('yupRequired')),
  });

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (rememberMe) {
        localStorage.setItem('remember', JSON.stringify(values));
      }
      try {
        let res = await UserService.loginUser(values);
        localStorage.setItem('er_token', res.data.token);
        await set('er_saved', res.data.wishList);
        dispatch(storeUser(res.data.user));
        navigate('/');
      } catch (error) {
        console.log(error);
      }
      setInputFocused((state) => !state);
      formik.resetForm();
    },
  });

  const showError = (name) => formik.errors[name] && formik.touched[name] && formik.errors[name];

  useEffect(() => {
    const rememberUser = JSON.parse(localStorage.getItem('remember'));

    if (rememberUser) {
      formik.setValues(rememberUser);
      setRememberMe(true);
    }
  }, []);

  const handleGoogleUser = async (body) => {
    try {
      let res = await UserService.loginWithGoogle(body);
      const { _id, email, firstName, lastName, role } = res.data.user;
      const newUser = { _id, email, firstName, lastName, role };
      localStorage.setItem('er_token', res.data.token);
      await set('er_saved', res.data.wishList);

      dispatch(storeUser(newUser));
      if (location.state) return navigate(location.state);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  const toSaved = location.search.includes('toSaved=true');

  return (
    <main className='grid h-screen lg:grid-cols-2'>
      <div className='hidden bg-primary-yellow lg:grid lg:w-full lg:place-content-center'>
        <img src={SidePreview} className='mx-auto w-full' alt='loginPicture' />
      </div>
      <div className='grid w-full place-items-center'>
        {/*start of all input content */}
        <div className='grid pt-10 text-xs break5:w-112 2xl:text-xl'>
          <Link to='/'>
            <img src={logo} alt='logo' className='m-auto w-3/4 px-12' />
          </Link>
          <h1 className='pt-5 text-center font-medium '>{t('yourERentAccount')}</h1>
          {toSaved && (
            <div className='my-6 flex items-center justify-center px-2 break5:px-0'>
              <p className='w-full flex-wrap text-xs lg:text-sm'>{t('loginPageFavoritesText')}</p>
            </div>
          )}
          {/* start form */}
          <div className='w-full pt-2 '>
            <form onSubmit={formik.handleSubmit} className='flex flex-col gap-3 px-2'>
              <FormRow
                labelText={t('email')}
                labelClassName='font-normal m-0'
                showError={showError('email')}
                className='rounded-md shadow-md '
                type={'text'}
                name={'email'}
                errorClassName='max-w-[400px]'
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder={t('enterYourEmail')}
                icon={<MdEmail />}
                iconClassName='z-10 text-xl text-primary-yellow'
                refFocus={refFocus}
                pError
              />
              <FormRow
                labelText={t('password')}
                text-center
                labelClassName='font-normal'
                showError={showError('password')}
                type={`${viewPass ? 'text' : 'password'}`}
                name={'password'}
                errorClassName='max-w-[400px]'
                value={formik.values.password}
                onChange={formik.handleChange}
                className='rounded-md shadow-md placeholder:translate-y-1'
                placeholder={'***********'}
                icon={<GiPadlock />}
                iconClassName='z-10 text-xl text-primary-yellow'
                iconView={
                  <button type='button' onClick={handleViewPass}>
                    {viewPass ? <AiFillEye /> : <AiOutlineEye />}
                  </button>
                }
                iconViewClassName='text-lg'
                pError
              />
              {/* input field */}
              <div className='flex justify-between px-1 pt-2 max-lg:flex-col lg:pt-2'>
                <div className='flex items-center gap-2 max-lg:justify-center'>
                  <input
                    type='checkbox'
                    name='rememberMe'
                    value={rememberMe}
                    onChange={handleRememberMe}
                    id='rememberMe'
                    className=' h-4 rounded-sm shadow-md checked:accent-white '
                  />
                  <p className=' font-medium '>{t('rememberMe')}</p>
                </div>
                <Link to='/change-password' className='font-medium  text-primary-yellow max-lg:mt-3 max-lg:text-center '>
                  {t('forgetPassword')}
                </Link>
              </div>
              {/* input field */}
              <button
                type='submit'
                className='mt-4 w-full rounded-2xl bg-primary-yellow py-3 font-medium transition-all duration-300 hover:bg-[#d3b000] lg:pt-2'
              >
                {t('signIn')}
              </button>
            </form>

            <div className='mt-3 flex w-full items-center justify-center'>
              <hr className='mr-[0.625rem] h-[0.1875rem] grow bg-[#CDCFDA]' />
              <p className='text-center font-normal text-[#9a9ba3]'>{t('choseAnotherOptionForSignIn')}</p>
              <hr className='ml-[0.625rem] h-[0.1875rem] grow bg-[#CDCFDA]' />
            </div>
            {/* ============================================================================================= */}
            <div className='mt-4 flex justify-center'>
              <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID} className='text-center'>
                <GoogleLogin
                  locale={JSON.parse(localStorage.getItem('lng')) === 'sr' ? 'en' : 'en'}
                  className='bg-slate-600'
                  onSuccess={(credentialResponse) => {
                    handleGoogleUser({ token: credentialResponse.credential });
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>
            </div>

            {/* ============================================================================================= */}
            <div className='mt-4 flex flex-col max-lg:flex-col lg:justify-center '>
              <p className='mt-[0.5rem] text-center  font-normal'>{t('doNotHaveERentAccount')} </p>
              <p className='mt-[0.5rem] text-center text-base font-bold text-black underline lg:text-xl'>
                <Link to='/register'>{t('registerQuicklyAndEasily')}</Link>
              </p>
            </div>
          </div>
          {/* end form */}
        </div>
        <div className='flex h-full w-full items-center justify-end lg:items-end'>
          <Link to='/' className='flex w-full items-center justify-center gap-1 pb-2 lg:justify-end lg:pr-4'>
            <BsArrowLeftCircle />
            <p className='text-xs font-medium 2xl:text-xl'>{t('backToHome')}</p>
          </Link>
        </div>
        {/*end of all input content */}
      </div>
    </main>
  );
};

export default LoginPage;
