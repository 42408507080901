let blackList = [
  'p...',
  'pičke',
  'picke',
  'pederi',
  'гробари',
  'делије',
  'свинје',
  'говна',
  'четници',
  'četnici',
  'grobari',
  'delije',
  'gadovi',
  'svinje',
  'šipci',
  'oca',
  'majku',
  'pizde',
  'govna',
  'говна',
  'п...',
  'пизде',
  'оца',
  'мајку',
  'пичка',
  'курац',
  'смрт',
  'убиј',
  'zakolji',
  'ubij',
  'jebanje',
  'pička',
  'kurac',
  'smrt',
  'jebo vas',
  'penis',
  'лаж',
  'циганин',
  'неистина',
  'лезбејка',
  'skupo',
  'prevara',
  'prevaranti',
  'prevariti',
  'lopovi',
  'lopuže',
  'lopovčine',
  'lopine',
  'mamu vam',
  'mamu vam j...',
  'mamu vam jebem',
  'mater',
  'sranje',
  's...',
  'smrde',
  'smrdi',
  'smrdljivci',
  'smutljivci',
  'j...',
  'jebacu',
  'jebaću',
  'jebati',
  'jebem',
  'jebem li',
  'pusi',
  'puši',
  'pušiti',
  'puši k...',
  'pušite',
  'pušite k ...',
  'pušite kurac',
  'puši krac',
  'kurčina',
  'k...',
  'kurvo',
  'kurvetino',
  'kurvice',
  'kurvi',
  'kurvanje',
  'prostitutka',
  'prostituktke',
  'prostaci',
  'prostitucija',
  'prostaci',
  'prostakuša',
  'sve po spisku',
  '... po spisku',
  'majmuni',
  'majku vam',
  'majku vam jebem',
  'majku vam ...',
  'lopovcine',
  'lopovčine',
  'lopovluk',
  'lažovi',
  'lažovčine',
  'lazovcine',
  'laz',
  'laž',
  'dete ti jebem',
  'jebem ti nerođeno dete',
  'jebem ti majku krvavu',
  'krv ti majčinu jebem',
  'jebaću ti milosnu mater',
  'nanu ti jebem',
  'oca ti jebem u dupe',
  'oca ti jebem',
  'oca ti j…',
  'jebanja',
  'govno',
  'govnari',
  'govnjaivko',
  'govnar',
  'sisa',
  'siso jedna',
  'sisaj',
  'sisaj ga',
  'sisu ti...',
  'sisu ti jebem',
  'pusi k...',
  'pusi kurac',
  'puši kurac',
  'klanje',
  'gabor',
  'gabori',
  'gaboru',
  'smrt',
  'ubicu te',
  'ubico',
  'ubica',
  'ubistvo',
  'ubiti',
  'polomiti',
  'tuča',
  'tući',
  'tuko',
  'krv',
  'krvavu ti',
  'krvavu',
  'krvavu ti majku j...',
  'idiot',
  'idiote',
  'idioti',
  'dilejo',
  'dileja',
  'degenu',
  'degen',
  'kopile',
  'prebiti',
  'prebicu te',
  'retard',
  'retardirani',
  'retardu',
  'retarde',
  'džiberi',
  'džiber',
  'dziber',
  'dziberi',
  'dupe',
  'debeli',
  'govnaru',
  'šiptari',
  'četnici',
  'ustaše',
  'ubice',
  'mrzim',
  'mrzim te',
  'mržnja',
  'leb te jebo',
  'leb te j...',
  'sns',
  'sns govna',
  'sns gamad',
  'gamad',
  'gnjide',
  'gnjido',
  'kurve',
  'kurvice',
  'kur...',
  'kurv...',
  'p*zda',
  'pizda',
  'piz...',
  'drkanje',
  'dr*anje',
  'drk...',
  'drkaj ga',
  'drkaj mi',
  'drkadzija',
  'drkadžija',
  'drkoš',
  'stoko',
  'gaboru',
  'skrndelj',
  'skrndelju',
  'snošaj',
  'koitus',
  'klitoris',
  'popušuš mi muda',
  'muda',
  'mudonja',
  'kurčina',
  'kur*na',
  'popuši mi',
  'popusi mi',
  'popusi',
  'pusi',
  'puši',
  'rugoba',
  'ružno',
  'ruzno',
  'raspad',
  'raspala',
  'raspalo',
  'sranje',
  'sra*e',
  's...',
  'sex',
  'seks',
  'seksualni',
  'prostak',
  'prostakuša',
  'prostitutka',
  'kurvetina',
  'kurva',
  'kurava',
  'anus',
  'štakor',
  'štakori',
  'moron',
  'moroni',
  'majmunčina',
  'majmunčino',
  'nesoju',
  'nesoj',
  'nedojeb',
  'nedojebana',
  'nejebana',
  'nejeban',
  'najebao',
  'najebala',
  'budalo',
  'budala',
  'varaju',
  'varate',
  'varaj',
  'varalice',
  'prevara',
  'prevaranti',
  'prevaren',
  'prevarili',
  'cipov',
  'cica',
  'cupi',
  'curva',
  'žrtva',
  'žvala',
  'žvalavi',
  'žvalo',
  'žbir',
  'ćurko',
  'lezbo',
  'lezbejke',
  'lezbače',
  'lezbace',
  'govna',
  'govnar',
  'govedo',
  'gubo',
  'govno',
  'fuck',
  'fuck you',
  'fucking',
  'duplo dno',
  'dno',
  'dno dna',
  'seljaci',
  'seronje',
  'seronja',
  'ser...',
  'akrep',
  'đoka',
  'đubre',
  'đavo',
  'đokica',
  'šonja',
  'šonjavko',
  'škart',
  'šupak',
  'prevaranti',
  'prevarili',
  'prevara',
  'mrš',
  'marš',
  'majku vam',
  'majku ti',
  'majku...',
  'ustaše',
  'tebra',
];

const checkForbiddenWords = (body) => {
  const filteredBody = body.replace(/(<([^>]+)>|&nbsp;|,|\.)/gi, '');
  const filteredBodyArray = filteredBody.split(' ');
  for (const element of blackList) {
    for (let j = 0; j < filteredBodyArray.length; j++) {
      if (filteredBodyArray[j].toLowerCase() === element) {
        console.log('Forbidden word found: ', element);
        return true;
      }
    }
  }
  return false;
};

export default checkForbiddenWords;
