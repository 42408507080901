import adSlice from './adSlice';
import bannerSlice from './bannerSlice';
import categorySlice from './categorySlice';
import { configureStore } from '@reduxjs/toolkit';
import currencySlice from './currencySlice';
import userSlice from './userSlice';
import filtersSlice from './filtersSlice';

const store = configureStore({
  reducer: {
    filtersStore: filtersSlice,
    userStore: userSlice,
    adStore: adSlice,
    categoryStore: categorySlice,
    bannerStore: bannerSlice,
    currencyStore: currencySlice,
  },
});

export default store;
