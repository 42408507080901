const testimonials = [
  {
    id: 1,
    name: 'Milena P.',
    translationKey: 'testimonials.testimonial1',
  },
  {
    id: 2,
    name: 'Milan D.',
    translationKey: 'testimonials.testimonial2',
  },
  {
    id: 3,
    name: 'Jelena K.',
    translationKey: 'testimonials.testimonial3',
  },
  {
    id: 4,
    name: 'Ivana M.',
    translationKey: 'testimonials.testimonial4',
  },
  {
    id: 5,
    name: 'Ana S.',
    translationKey: 'testimonials.testimonial5',
  },
  {
    id: 6,
    name: 'Marija T.',
    translationKey: 'testimonials.testimonial6',
  },
];

export default testimonials;
