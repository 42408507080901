import { Outlet } from 'react-router-dom';

const Payments = () => {
  return (
    <main className='w-full px-7'>
      <Outlet />
    </main>
  );
};

export default Payments;
