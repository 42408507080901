import { useCallback, useEffect, useState } from 'react';

import testimonials from '@/pages/Home/utils/testimonials.js';
import { useTranslation } from 'react-i18next';
import TestimonialCard from '@/pages/Home/components/TestimonialCard.jsx';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const cardsToShow = () => {
  if (window.innerWidth >= 1024) {
    return 2;
  } else {
    return 1;
  }
};
const HomePageTestimonials = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleNext = useCallback(() => {
    if (currentIndex === testimonials.length - cardsToShow()) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => Math.min(testimonials.length - cardsToShow(), prevIndex + cardsToShow()));
    }
  }, [currentIndex]);

  const handlePrev = useCallback(() => {
    if (currentIndex === 0) {
      setCurrentIndex(testimonials.length - cardsToShow());
    } else {
      setCurrentIndex((prevIndex) => Math.max(0, prevIndex - cardsToShow()));
    }
  }, [currentIndex]);

  useEffect(() => {
    const handleResize = () => {
      const newIndex = Math.min(testimonials.length - cardsToShow(), currentIndex);
      setCurrentIndex(newIndex);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentIndex, windowWidth]);

  useEffect(() => {
    const interval = setInterval(handleNext, 6000);
    return () => clearInterval(interval);
  }, [handleNext, currentIndex]);

  const testimonialSlice = [...testimonials.slice(currentIndex, currentIndex + cardsToShow())];

  return (
    <section className='flex flex-col justify-center pb-8 pt-8'>
      <h3 className='mb-3 text-center text-3xl font-bold text-[#1B1310] md:text-3xl'>{t('testimonials.title')}</h3>
      <div className='mx-auto mb-9 h-1.5 w-[280px] bg-primary-yellow md:mb-10 md:w-[330px]'></div>
      <div className='relative mx-auto drop-shadow-[0_1px_2px_rgba(0,0,0,0.25)]'>
        <div className='flex overflow-x-hidden'>
          <div className='flex cursor-pointer items-center justify-start px-4' onClick={() => handlePrev()}>
            <FaChevronLeft color={'#FDD400'} size={30} />
          </div>
          <div className={`flex gap-2`}>
            {testimonialSlice.map((testimonial) => (
              <TestimonialCard key={testimonial.id + testimonial.name} testimonial={testimonial} />
            ))}
          </div>
          <div className='flex cursor-pointer items-center justify-start px-4' onClick={() => handleNext()}>
            <FaChevronRight color={'#FDD400'} size={30} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageTestimonials;
