import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FiBookmark } from 'react-icons/fi';
import PdfContract from '../../../assets/priceList/contract.pdf';

const QuestionsAnswers = () => {
  const { t } = useTranslation();
  const questionAnswer = [
    {
      id: 1,
      question: t('howPlaceAd'),
      answer: (
        <>
          {t('instructionsForPlacingAd')}
          {
            <Link to={'/'} className='font-semibold'>
              {t('link')}
            </Link>
          }
          .
        </>
      ),
    },
    {
      id: 2,
      question: t('whatIsThePrice'),
      answer: (
        <>
          {t('youCanCheckThePrice')}
          {
            <a href='learn-more#price-list' rel='noopener noreferrer' className='font-semibold'>
              {t('link')}
            </a>
          }
          .
        </>
      ),
    },
    {
      id: 3,
      question: t('areYouTheOwners'),
      answer: t('noWeArePlatformForPlacingAds'),
    },
    {
      id: 4,
      question: t('doYouTakeCommission'),
      answer: t('noWeWorkCommissionFree'),
    },
    {
      id: 5,
      question: t('betterPersonalizedOffer'),
      answer: t('yesItsPossible'),
    },
    {
      id: 6,
      question: t('isItTrueThatAdvertising'),
      answer: t('thatsCorrect'),
    },
    {
      id: 7,
      question: t('canSellHouse'),
      answer: t('weDoNotMediateBuyingSelling'),
    },
    {
      id: 8,
      question: t('canYouGuarantee'),
      answer: t('erentDoesNotProvideGuarantee'),
    },
    {
      id: 9,
      question: t('photosAuthentic'),
      answer: t('erentIsUnableToVerify'),
    },
    {
      id: 10,
      question: t('howToPlaceBanner'),
      answer: (
        <>
          {t('forPlacingBanner')}
          {
            <a href='learn-more#price-list' rel='noopener noreferrer' className='font-semibold'>
              {t('link')}
            </a>
          }{' '}
          .{t('forBannerPlacement')}.
        </>
      ),
    },
    {
      id: 11,
      question: t('createLeaseAgreement'),
      answer: (
        <>
          {t('contractTemplate')}
          {
            <a href={PdfContract} rel='noopener noreferrer' className='font-semibold' target='_blank' download>
              {t('here')}
            </a>
          }
          .
        </>
      ),
    },
    {
      id: 12,
      question: t('shouldContactLawyer'),
      answer: (
        <>
          {t('lawyerCreateContract')}
          {
            <a href='mailto:office@e-rent.rs' className='font-semibold'>
              advokat@e-rent.rs
            </a>
          }
          .
        </>
      ),
    },
    {
      id: 13,
      question: t('howCanIKnowIfMySpace'),
      answer: t('afterPlacingTheAd'),
    },
    {
      id: 14,
      question: t('haveUserAccount'),
      answer: t('postAdYouNeedToHave'),
    },
    {
      id: 15,
      question: t('historySearchedAds'),
      answer: (
        <>
          {t('youAreRegisteredUser')}
          {
            <Link to={'/saved'} className='flex font-semibold'>
              {t('link')} <FiBookmark size={15} className='font-extralight' color='black' />
            </Link>
          }
          .
        </>
      ),
    },
    {
      id: 16,
      question: t('changeMyAdAfter'),
      answer: (
        <>
          {t('detailsOfThAdCan')}
          {
            <span href={'/'} className='font-semibold'>
              info@e-rent.rs
            </span>
          }{' '}
          .{t('forBannerPlacement')}.
        </>
      ),
    },
  ];
  return { questionAnswer };
};

export default QuestionsAnswers;
