import { Outlet } from 'react-router-dom';

const AddAds = () => {
  return (
    <main className=' w-full px-7'>
      <Outlet />
    </main>
  );
};

export default AddAds;
