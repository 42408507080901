import Footer from '../../components/footer/Footer.jsx';
import Navbar from '../../components/navbar/Navbar.jsx';
import { Outlet } from 'react-router-dom';
import SmallSidebar from '../../components/smallSidebar/SmallSidebar.jsx';
import { useState } from 'react';

const StartLayout = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <main className='min-h-screen min-w-full'>
      {showSideBar ? <div className='fixed z-20 h-full w-full bg-black opacity-40' /> : null}
      {/* Ovde ce ici <Navbar /> komponenta sa  linkovima*/}
      <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {/*smallSidebar for mob tel*/}
      <SmallSidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

      <Outlet />
      <Footer />
    </main>
  );
};

export default StartLayout;
