import { Link } from 'react-router-dom';
import { del } from '../../../utils/localStorage';
import { logoutUser } from '../../../store/userSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import userAvatar from '../../../assets/user-avatar.svg';
import userLogged from '../../../assets/userLoggedIn.svg';

const DropDown = ({ openUserMenu, userMenuRef }) => {
  const user = JSON.parse(localStorage.getItem('er_user'));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleUserLogout = async () => {
    dispatch(logoutUser());
    await del('er_saved');
  };

  return (
    <div
      className={`${
        openUserMenu ? 'grid' : 'hidden'
      } absolute right-0 top-20 z-10 w-56 place-items-center overflow-hidden rounded-lg bg-[#FFFFFF] shadow-md shadow-[#CCD5FF80]`}
      ref={userMenuRef}
    >
      {user ? (
        <Link to='/dashboard/settings' className='mb-4 mt-5 flex w-[11.5625rem] gap-4'>
          <div className='grid h-16 w-16 place-items-center rounded-full bg-yellow-100 text-center'>
            <img src={`${user ? userLogged : userAvatar}`} alt='avatar' />
          </div>
          <div className='mt-2 flex-col pr-1'>
            <p className=' font-normal text-[#898989]'>{user ? user.firstName : 'Username'}</p>
          </div>
        </Link>
      ) : null}

      <div className='flex w-full flex-col  text-[#898989]'>
        {user ? <div className='w-full border border-[#272D3B] opacity-10' /> : ''}

        <a className='w-full py-4 pl-2 hover:bg-[#f8f8f8]' href={`${user ? '/dashboard' : '/register'}`}>
          {user ? t('dashboard') : t('register')}
        </a>

        <div className='w-full border border-[#272D3B] opacity-10' />
        {user ? (
          <div onClick={handleUserLogout} className='w-full py-4 pl-2 transition-all duration-200 hover:bg-[#f8f8f8]'>
            {t('signOut')}
          </div>
        ) : (
          <a href='/login' className='w-full py-4 pl-2 transition-all duration-200 hover:bg-[#f8f8f8]'>
            {t('logIn')}
          </a>
        )}
      </div>
    </div>
  );
};

export default DropDown;
