import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './locals/i18next';

import React, { Suspense } from 'react';

import App from './App.jsx';
import { HelmetProvider } from 'react-helmet-async';
import MetaPixel from './components/MetaPixel/MetaPixel.jsx';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { Spinner } from './components/Spinner/index.jsx';
import { ToastContainer } from 'react-toastify';
import store from './store/store';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://de5a6dd02b98621641cb01de113161e8@o4505869874757632.ingest.sentry.io/4505869876658176',
  beforeSend: (event) => {
    console.log(event.exception);
    console.log(event.breadcrumbs);
    return null;
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/e-rent\.rs\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <>
      <Suspense fallback={<Spinner />}>
        <HelmetProvider>
          <Provider store={store}>
            <MetaPixel />
            <App />
          </Provider>
        </HelmetProvider>
      </Suspense>

      <ToastContainer position='top-center' autoClose={2000} />
    </>
  </React.StrictMode>
);
