import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import { useEffect } from 'react';

const PaginationContainer = ({ setCurrentPage, totalCount, paginationClass, currentPage, pageSize }) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const numberOfPages = Math.ceil(totalCount / pageSize);

  const handlePageNumber = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // This effect will be triggered when the currentPage prop changes.
    // It ensures that the state is updated before navigation.
    const searchParams = new URLSearchParams(search);
    searchParams.set('page', currentPage);
    navigate(`${pathname}?${searchParams.toString()}`);
  }, [currentPage, search, pathname, navigate]);
  const addPageButton = ({ page, activeClass }) => {
    return (
      <button
        key={Math.floor(Math.random() * 100000)}
        className={`h-8  w-8 rounded-full  ${activeClass && 'bg-primary-yellow'}`}
        onClick={() => {
          handlePageNumber(page);
          window.scroll(0, 650);
        }}
      >
        {page}
      </button>
    );
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    //firstButton
    pageButtons.push(addPageButton({ page: 1, activeClass: currentPage === 1 }));
    //dotsBefore
    if (currentPage > 3) {
      pageButtons.push(
        <span key={'dots1'} className='pb-2  text-center sm:w-8'>
          ...
        </span>
      );
    }
    //beforeCurrentButton
    if (currentPage !== 1 && currentPage !== 2) {
      pageButtons.push(addPageButton({ page: currentPage - 1, activeClass: false }));
    }
    //currentButton
    if (currentPage !== 1 && currentPage !== numberOfPages) {
      pageButtons.push(addPageButton({ page: currentPage, activeClass: true }));
    }
    //afterCurrentButton
    if (currentPage !== numberOfPages && currentPage !== numberOfPages - 1) {
      pageButtons.push(addPageButton({ page: currentPage + 1, activeClass: false }));
    }
    //dotsAfter
    if (currentPage < numberOfPages - 2) {
      pageButtons.push(
        <span key={'dots2'} className='pb-2 text-center sm:w-8'>
          ...
        </span>
      );
    }
    //lastButton
    pageButtons.push(addPageButton({ page: numberOfPages, activeClass: currentPage === numberOfPages }));

    return pageButtons;
  };

  return numberOfPages > 1 ? (
    <div className={twMerge(`flex w-full gap-1 ${paginationClass}`)}>
      {/* {currentPage !== 1 ? ( */}
      <button
        className='grid h-8 w-8 place-items-center rounded-md bg-primary-yellow'
        onClick={() => {
          let prevPage = currentPage - 1;
          if (prevPage < 1) prevPage = 1;
          handlePageNumber(prevPage);
        }}
      >
        <MdOutlineKeyboardArrowLeft />
      </button>
      {/* ) : null} */}
      <div
        className={`flex h-auto w-auto rounded-full  
        ${numberOfPages < 3 ? 'justify-center' : 'justify-between'} 
         items-center px-1`}
      >
        {renderPageButtons()}
      </div>
      {/* {currentPage !== numberOfPages ? ( */}
      <button
        className='grid h-8 w-8 place-items-center rounded-md bg-primary-yellow'
        onClick={() => {
          let nextPage = currentPage + 1;
          if (nextPage > numberOfPages) nextPage = numberOfPages;
          handlePageNumber(nextPage);
        }}
      >
        <MdOutlineKeyboardArrowRight />
      </button>
      {/* ) : null} */}
    </div>
  ) : null;
};

export default PaginationContainer;
