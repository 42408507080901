import { BiBox, BiSolidBookmark } from 'react-icons/bi';
import { get, set } from '../../utils/localStorage';
import { useCallback, useEffect, useState } from 'react';

import ProductService from '../../services/ProductService';
import { AiOutlineStar } from 'react-icons/ai';
import { FiBookmark } from 'react-icons/fi';
import { ImLocation } from 'react-icons/im';
import { IoDiamondOutline } from 'react-icons/io5';
import { IoIosRemoveCircle } from 'react-icons/io';
import { convertCurrency } from '../../utils/currencyConverter';
import errorImage from '../../utils/errorImage';
import getLanguageVersion from '../../utils/getLanguageVersion';
import { isLoggedIn } from '../../utils/auth';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Card = ({ ad, canRemove = false, refreshData, mainClass, type, homeCard = false }) => {
  const { t, i18n } = useTranslation();
  const [isInWishList, setIsInWishList] = useState(false);
  const { currency, currencySymbol } = useSelector((state) => state.currencyStore);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let background = '';
  let border = '';

  const handleAd = async () => {
    await ProductService.viewAds(ad._id);
    navigate(`/products/details/${ad._id}`);
  };
  const addToWishList = async () => {
    if (isLoggedIn()) {
      let adId = ad._id;
      const response = await ProductService.updateWishList(adId);
      await set('er_saved', response.data);
      await checkIsSaved();
    } else {
      const list = await get('er_saved');

      if (list?.includes(ad._id)) {
        const newList = list.filter((a) => a !== toString(ad._id));
        await set('er_saved', newList);
      } else {
        await set('er_saved', [...list, ad._id]);
      }
    }
  };

  const removeFromWishList = async () => {
    if (isLoggedIn()) {
      const response = await ProductService.deleteWishList(ad._id);
      await set('er_saved', response.data);
      await checkIsSaved();
    } else {
      const list = await get('er_saved');
      const newList = list.filter((a) => a !== ad._id);
      await set('er_saved', newList);
    }

    refreshData();
  };

  const checkIsSaved = useCallback(async () => {
    const savedAds = await get('er_saved');
    setIsInWishList(savedAds?.includes(ad._id));
  }, [ad._id]);

  useEffect(() => {
    checkIsSaved();
  }, [checkIsSaved]);

  if (homeCard) {
    background = 'bg-primary-yellow text-black';
  } else {
    switch (ad.adType || type) {
      case 'REGULAR':
        background = 'bg-primary-yellow text-black';
        break;
      case 'PREMIUM':
        background = 'bg-[#FF4343] text-white';
        border = 'border-[#FF4343] border-2';
        break;

      case 'PROMO':
        background = 'bg-primary-yellow text-black';
        border = 'border-2 border-primary-yellow';
        break;

      case homeCard:
        background = 'bg-primary-yellow text-black';
        break;
      default:
        break;
    }
  }
  const checkPriceForSymbol = (priceFrom, priceUpTo) => {
    return priceFrom && priceUpTo && priceFrom !== 0 && priceUpTo !== 0;
  };

  return (
    <div className={`${border} ${mainClass} w-full overflow-hidden rounded-md text-xs shadow-md 2xl:text-sm`}>
      {ad.adType === 'PREMIUM' && !homeCard ? (
        <div className='relative overflow-hidden'>
          <div className='absolute left-2 top-2 z-10 flex items-center gap-1 rounded-full bg-[#FF4343] px-3 py-1 text-white'>
            <IoDiamondOutline width={18} />
            <p className='text-xs font-semibold 2xl:text-xs'>{t('premiumAd')}</p>
          </div>
          {canRemove && (
            <IoIosRemoveCircle
              color='#FF0000'
              onClick={() => removeFromWishList()}
              className='absolute right-0 z-20 m-3 h-8 w-8 cursor-pointer'
            />
          )}
          <img
            src={ad.images && ad.images.length ? `${import.meta.env.VITE_IMAGE_URL}${ad.images[0]}` : []}
            alt='image'
            style={{
              display: loading ? 'none' : 'block',
              animation: 'fadeIn 0.5s',
            }}
            loading='eager'
            // className=' h-48 w-[250px] object-cover hover:scale-110 sm:w-[250px] md:h-36 lg:h-60 lg:w-full xl:w-[400px] 2xl:h-52'
            className='h-80 w-full cursor-pointer object-cover hover:scale-110'
            onLoad={(_) => setLoading(false)}
            onError={errorImage}
            onClick={() => handleAd()}
          />
        </div>
      ) : (
        <div className='flex w-full justify-center overflow-hidden rounded-t-md'>
          {canRemove && (
            <IoIosRemoveCircle color='#FF0000' onClick={() => removeFromWishList()} className='absolute right-0 z-20 m-3 h-8 w-8' />
          )}
          <img
            src={ad.images && ad.images.length ? `${import.meta.env.VITE_IMAGE_URL}${ad.images[0]}` : []}
            alt='image'
            loading='lazy'
            // className='h-48 w-[250px] object-cover hover:scale-110 sm:w-[250px] md:h-36 lg:h-60 lg:w-full xl:w-[350px] 2xl:h-52'
            className='h-80 w-full cursor-pointer object-cover hover:scale-110'
            onError={errorImage}
            onClick={() => handleAd()}
          />
        </div>
      )}

      <div className={`${ad.adType === 'PROMO' && !homeCard ? 'bg-[#FFFADF]' : 'bg-white'} px-2 md:px-4`}>
        <div className='flex w-full items-center  justify-between py-1 lg:py-2'>
          <div className={`flex items-center justify-around gap-2 rounded-sm ${background} px-2 py-1 text-black`}>
            {ad.rating ? ad.rating : '0'}
            <AiOutlineStar />
          </div>
          {ad?.askForPrice && <div className=' text-[#2c2c2c]'>{t('askForPrice')}</div>}
          {!ad?.askForPrice && (
            <div className=' text-[#2c2c2c]'>
              {ad?.priceFrom === ad?.priceUpTo && (ad?.priceFrom !== 0 || ad?.priceUpTo !== 0) ? (
                <>
                  {ad?.priceFrom ? convertCurrency(ad?.priceFrom, currency) : ''}{' '}
                  {checkPriceForSymbol(ad?.priceFrom, ad?.priceUpTo) ? currencySymbol : ''}
                </>
              ) : (
                <>
                  {ad?.priceFrom ? convertCurrency(ad?.priceFrom, currency) : ''}
                  {ad?.priceUpTo ? `${ad?.priceFrom ? '-' : ''}${convertCurrency(ad?.priceUpTo, currency)}` : ''}{' '}
                  {checkPriceForSymbol(ad?.priceFrom, ad?.priceUpTo) ? currencySymbol : ''}
                </>
              )}
              {/* {ad?.priceFrom ? convertCurrency(ad?.priceFrom, currency) : ''}
            {ad?.priceUpTo ? `${ad?.priceFrom ? '-' : ''}${convertCurrency(ad?.priceUpTo, currency)}` : ''} {currencySymbol} */}
              {/* {ad.price === null ? 0 : convertCurrency(ad.price, currency)} {currencySymbol} */}
            </div>
          )}
        </div>
        <h4 className='truncate text-left font-medium text-[#2c2c2c]'>{getLanguageVersion(i18n.language, ad.title, ad.title_en)}</h4>
        <div className='flex items-center gap-1 pb-1 text-left text-[#2c2c2c] lg:pb-3'>
          <ImLocation color='#000000' className='' />
          {ad.city}
        </div>
      </div>
      <div className='flex h-8 2xl:h-12'>
        {isLoggedIn() ? (
          <button
            onClick={() => {
              isInWishList ? removeFromWishList() : addToWishList();
            }}
            className='flex w-1/2 items-center justify-center gap-1 bg-[#EDEDED] '
          >
            {isInWishList ? <BiSolidBookmark /> : <FiBookmark />}

            {t('wishList')}
          </button>
        ) : null}

        <a
          onClick={() => handleAd()}
          className={twMerge(
            isLoggedIn()
              ? `flex w-1/2 items-center justify-center ${background} `
              : `flex w-full items-center justify-center ${background} `
          )}
        >
          <BiBox className='mr-2' />
          {t('detail')}
        </a>
      </div>
    </div>
  );
};

export default Card;
