import { twMerge } from 'tailwind-merge';

const FormRow = ({
  id,
  layout,
  labelText,
  labelClassName,
  showError,
  errorClassName,
  type,
  name,
  value,
  onChange,
  className,
  placeholder,
  icon,
  iconView,
  refFocus,
  iconClassName,
  iconViewClassName,
  minValue,
  maxValue,
  readOnly,
  checked,
  pError = false,
  inputClass,
  onClick,
  labelClick,
}) => {
  return (
    <div className={twMerge(`grid w-full gap-2 ${layout}`)}>
      {labelText ? (
        <label htmlFor={name} onClick={labelClick} className={twMerge(`font-light ${labelClassName}`)}>
          {labelText}{' '}
          {pError ? (
            <p className={twMerge(`text-base text-pink-500 ${errorClassName}`)}>{showError}</p>
          ) : (
            <span className={twMerge(`text-pink-500 ${errorClassName}`)}>{showError}</span>
          )}
        </label>
      ) : null}
      <div className={twMerge(`relative flex w-full ${inputClass}`)}>
        <input
          onClick={onClick}
          min={minValue}
          max={maxValue}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          className={twMerge(`relative mx-auto w-full rounded-lg bg-white py-4 pl-12 pr-4 shadow-lg outline-none  ${className}`)}
          placeholder={placeholder}
          ref={refFocus}
          checked={checked}
        />
        <div className={twMerge(`absolute left-3 top-[50%] -translate-y-[50%] ${iconClassName}`)}>{icon}</div>
        <div className={twMerge(`absolute right-5 top-[50%] flex -translate-y-[50%] items-center ${iconViewClassName}`)}>{iconView}</div>
      </div>
    </div>
  );
};

export default FormRow;
