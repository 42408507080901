import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import errorImage from '@/utils/errorImage';
import imageMissing from '../../../../../assets/imageMissing.png';
import { twMerge } from 'tailwind-merge';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SingleAdArticle = ({
  id,
  adName,
  endDate,
  edit,
  image,
  category,
  created,
  setModalFlag,
  paid,
  isPaused,
  className,
  openModal,
  email,
  role,
  handleAds,
  check,
  selectedAd,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userStore);
  return (
    <article className={twMerge(`flex h-full w-full flex-col justify-end rounded-md p-2 text-sm shadow-md 2xl:text-lg ${className}`)}>
      {/* title */}
      <div className='flex w-full flex-grow justify-between self-start border-b-[1px] pb-2'>
        <h1 className=''>{adName}</h1>
        {check && user.role !== 'admin' ? (
          <input type='checkbox' checked={selectedAd} onClick={() => handleAds(id)} name='singleAd' className='h-5 w-6' />
        ) : null}
      </div>
      {/* <hr /> */}
      {/* picture  and details*/}
      <div className='w-full'>
        {/* picture */}
        <div className='flex gap-3 py-2'>
          <img src={image ? image : imageMissing} alt='image' onError={errorImage} className='h-16 w-16 rounded-md' />
          <div>
            <h1 className='font-medium'>{endDate ? 'Važi od' : t('created')}:</h1>
            <p className='font-thin'>{created}</p>
            {endDate ? (
              <>
                <h1 className='font-medium'>{t('validUntil')}:</h1>
                <p className='font-thin'>{endDate}</p>
              </>
            ) : null}
            <div
              onClick={() => {
                // openModal(id);
                // setModalFlag(isPaused ? 'active' : 'inactive');
              }}
              className={`grid place-items-center rounded-xl border px-2 py-0.5 font-thin 
            ${isPaused !== false ? 'border-[#C51B1B] text-[#C51B1B] opacity-80' : 'border-[#1BC532] text-[#1BC532]'}
            `}
            >
              {isPaused ? t('inactive') : t('active')}
            </div>
          </div>
        </div>
        {/* details */}
        {/* <div className='h-34 py-2'> */}
        <div className='h-34 py-2'>
          <h1 className='font-medium'>
            {t('category')}:<span className='font-thin'> {category}</span>
          </h1>

          <h1 className='font-medium'>
            {t('paid')}:<span className='font-thin'> {paid}</span>
          </h1>
          {role === 'admin' ? (
            <h1 className='font-medium'>
              {t('email')}:<span className='break-all font-thin'> {email}</span>
            </h1>
          ) : null}
        </div>
      </div>

      {
        edit ? (
          <>
            <div className='w-full self-end'>
              <hr />
              <div className='flex items-center justify-end gap-3 pt-2 '>
                <button
                  className=' text-[#E5BF01]'
                  onClick={() => {
                    openModal(id);
                    setModalFlag('editAd');
                  }}
                >
                  <FiEdit />
                </button>
                <button
                  className=''
                  onClick={() => {
                    openModal(id);
                    setModalFlag('deleteAd');
                  }}
                >
                  <RiDeleteBin2Fill />
                </button>
                <Link to={`/products/${id}`} target='_blank' className='text-xs'>
                  <FaArrowUpRightFromSquare />
                </Link>
              </div>
            </div>
          </>
        ) : null
        // <div className='flex w-full justify-end'>
        //   <input type='checkbox' name='singleAd' className='h-3 w-3' />
        // </div>
      }
    </article>
  );
};

export default SingleAdArticle;
