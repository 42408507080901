import { en } from './en.js';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { sr } from './sr.js';

const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';

i18n.use(initReactI18next).init({
  debug: isDevelopment ? true : false,
  fallbackLng: JSON.parse(localStorage.getItem('lng')) ? JSON.parse(localStorage.getItem('lng')) : 'sr',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    sr,
    en,
  },
});

export default i18n;
