import getSlugText from '../../../utils/getSlugText';
import { storeCategory } from '../../../store/categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCategoryImage, getCategoryKey } from '@/utils/category.js';
import { setFilterObjectState } from '../../../store/filtersSlice';

const SingleAdCategory = ({ id, name, category, setMainHeader, setSearchText }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterObject = useSelector((s) => s.filtersStore);
  let text = '';

  switch (name) {
    case 'Turizam i odmor':
      text = 'tourismAndVacation';
      break;
    case 'Ugostiteljstvo':
      text = 'catering';
      break;
    case 'Vozila, mašine i alati':
      text = 'vehiclesMachinesTools';
      break;
    case 'Servisi':
      text = 'services';
      break;
    case 'Menjam/Poklanjam':
      text = 'exchangeGiveAway';
      break;
    case 'Razno':
      text = 'other';
      break;
  }

  return (
    <button
      type='button'
      onClick={() => {
        dispatch(setFilterObjectState({ ...filterObject, isSubmit: true, fields: [], city: '', category: name, search: '' }));
        setMainHeader(text);
        dispatch(storeCategory({ _id: id, name: name, key: text }));
        setSearchText('');
        navigate(`/products/${getSlugText(t(text))}`, { state: { hash: 'search' } });
      }}
      className='z-5 flex cursor-pointer flex-col items-center justify-center'
    >
      <div key={category.name} className='z-5 flex cursor-pointer flex-col items-center justify-center'>
        <div
          className='h-40 w-40 rounded-xl border-4 border-primary-yellow bg-cover xl:h-48 xl:w-48'
          style={{ backgroundImage: `url(${getCategoryImage(category.name)})` }}
        />
        <div
          className={`inline-block w-48 overflow-hidden text-center text-xs font-semibold uppercase text-black lg:text-white xl:text-sm`}
        >
          {t(getCategoryKey(category.name))}
        </div>
      </div>
    </button>
  );
};

export default SingleAdCategory;
