import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { BiRightArrowAlt } from 'react-icons/bi';
import NoResultMessage from '../../../../../components/noResultMessage/NoResultMessage';
import PaginationContainer from '../../../../Ads/components/PaginationContainer';
import SingleAdArticle from '../../AdsList/components/SingleAdArticle';
import SingleAdTable from '../../AdsList/components/SingleAdTable';
import UserService from '../../../../../services/UserServices';
import axios from 'axios';
import formatDate from '../../../../../helpers/formatDate';
import getLanguageVersion from '../../../../../utils/getLanguageVersion';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';
// import { useDebounce } from '../../../../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';

const ITEMS_PER_PAGE = 6;

const StepOne = () => {
  let [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [adsData, setAdsData] = useState({ totalAds: 0, numberOfPages: 0, currentPage: 1, ads: [] });
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [filterObject, setFilterObject] = useState({ limit: ITEMS_PER_PAGE, page: page, sort: 'createAt', order: -1, category: 'all' });
  const [selectedAds, setSelectedAds] = useState([]);
  const [totalAds, setTotalAds] = useState(0);

  // const search = useDebounce(filterObject?.search, 300);

  const filteredAds = useCallback(
    async (source) => {
      try {
        const queryParams = new URLSearchParams(filterObject).toString();
        const response = await UserService.userAdsDashboard(queryParams, { cancelToken: source.token });

        setTotalAds(response.data.totalCount);
        setAdsData({
          numberOfPages: Math.ceil(response.data.totalCount / ITEMS_PER_PAGE),
          currentPage: page,
          ads: response.data.data,
        });
      } catch (error) {
        if (error.message !== 'canceled') console.log(error);
      }
    },
    [page, filterObject]
  );

  useEffect(() => {
    const id = searchParams.get('toPay');
    if (!id) return;
    setSelectedAds(id);
    navigate('step-two', { state: [id] });
  }, [navigate, searchParams]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    filteredAds(source);
    return () => source.cancel();
  }, [filteredAds]);

  useEffect(() => {
    setFilterObject((prev) => ({ ...prev, page }));
  }, [page]);

  const handleWarning = () => {
    toast.warning(t('selectAds'));
  };

  const handleAds = (id) => {
    setSelectedAds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((adId) => adId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  return (
    <main>
      <div className='flex w-full flex-col py-[1.625rem] xl:flex-row xl:items-center'>
        <div className='flex w-full flex-col gap-2 text-left  xl:w-[45%]'>
          <div className='relative w-48 bg-primary-yellow '>
            <h1 className='pl-3  text-[2rem] font-semibold uppercase leading-[2.8125rem] text-[#000000]'>{t('step')} 1</h1>
            <div className='absolute -right-[2rem] top-0 h-[2.8125rem] w-[2.8125rem] rotate-45 bg-[#FAF9F5]' />
          </div>
          <p className='text-md font-medium text-gray-600'>{t('chooseAdPayment')}</p>
        </div>
        <p className='pt-4 text-xl font-medium md:text-2xl xl:text-3xl'>{t('chooseAd')}</p>
      </div>
      {/* List of ads */}
      <div className='justify-center'>
        {/* Table view */}
        <table className={'hidden xl:table  xl:w-full  xl:table-auto xl:border-separate  xl:border-spacing-y-6 xl:border-y xl:text-center'}>
          {/* Header of table */}
          <thead className=' font-medium '>
            <tr>
              {/* <th>#</th> */}
              <th>
                <h1>{t('adName')}</h1>
              </th>
              <th>
                <h1>{t('category')}</h1>
              </th>
              <th>
                <h1>{t('validFrom')}</h1>
              </th>
              <th>
                <h1>{t('validUntil')}</h1>
              </th>
              <th>
                <h1>{t('adPackage')}</h1>
              </th>
              <th>
                <h1>{t('status')}</h1>
              </th>
              <th>
                <h1>{t('adId')}</h1>
              </th>
            </tr>
          </thead>
          {adsData.ads.length !== 0
            ? adsData.ads.map((add, index) => {
                return (
                  <SingleAdTable
                    id={add._id}
                    key={index + add._id}
                    adName={getLanguageVersion(i18n.language, add.title, add.title_en)}
                    image={add.images[0] ? `${import.meta.env.VITE_IMAGE_URL}${add.images[0]}` : null}
                    created={formatDate(add.startDate)}
                    endDate={formatDate(add.endDate)}
                    isPaused={add.isPaused}
                    category={t(add.category[0]?.key)}
                    paid={t(add.adType)}
                    uniqueId={add.uniqueId}
                    check={true}
                    showId
                    handleAds={handleAds}
                  />
                );
              })
            : null}
        </table>
        {adsData.ads.length === 0 ? <NoResultMessage /> : null}
      </div>
      <div className='flex xl:hidden'>
        {/* Article view */}
        {adsData.ads.length !== 0 ? (
          <div className={'mt-[1rem] grid gap-[1rem] sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3'}>
            {adsData.ads.map((add, index) => {
              return (
                <SingleAdArticle
                  id={add._id}
                  numOfPages
                  key={index}
                  adName={getLanguageVersion(i18n.language, add.title, add.title_en)}
                  image={add.images[0] ? `${import.meta.env.VITE_IMAGE_URL}${add.images[0]}` : null}
                  created={formatDate(add.startDate)}
                  endDate={formatDate(add.endDate)}
                  isPaused={add.isPaused}
                  category={t(add.category[0]?.key)}
                  paid={t(add.adType)}
                  check
                  showId
                  handleAds={handleAds}
                />
              );
            })}
          </div>
        ) : null}
      </div>
      {/*Num of ads and pagination */}
      {/* <hr className='mt-20' /> */}
      <footer className='grid place-items-center gap-[1rem]  py-[2.4375rem] sm:flex sm:items-center sm:justify-between'>
        {/* <h1 className='text-[#272D3B4F]'>
          Prikazano <span className='text-[#272D3B]'>{adsData.ads.length}</span> od <span className='text-[#272D3B]'>{totalAds}</span>
        </h1> */}
        <div className='mx-auto max-w-min '>
          <PaginationContainer setCurrentPage={setPage} totalCount={totalAds} currentPage={page} pageSize={ITEMS_PER_PAGE} />
        </div>
        {selectedAds.length !== 0 ? (
          <Link to={{ pathname: 'step-two' }} state={selectedAds}>
            <div className='flex items-center  justify-center gap-2 rounded-[1.25rem] bg-[#FED400] px-10 py-2 text-[1.25rem] leading-6 text-black transition-all duration-200 hover:bg-[#fed400ce]'>
              {t('nextStep')}{' '}
              <span className='text-[2rem]'>
                <BiRightArrowAlt />
              </span>
            </div>
          </Link>
        ) : (
          <button type='button' onClick={handleWarning}>
            <div className='flex items-center justify-center gap-2 rounded-[1.25rem] bg-primary-yellow px-10 py-2 text-[1.25rem] leading-6 text-black transition-all duration-200'>
              {t('nextStep')}{' '}
              <span className='text-[2rem]'>
                <BiRightArrowAlt />
              </span>
            </div>
          </button>
        )}
      </footer>
    </main>
  );
};

export default StepOne;
