import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { useState } from 'react';

const SingleQuestion = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <article className='border-b-[1px] border-b-[#D6D6D6]'>
      <header className='flex items-center justify-between py-6'>
        <h3 className=' font-semibold'>{question}</h3>
        <button onClick={() => setShowAnswer((prev) => !prev)}>{showAnswer ? <IoIosArrowUp /> : <IoIosArrowDown />}</button>
      </header>
      {showAnswer && <p className='pb-6  text-[0.75rem] font-normal leading-4 text-[#1B1310] sm:pr-12'>{answer}</p>}
    </article>
  );
};

export default SingleQuestion;
