import { useTranslation } from 'react-i18next';
import Header from '@/components/Header/Header.jsx';
import background from '@/assets/main-about.jpg';

const AboutPageHeader = () => {
  const { t } = useTranslation();
  return (
    <Header background={background}>
      <h1 className='mt-10 text-center text-3xl font-medium text-primary-yellow md:text-5xl lg:mt-16 xl:text-7xl'>{t('aboutUs')}</h1>
    </Header>
  );
};

export default AboutPageHeader;
