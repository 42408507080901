import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import * as Yup from 'yup';

import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import { Link, useNavigate } from 'react-router-dom';
import { convertCurrency, convertRSDCurrency } from '../../../../../../utils/currencyConverter';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import ProductService from '../../../../../../services/ProductService';
import { BiRightArrowAlt } from 'react-icons/bi';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Editor } from 'react-draft-wysiwyg';
import FormRow from '../../../../../../components/formRow/FormRow';
import MapComponent from '@/components/Map/Map';
import { MdEuroSymbol } from 'react-icons/md';
import Modal from '@/components/modal/modal';
import Select from 'react-select';
import UserService from '../../../../../../services/UserServices';
import axios from 'axios';
import checkForbiddenWords from '../../../../../../utils/checkForbiddenWords';
import draftToHtml from 'draftjs-to-html';
import editorOptions from '../../../../../../utils/editorOptions';
import { get } from '../../../../../../utils/localStorage';
import { storeNewAd } from '../../../../../../store/adSlice';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const USERS_IN_DROPDOWN = 20;

const StepTwoPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cities, setCities] = useState([]);
  const [users, setUsers] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isThereSubCat, setIsThereSubCat] = useState(true);
  const [currentValues, setCurrentValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const center = useMemo(() => ({ lat: 44.809612, lng: 20.460622 }), []);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLogitude] = useState('');
  const [categoryName, setCategoryName] = useState('exchangeGiveAway');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateEn, setEditorStateEn] = useState(EditorState.createEmpty());
  const currentUser = JSON.parse(localStorage.getItem('er_user'));
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [searchUserText, setSearchUserText] = useState('');

  const userSearch = useDebounce(searchUserText, 300);

  const [searchCityText, setSearchCityText] = useState('');
  const [selectedCityId, setSelectedCityId] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);

  const handleSelectedUser = (selectedUser) => {
    setSelectedUser(selectedUser);
    setSelectedUserId(selectedUser.value);
  };

  const handleSelectedCity = (selectedCity) => {
    setSelectedCity(selectedCity);
    setSelectedCityId(selectedCity.value);
    formik.setFieldValue('city', selectedCity.label);
  };
  const { currency } = useSelector((state) => state.currencyStore);

  // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(adResponse.data.description))));

  const adSchema = Yup.object().shape({
    title: Yup.string().required(t('yupRequired')),
    title_en: Yup.string(),
    address: Yup.string().required(t('yupRequired')),
    city: Yup.string().required(t('yupRequired')),
    description: Yup.string().required(t('yupRequired')),
    description_en: Yup.string(),
    phone: Yup.string().required(t('yupRequired')),
    phone2: Yup.string(),
    priceFrom: Yup.number()
      .typeError(t('numberValidation'))
      .test('conditional-required', t('yupRequired'), function (value) {
        const { categoryName, askForPrice } = this.parent || '';
        if (categoryName !== 'exchangeGiveAway' && !askForPrice) {
          if (!value || value === undefined) {
            return this.createError({ message: t('yupRequired') });
          }
        }
        return true;
      }),
    priceUpTo: Yup.number()
      .typeError(t('numberValidation'))
      .test('conditional-required', t('yupRequired'), function (value) {
        const { categoryName, priceFrom, priceUpTo, askForPrice } = this.parent || '';
        if (categoryName !== 'exchangeGiveAway' && !askForPrice) {
          if (!value || value === undefined) {
            return this.createError({ message: t('yupRequired') });
          }
          if (parseInt(priceFrom) > parseInt(priceUpTo) && !askForPrice) {
            return this.createError({ message: t('priceFromPriceTo') });
          }
        }
        return true;
      }),
    askForPrice: Yup.boolean(),
    video: Yup.string(),
    instagram: Yup.string(),
    facebook: Yup.string(),
    viber: Yup.boolean(),
    whatsApp: Yup.boolean(),
    isSubCat: Yup.boolean(),
    subCategoryId: Yup.string().test('conditional-required', t('yupRequired'), function (value) {
      const { isSubCat } = this.parent || false;
      if (isSubCat) {
        if (!value || value.trim() === '') {
          return this.createError({ message: t('yupRequired') });
        }
      }

      return true;
    }),
    email: Yup.string(),
    latitude: Yup.string(),
    longitude: Yup.string(),
    userId: Yup.string(),
  });

  useEffect(() => {
    const handleValues = async () => {
      const {
        address,
        categoryId,
        city,
        description,
        description_en,
        email,
        phone,
        phone2,
        priceFrom,
        priceUpTo,
        askForPrice,
        subCategoryId,
        title,
        title_en,
        viber,
        video,
        instagram,
        facebook,
        whatsApp,
        latitude,
        longitude,
        categoryName,
        userId,
        searchUserText,
        selectedCityTextSave,
      } = await get('er_ad');

      setCategoryName(categoryName);
      setSearchUserText(searchUserText ?? '');
      setSearchCityText(selectedCityTextSave ?? '');
      setCurrentValues({
        ...currentValues,
        address,
        categoryId,
        city,
        description,
        description_en,
        email,
        phone,
        phone2,
        priceFrom,
        priceUpTo,
        askForPrice,
        subCategoryId,
        title,
        title_en,
        viber,
        video,
        instagram,
        facebook,
        whatsApp,
        latitude,
        longitude,
        userId,
        searchUserText,
        selectedCityTextSave,
      });
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(description ?? ''))));
      setEditorStateEn(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(description_en ?? ''))));
    };
    handleValues();
  }, []);

  const formik = useFormik({
    initialValues: {
      title: currentValues.title,
      title_en: currentValues.title_en,
      address: currentValues.address,
      city: currentValues.city,
      description: currentValues.description,
      description_en: currentValues.description_en,
      phone: currentValues.phone,
      phone2: currentValues.phone2 || '',
      priceFrom: currentValues.priceFrom ? convertCurrency(currentValues.priceFrom, currency, 3) : 0,
      priceUpTo: currentValues.priceUpTo ? convertCurrency(currentValues.priceUpTo, currency, 3) : 0,
      askForPrice: currentValues.askForPrice || false,
      video: currentValues.video || '',
      instagram: currentValues.instagram || '',
      facebook: currentValues.facebook || '',
      viber: currentValues.viber || false,
      whatsApp: currentValues.whatsApp || false,
      isSubCat: isThereSubCat,
      categoryName: categoryName,
      subCategoryId: currentValues.subCategoryId || '',
      email: currentValues.email || '',
      latitude: currentValues.latitude,
      longitude: currentValues.longitude,
      fields: '[]',
      // userId: selectedUserId || '',
      userId: currentValues.userId || '',
      cityId: currentValues.cityId || '',
    },

    validationSchema: adSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      let convertedPriceFrom = values.priceFrom;
      let convertedPriceUpTo = values.priceUpTo;

      if (currency !== 'eur' && !values.askForPrice) {
        convertedPriceFrom = convertRSDCurrency(values.priceFrom, 'eur', 3);
        convertedPriceUpTo = convertRSDCurrency(values.priceUpTo, 'eur', 3);
      }

      let videoCode;
      if (values.video.length !== 0 && values.video.includes('=')) {
        if (values.video.includes('https://youtu.be')) {
          let separatedUrl = values.video.split('/');
          videoCode = separatedUrl[separatedUrl.length - 1].split('?')[0];
        } else {
          videoCode = values.video.split('=')[1];
        }
      } else {
        videoCode = values.video;
      }
      try {
        const { categoryId } = await get('er_ad');
        if (checkForbiddenWords(values.title)) {
          return toast.warning(t('forbiddenWordsInTitle'));
        }
        if (checkForbiddenWords(values.description)) {
          return toast.warning(t('forbiddenWordsInDescription'));
        }
        dispatch(
          storeNewAd({
            ...values,
            description_en: values.description_en ?? '',
            title_en: values.title_en ?? '',
            priceFrom: convertedPriceFrom,
            priceUpTo: convertedPriceUpTo,
            askForPrice: values.askForPrice,
            video: videoCode,
            instagram: values.instagram,
            facebook: values.facebook,
            categoryId: categoryId,

            latitude: latitude,

            longitude: longitude,
            userId: selectedUserId,
            searchUserText: selectedUser?.label || '',
            cityId: selectedCityId,
            selectedCityTextSave: selectedCity?.label || '',
          })
        );
        navigate('/dashboard/addAds/stepThree');
      } catch (error) {
        console.log(error);
        toast.error(t('somethingWentWrong'));
      }
    },
  });

  const getAllSubcategories = async () => {
    try {
      const { categoryId } = await get('er_ad');
      const response = await ProductService.getSubCategories(categoryId);
      const subCategories = response.data;
      if (subCategories.length === 0) {
        setIsThereSubCat(false);
      }

      if (subCategories.length === 1) {
        formik.setFieldValue('subCategoryId', subCategories[0]._id);
      }
      setSubCategories(subCategories);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCities = async () => {
    try {
      const response = await ProductService.getCities();
      const allCities = response.data;
      setCities(allCities);
      const { selectedCityTextSave } = await get('er_ad');
      const k = allCities.find((city) => city.name === selectedCityTextSave);
      setSelectedCity({ value: k?._id ?? '', label: k?.name ?? '' });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllUsers = useCallback(
    async (source) => {
      try {
        const pagination = `page=${1}&size=${USERS_IN_DROPDOWN}&search=${userSearch}`;
        const response = await UserService.getAllUsers(pagination, { cancelToken: source.token });
        const allUsers = response.data.data;
        const formattedUsers = allUsers.map((user) => ({
          value: user._id,
          label: user.firstName + ' ' + user.lastName + ' | ' + user.email,
        }));
        setUsers(formattedUsers);
      } catch (error) {
        if (error.message !== 'canceled') console.log(error);
      }
    },
    [userSearch]
  );

  useEffect(() => {
    getAllSubcategories();
    getAllCities();
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();

    getAllUsers(source);
    return () => {
      source.cancel();
    };
  }, [getAllUsers]);

  function onEditorStateChange(e) {
    if (e) {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      formik.setFieldValue('description', html);
      setEditorState(e);
    }
  }
  function onEditorStateChangeEn(e) {
    if (e) {
      const html = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()));
      formik.setFieldValue('description_en', html);
      setEditorStateEn(e);
    }
  }

  const showError = (name) => formik.errors[name] && formik.touched[name] && formik.errors[name];

  return (
    <div className='grid '>
      <nav className='grid w-full place-items-center py-[1.625rem] pb-6 xl:flex xl:items-center'>
        <div className='grid place-items-center text-center xl:place-items-start'>
          <div className='relative w-48 bg-primary-yellow text-left'>
            <h1 className='pl-3  text-[2rem] font-semibold uppercase leading-[2.8125rem] text-[#000000]'>{t('step')} 2</h1>
            <div className='absolute -right-[2rem] top-0 h-[2.8125rem] w-[2.8125rem] rotate-45 bg-[#FAF9F5]'></div>
          </div>
          <h1 className='mt-2  text-[1.625rem] font-medium uppercase leading-[2.25rem] text-[#5A5A5A]'>{t('informationAboutAd')}</h1>
        </div>
        <h1 className='grow text-center  text-[2.625rem] font-medium leading-[3.6875rem] text-[#000000] xl:pr-[19.6875rem]'>
          {t('addAds')}
        </h1>
      </nav>
      <hr />
      {/* FORM section */}
      <form onSubmit={formik.handleSubmit}>
        <section className='grid place-items-center pb-4 pt-4 xl:pb-16 xl:pr-8 xl:pt-12'>
          <div className='grid w-full place-items-start gap-7 xl:grid-cols-2 xl:gap-10'>
            {/* Left side */}
            <div id='left-side' className='w-full'>
              {/* subcategories */}
              {subCategories.length <= 1 ? null : (
                <div>
                  <label htmlFor='subCategoryId' className=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310]'>
                    {subCategories.length <= 0 ? t('subCategory') : t('subcategoryRequiredField')}{' '}
                    <span className='text-[0.75rem] text-pink-500'>{showError('subCategoryId')}</span>
                  </label>
                  <div className='mt-[1rem] grid h-[3.0625rem] w-full place-items-center rounded-[4px] border-[1px] border-[#707070] bg-[#FFFFFF] pl-[1.3rem] pr-2'>
                    <select
                      name='subCategoryId'
                      id='subCategoryId'
                      type={'text'}
                      value={formik.values.subCategoryId}
                      onChange={formik.handleChange}
                      className='w-full bg-[#FFFFFF]  text-[1rem] font-medium leading-[1.1875rem]'
                    >
                      <option value='' className='opacity-60'>
                        {t('nothingHasBeenSelected')}
                      </option>
                      {/* This is for production */}
                      {subCategories.map((subCategory) => {
                        return (
                          <option key={subCategory._id} value={subCategory._id} className='opacity-60'>
                            {t(subCategory.name)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {/* address */}
              <FormRow
                layout={`h-[5.375rem] ${subCategories.length > 1 ? 'mt-[1.75rem]' : 'mt-[0.30rem]'} gap-0`}
                labelText={t('addressRequiredField')}
                showError={showError('address')}
                errorClassName={'text-[0.75rem]'}
                labelClassName=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-0'
                type={'text'}
                name={'address'}
                value={formik.values.address}
                onChange={formik.handleChange}
                className='mt-[1rem] h-[3.0625rem] rounded-[4px] border-[1px] border-[#707070] py-[9px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                placeholder={t('enterAnAddress')}
              />
              <div className='hidden h-[2.5rem] w-full xl:block 2xl:hidden'></div>
              {/* description */}
              <div className='mt-[1.75rem] grid w-full xl:mt-[3.8rem]'>
                <label className=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310]'>
                  {t('descriptionRequiredField')} <span className='text-[0.75rem] text-pink-500'>{showError('description')}</span>
                </label>
                <div className='mt-4 h-[292px] overflow-scroll rounded-[4px] border-[1px]  border-[#707070] bg-white px-2 '>
                  <Editor
                    toolbar={editorOptions}
                    name={'description'}
                    value={formik.values.description}
                    defaultValue={formik.values.description}
                    editorState={editorState}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={onEditorStateChange}
                  />
                </div>
              </div>
              {/* Prices START*/}
              {categoryName !== 'exchangeGiveAway' ? (
                <>
                  <hr className='mt-10 w-full' />
                  <div className='flex space-x-6 2xl:gap-10'>
                    {/* Price from */}
                    <div className='flex flex-1 items-end'>
                      <FormRow
                        layout='mt-[1.2625rem]'
                        labelText={t('priceFrom')}
                        labelClassName='text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-[1rem]'
                        showError={showError('priceFrom')}
                        errorClassName='text-[0.75rem]'
                        type='number'
                        readOnly={formik.values.askForPrice}
                        onKeyDown={(evt) => ['e', 'E', '+', '-', ',', '.'].includes(evt.key) && evt.preventDefault()}
                        name='priceFrom'
                        value={formik.values.askForPrice ? '' : formik.values.priceFrom}
                        onChange={formik.handleChange}
                        className='h-[49px] rounded-l-[4px] rounded-r-[0px] border-[1px] border-[#707070] py-[11.5px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                        placeholder={t('enterPrice')}
                      />
                      <div className='grid h-[3.0625rem] w-[2.7825rem] place-items-center rounded-r-[4px] bg-[#707070] text-xl text-[#FFFFFF]'>
                        {currency !== 'eur' ? 'DIN' : <MdEuroSymbol />}
                      </div>
                    </div>
                    {/* Price to */}
                    <div className='flex flex-1 items-end'>
                      <FormRow
                        layout='mt-[1.2625rem]'
                        labelText={t('priceTo')}
                        labelClassName='text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-[1rem]'
                        showError={showError('priceUpTo')}
                        errorClassName='text-[0.75rem]'
                        type='number'
                        readOnly={formik.values.askForPrice}
                        onKeyDown={(evt) => ['e', 'E', '+', '-', ',', '.'].includes(evt.key) && evt.preventDefault()}
                        name='priceUpTo'
                        value={formik.values.askForPrice ? '' : formik.values.priceUpTo}
                        onChange={formik.handleChange}
                        className='h-[49px] rounded-l-[4px] rounded-r-[0px] border-[1px] border-[#707070] py-[11.5px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                        placeholder={t('enterPrice')}
                      />
                      <div className='grid h-[3.0625rem] w-[3.0625rem] place-items-center rounded-r-[4px] bg-[#707070] text-xl text-[#FFFFFF]'>
                        {currency !== 'eur' ? 'DIN' : <MdEuroSymbol />}
                      </div>
                    </div>
                  </div>

                  <div className='mt-[24px]'>
                    <div className='flex items-center'>
                      <input
                        type='checkbox'
                        name={'askForPrice'}
                        checked={formik.values.askForPrice}
                        value={formik.values.askForPrice}
                        onChange={formik.handleChange}
                        className='h-[1.2rem] w-[1.2rem]'
                      />
                      <label className='pl-[5px] text-lg font-medium'>{t('askForPrice')}</label>
                    </div>
                    <p className='mt-2 text-sm text-gray-500'>{t('askForPriceDescription')}</p>
                  </div>
                  <hr className='mt-10 w-full' />
                </>
              ) : null}
              {/* Prices END */}
              {/* MAP START */}
              <div className='grid w-full place-items-center py-8'>
                <button
                  type='button'
                  onClick={() => setIsOpen(true)}
                  className='rounded-lg bg-primary-yellow px-20 py-3  text-base font-medium  transition-all duration-200 hover:bg-[#fed400ce]'
                >
                  {t('map')}
                </button>
              </div>

              <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                modalButtons={'justify-between'}
                confirmButton={'block'}
                markerPosition={markerPosition}
                setLatitude={setLatitude}
                setLogitude={setLogitude}
                setIsOpen={setIsOpen}
                modalClass='p-0'
                title={t('placeCorrectLocation')}
              >
                <MapComponent
                  markerPosition={markerPosition}
                  setMarkerPosition={setMarkerPosition}
                  center={center}
                  isDraggable={true}
                  showSearch={true}
                  classNameMap='h-[500px]'
                />
              </Modal>
              {/* MAP END */}
            </div>
            {/* Right side */}
            <div id='right-side' className='w-full'>
              <FormRow
                layout='h-[5.375rem] gap-0'
                labelText={t('titleRequiredField')}
                labelClassName=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-0'
                showError={showError('title')}
                errorClassName={'text-[0.75rem]'}
                type={'text'}
                name={'title'}
                value={formik.values.title}
                onChange={formik.handleChange}
                className='mt-[1.3rem] h-[3.0625rem] rounded-[4px] border-[1px] border-[#707070] py-[9px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                placeholder={t('enterYourAdTitle')}
              />
              <div className='mt-[1.75rem]'>
                <label className=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310]'>
                  {t('cityRequiredField')} <span className='text-[0.75rem] text-pink-500'>{showError('city')}</span>
                </label>
                <div className='mt-[1rem] grid h-[3.0625rem] w-full place-items-center rounded-[4px]  border-[#707070] bg-[#FFFFFF] '>
                  <Select
                    name='city'
                    id='city'
                    type={'text'}
                    value={selectedCity}
                    onChange={handleSelectedCity}
                    className='-mt-[1.3rem] w-full '
                    options={cities.map((x) => ({
                      value: x._id,
                      label: x.name,
                    }))}
                    inputValue={searchCityText}
                    onInputChange={(e) => setSearchCityText(e)}
                    menuPlacement='auto'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '4px',
                        borderColor: '#707070',
                        fontSize: '1rem',
                        marginTop: '1rem',
                        height: '3.0625rem',
                        lineHeight: '1.1875rem',
                        paddingLeft: '1.3rem',
                      }),
                    }}
                  ></Select>
                  {/* <select
                    name='city'
                    id='city'
                    type={'text'}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    className='w-full bg-[#FFFFFF]  text-[1rem] font-medium leading-[1.1875rem]'
                  >
                    <option value='' className='opacity-60'>
                      {t('nothingHasBeenSelected')}
                    </option>
                    {cities.map((city) => {
                      return (
                        <option key={city._id} value={city.name} className='opacity-60'>
                          {city.name}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
                <p className='mt-[10px]  text-[0.875rem] font-light leading-[1.125rem] text-[#1B1310]'>{t('citiesWesternBalkan')}</p>
              </div>
              <div className='grid place-items-start'>
                <div className='mt-[1.75rem] grid place-items-start gap-4 sm:flex sm:items-center xl:grid xl:place-items-start xl:gap-4 2xl:flex 2xl:items-center'>
                  <p className='pr-[1.6875rem]  text-[1rem] font-medium leading-[1.5rem] text-[#1B1310]'>
                    {t('contactPhoneNumber')} <span className='text-[0.75rem] text-pink-500'>{showError('phone')}</span>
                  </p>
                  {/* socialNetwork */}
                  <div className='flex gap-4'>
                    {/* whatsApp */}
                    <div className='flex'>
                      <input
                        type='checkbox'
                        name={'whatsApp'}
                        checked={formik.values.whatsApp}
                        value={formik.values.whatsApp}
                        onChange={formik.handleChange}
                        className='h-[1.1875rem] w-[1.1875rem]'
                      />
                      <label className='pl-[5px] capitalize'>WhatsApp</label>
                    </div>
                    {/* viber */}
                    <div className='flex 2xl:pl-[1rem]'>
                      <input
                        type='checkbox'
                        name={'viber'}
                        checked={formik.values.viber}
                        value={formik.values.viber}
                        onChange={formik.handleChange}
                        className='h-[1.1875rem] w-[1.1875rem]'
                      />
                      <label className='pl-[5px] capitalize'>Viber</label>
                    </div>
                  </div>
                </div>
                <FormRow
                  type={'text'}
                  name={'phone'}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  className='mt-[1rem] h-[3.0625rem] rounded-[4px] border-[1px] border-[#707070] py-[9px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                  placeholder={t('enterYourPhone')}
                />
              </div>
              <FormRow
                layout='mt-[1.75rem] gap-1'
                labelText={t('contactPhoneNumber2')}
                labelClassName=' text-[1rem] font-medium leading-[1.5rem] text-[#1B1310] mb-0'
                type={'text'}
                name={'phone2'}
                value={formik.values.phone2}
                onChange={formik.handleChange}
                className='mt-[1rem] h-[3.0625rem] rounded-[4px] border-[1px] border-[#707070] py-[9px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                placeholder={t('enterYourPhone')}
              />
              <FormRow
                layout='h-[5.375rem] mt-[1.75rem]'
                labelText={t('emailVisibleOnSite')}
                showError={showError('email')}
                errorClassName={'text-[0.75rem]'}
                type={'text'}
                name={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
                labelClassName=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-0 mt-[1px]'
                className='mt-[1rem] rounded-[4px] border-[1px] border-[#707070] py-[11.5px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                placeholder={t('enterEmail')}
              />
              <div>
                <FormRow
                  layout='h-[5.375rem] mt-[1.75rem]'
                  labelText='Video (youtube)'
                  labelClassName=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-0 mt-[1px]'
                  showError={showError('video')}
                  errorClassName={'text-[0.75rem]'}
                  type={'text'}
                  name={'video'}
                  value={formik.values.video}
                  onChange={formik.handleChange}
                  className='mt-[1rem] rounded-[4px] border-[1px] border-[#707070] py-[11.5px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                  placeholder={t('enterVideoLink')}
                />
                <p className='mt-[10px]  text-[0.75rem] font-normal leading-4 text-[#1B1310]'>{t('enterCodeVideo')}</p>
              </div>
              <FormRow
                layout='h-[5.375rem] mt-[1.75rem]'
                labelText='Instagram link'
                labelClassName=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-0 mt-[1px]'
                showError={showError('instagram')}
                errorClassName={'text-[0.75rem]'}
                type={'text'}
                name={'instagram'}
                value={formik.values.instagram}
                onChange={formik.handleChange}
                className='mt-[1rem] rounded-[4px] border-[1px] border-[#707070] py-[11.5px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                placeholder={t('enterInstagramLink')}
              />
              <FormRow
                layout='h-[5.375rem] mt-[1.75rem]'
                labelText='Facebook link'
                labelClassName=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-0 mt-[1px]'
                showError={showError('facebook')}
                errorClassName={'text-[0.75rem]'}
                type={'text'}
                name={'facebook'}
                value={formik.values.facebook}
                onChange={formik.handleChange}
                className='mt-[1rem] rounded-[4px] border-[1px] border-[#707070] py-[11.5px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                placeholder={t('enterFacebookLink')}
              />
              {/* all Users START */}
              {currentUser.role === 'admin' ? (
                <div className='mt-[1.75rem]'>
                  <label className=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310]'>
                    {t('users')} <span className='text-[0.75rem] text-pink-500'>{showError('userId')}</span>
                  </label>
                  <Select
                    name='userId'
                    options={users}
                    value={selectedUser}
                    onChange={handleSelectedUser}
                    inputValue={searchUserText}
                    onInputChange={(e) => setSearchUserText(e)}
                    menuPlacement='top'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '4px',
                        borderColor: '#707070',
                        fontSize: '1rem',
                        marginTop: '1rem',
                        height: '3.0625rem',
                        lineHeight: '1.1875rem',
                        paddingLeft: '1.3rem',
                      }),
                    }}
                  />
                </div>
              ) : null}
              {/* all Users END */}
            </div>
          </div>
          <hr className='mt-10 w-full' />
          <div className='grid w-full place-items-start gap-7 xl:grid-cols-2 xl:gap-10'>
            {/* Left side */}
            <div className='mt-8 w-full'>
              <h1 className='text-2xl font-semibold'>{t('titleDescriptionEn')}</h1>
              <p className='mt-8 text-base md:text-lg'>{t('pleaseCompleteEn')}</p>
              <p className='mt-2 text-sm font-medium md:text-base'>{t('fillingOut')}</p>
            </div>
            {/* Right side */}
            <div className='w-full'>
              <FormRow
                layout='h-[5.375rem] gap-0 mt-8'
                labelText={t('titleEnglish')}
                labelClassName=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310] mb-0'
                showError={showError('title_en')}
                errorClassName={'text-[0.75rem]'}
                type={'text'}
                name={'title_en'}
                value={formik.values.title_en}
                onChange={formik.handleChange}
                className='mt-[1.3rem] h-[3.0625rem] rounded-[4px] border-[1px] border-[#707070] py-[9px] pl-[1.5rem] shadow-none placeholder:text-[1rem] placeholder:font-medium placeholder:leading-[1.1875rem] placeholder:text-[#1B1310] placeholder:opacity-60'
                placeholder={t('enterYourAdTitleEn')}
              />
              {/* description */}
              <div className='mt-[1rem] grid w-full xl:mt-[2rem]'>
                <label className=' text-[1rem] font-medium leading-[1.1875rem] text-[#1B1310]'>
                  {t('descriptionEnglish')} <span className='text-[0.75rem] text-pink-500'>{showError('description_en')}</span>
                </label>
                <div className='mt-4 h-[292px] overflow-scroll rounded-[4px] border-[1px]  border-[#707070] bg-white px-2 '>
                  <Editor
                    toolbar={editorOptions}
                    name={'description_en'}
                    value={formik.values.description_en}
                    defaultValue={formik.values.description_en}
                    editorState={editorStateEn}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={onEditorStateChangeEn}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Footer section */}
        <section className='grid place-items-center gap-4 pb-4 md:flex md:items-center md:justify-between'>
          <Link to='/dashboard/addAds' className='flex items-center justify-end gap-2'>
            <BsArrowLeftCircle className='text-[1.875rem]' />
            <p className=' text-[1.3125rem] font-medium leading-[1.8125rem]'>{t('goBack')}</p>
          </Link>
          <button type='submit'>
            <div className='flex items-center justify-center gap-2 rounded-[1.25rem] bg-[#FED400] px-10 py-2 text-[1.25rem] leading-6 text-black transition-all duration-200 hover:bg-[#fed400ce]'>
              {t('nextStep')}
              <span className='text-[2rem]'>
                <BiRightArrowAlt />
              </span>
            </div>
          </button>
        </section>
      </form>
    </div>
  );
};

export default StepTwoPage;
