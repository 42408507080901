export const PRIORITY_STATUS = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};
export const SORT = {
  PRICE_HIGH: 'priceToHigh',
  PRICE_LOW: 'priceToLow',
  NEWEST: 'newest',
  OLDEST: 'oldest',
};
export const AD_IMAGES_LIMIT = 10;
