import { createSlice } from '@reduxjs/toolkit';

const currencySlice = createSlice({
  name: 'currency',
  initialState: {
    currency: 'eur',
    currencySymbol: '€',
  },
  reducers: {
    changeCurrency: (state, action) => {
      state.currency = action.payload === 'eur' ? 'rsd' : 'eur';
      state.currencySymbol = state.currency === 'eur' ? '€' : 'din';
      localStorage.setItem('er_currency', state.currency);
    },
    restoreCurrency: (state) => {
      state.currency = localStorage.getItem('er_currency') ?? 'eur';
      state.currencySymbol = state.currency === 'eur' ? '€' : 'din';
    },
  },
});

export const { changeCurrency, restoreCurrency } = currencySlice.actions;
export default currencySlice.reducer;
