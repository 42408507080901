import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TestimonialCard = ({ testimonial }) => {
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoaded(false);
    setTimeout(() => setLoaded(true), 100);
  }, [testimonial]);

  return (
    <div
      className={`flex h-[220px] flex-col justify-between rounded-xl border border-[#707070] bg-white p-4 break4:h-[220px] break55:h-[300px] break55:w-[300px] sm:h-[220px] sm:w-[500px] lg:h-[360px] lg:w-[360px] xl:h-[280px] xl:w-[560px] xl:p-8`}
    >
      <div>
        <p
          className={`text-sm italic text-[#131313] transition delay-[100ms] duration-[2000ms] ease-in-out break55:text-base lg:text-lg ${
            loaded ? 'opacity-100' : 'opacity-0'
          }`}
        >
          ” {t(testimonial.translationKey)} „
        </p>
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex flex-col'>
          <p
            className={`${
              loaded ? 'opacity-100' : 'opacity-0'
            } text-base font-bold text-[#131313] transition duration-500 ease-in-out sm:text-xl md:text-2xl`}
          >
            {testimonial.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
