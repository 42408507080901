import axiosInstance from '../helpers/axiosConfig';
import axiosInstanceMultipart from '../helpers/axiosConfigMultipart';

class ProductService {
  static getAds = (fullPath, config = {}) => axiosInstance.get(fullPath, config);
  static getSingleAd = (id) => axiosInstance.get(`/products/${id}`);
  static getHomePageAds = () => axiosInstance.get(`/products/home-ads`);
  static deleteAd = (id) => axiosInstance.delete(`/products/delete/${id}`);
  static updateAd = (id, formData) => axiosInstanceMultipart.put(`/products/update/${id}`, formData);
  static changeAdStatus = (id) => axiosInstance.put(`/products/changeAdStatus/${id}`);
  static viewAds = (id) => axiosInstance.put(`/products/${id}`);
  static phoneViews = (id) => axiosInstance.put(`/products/${id}/phoneViews`);
  static createAd = (body) => axiosInstanceMultipart.post(`/products/create`, body);
  static exportAds = (body) => axiosInstance.post('/products/export-ads', body);

  static rateAd = (id, body) => axiosInstance.post(`/products/adsRating/${id}`, body);

  static getSubCategories = (id) => axiosInstance.get(`/categories/subCategories/${id}`);
  static getSubCategoriesFields = (id) => axiosInstance.get(`/categories/subCategoryFields/${id}`);

  static getAllCategories = () => axiosInstance.get('/categories');
  static getCities = (category, subcategory) => {
    let queryParams = '';

    if (category) {
      queryParams += `?category=${encodeURIComponent(category)}`;
    }

    if (subcategory) {
      const prefix = queryParams.length ? '&' : '?';
      queryParams += `${prefix}subCategory=${encodeURIComponent(subcategory)}`;
    }

    return axiosInstance.get(`/dropdown/city${queryParams}`);
  };

  static getUserSavedAds = (page, pageSize) => axiosInstance.get(`/products/getAll/wishlist?page=${page}&pageSize=${pageSize}`);
  static getAdsArrayInfo = (data) => axiosInstance.post('/products/getAdsArrayInfo', data);
  static updateWishList = (id) => axiosInstance.post(`/products/wishlist/${id}`);
  static deleteWishList = (id) => axiosInstance.delete(`/products/wishlist/${id}`);

  static getContract = (type) => axiosInstance.get(`/products/contract?type=${type}`);

  static getAllSliders = () => axiosInstance.get('/sliders/all');
}

export default ProductService;
