import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { set } from '../utils/localStorage.js';

export const storeNewAd = createAsyncThunk('ad/storeNewAd', async (data, thunkAPI) => {
  try {
    await set('er_ad', data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
});

const adSlice = createSlice({
  name: 'ad',
  initialState: {
    isLoading: true,
    ad: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeNewAd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(storeNewAd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ad = action.payload;
      })
      .addCase(storeNewAd.rejected, (state, action) => {
        console.log(action.payload);
        state.isLoading = true;
      });
  },
});

export const { storeAd } = adSlice.actions;
export default adSlice.reducer;
