import { createSlice } from '@reduxjs/toolkit';

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    banners: false,
  },
  reducers: {
    getBanners: (state) => {
      state.banners = !state.banners;
    },
  },
});

export const { getBanners } = bannerSlice.actions;
export default bannerSlice.reducer;
