import { BsFacebook, BsInstagram } from 'react-icons/bs';
import { FiBookmark, FiMenu, FiSearch } from 'react-icons/fi';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import DropDown from './components/DropDown';
import Dropdown from '../dropdown/Dropdown.jsx';
import { FaRegUserCircle } from 'react-icons/fa';
import NavigationLinks from './NavLinks.jsx';
import { isLoggedIn } from '../../utils/auth';
import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';

const Navbar = ({ showSideBar, setShowSideBar }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [openUserMenu, setOpenUserMenu] = useState(false);
  const { navLinks } = NavigationLinks();
  const userMenuRef = useRef();
  const btnMenuRef = useRef();
  const search = '';

  const lng = JSON.parse(localStorage.getItem('lng'));

  useEffect(() => {
    const handler = (e) => {
      if (btnMenuRef.current.contains(e.target)) return;
      if (!userMenuRef.current.contains(e.target)) {
        setOpenUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  const handleChangeLanguage = (lng) => {
    if (lng === 'SR') {
      localStorage.setItem('lng', JSON.stringify('sr'));
      i18n.changeLanguage('sr');
    } else if (lng === 'EN') {
      localStorage.setItem('lng', JSON.stringify('en'));
      i18n.changeLanguage('en');
    }
  };

  return (
    <div className='sticky top-0 z-50 shadow-lg'>
      <div id='navbar' className='flex items-center justify-end bg-[#131313] py-3 pl-2 pr-10 md:pl-[75px] md:pr-10 lg:pr-[80px] '>
        <div className='flex items-center gap-5'>
          <button
            aria-label='Search'
            name='search button'
            className='flex items-center justify-center rounded-full'
            onClick={() => {
              navigate('products', { state: { search } });
            }}
          >
            <FiSearch size={20} color='#FED400' />
          </button>

          <div className='flex items-center justify-center gap-2'>
            <a href='https://www.facebook.com/erentoglasi/' target='_blank' rel='noreferrer'>
              <BsFacebook color='#fed400' className='text-sm md:text-xl' />
            </a>
            <a href='https://www.instagram.com/erentoglasi?igsh=MXdjc2c2MWU1Z2Rxcg==' target='_blank' rel='noreferrer'>
              <BsInstagram color='#fed400' className='text-sm md:text-xl' />
            </a>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-between bg-[#ffffff] pl-4 align-middle text-xs md:pl-14 xl:text-sm '>
        <Link to='/' className='w-28 md:w-40' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' })}>
          <img src={logo} alt='logo' className='' />
        </Link>
        <div className='hidden w-auto items-center  gap-6 lg:flex'>
          <div className='flex items-center gap-6 lg:flex '>
            {navLinks.map((navlink) => {
              const { id, text, path } = navlink;
              return (
                <NavLink
                  className='whitespace-nowrap border-b-4 border-white text-base font-medium text-[#484848] aria-[current=page]:border-b-primary-yellow aria-[current=page]:text-primary-yellow'
                  onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' })}
                  to={path}
                  key={id}
                >
                  {text}
                </NavLink>
              );
            })}
            {
              <Dropdown
                titleClass='pl-0 text-base font-semibold'
                className='mb-1 cursor-pointer border-none py-0 pr-2  text-base font-medium shadow-none outline-none 2xl:text-sm'
                dropdownClass='bg-white text-base pb-2 top-6 right-1 px-5 '
                text={lng ? lng?.toUpperCase() : 'SR'}
                defaultValue={lng?.toUpperCase()}
                options={
                  <div className='pt-1'>
                    <li onClick={() => handleChangeLanguage('SR')}>SR</li>
                    <li onClick={() => handleChangeLanguage('EN')}>EN</li>
                  </div>
                }
              />
            }
            <div>
              <Link
                to={isLoggedIn() ? '/saved' : '/login?toSaved=true'}
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' })}
              >
                <FiBookmark size={25} className='mb-1' color='black' />
              </Link>
            </div>
            <div
              className='relative flex h-20 w-20 cursor-pointer items-center justify-center bg-primary-yellow lg:ml-auto '
              onClick={() => {
                setOpenUserMenu(!openUserMenu);
              }}
              ref={btnMenuRef}
            >
              <FaRegUserCircle color='#000000' className='text-4xl' />

              <DropDown openUserMenu={openUserMenu} userMenuRef={userMenuRef} />
            </div>
          </div>
        </div>
        <div className='flex cursor-pointer lg:hidden' onClick={() => setShowSideBar(!showSideBar)}>
          <FiMenu size={36} className='pr-2 text-primary-yellow' />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
