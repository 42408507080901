import { useEffect, useState } from 'react';

import ProductService from '../../../../../../services/ProductService';
import { Link } from 'react-router-dom';
import exchange from '@/assets/homePageExchangeGiveAway.png';
// icons
import exchangeIcon from '@/assets/icons/homePageCategories/homePageExchangeGiveAway.svg';
// images
import imageMissing from '@/assets/imageMissing.png';
import other from '@/assets/homePageOther.png';
import otherIcon from '@/assets/icons/homePageCategories/homePageOther.svg';
import { storeNewAd } from '../../../../../../store/adSlice';
import turizam from '@/assets/homePageTourismAndVacation.png';
import turizamIcon from '@/assets/icons/homePageCategories/homePageTourismAndVacation.svg';
import ugostiteljstvo from '@/assets/homePageCatering.png';
import ugostiteljstvoIcon from '@/assets/icons/homePageCategories/homePageCatering.svg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usluge from '@/assets/homePageServices.png';
import uslugeIcon from '@/assets/icons/homePageCategories/homePageServices.svg';
import vozila from '@/assets/homePageVehiclesMachinesTools.png';
import vozilaIcon from '@/assets/icons/homePageCategories/homePageVehiclesMachinesTools.svg';
const SingleCategory = ({ id, title, name, _layout, _imgClass, _btnClass }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubCat, setIsSubCat] = useState(true);
  const path = 'stepTwo';
  let imageToLoad = '';
  let icon = '';

  useEffect(() => {
    const getSubCat = async () => {
      const res = await ProductService.getSubCategories(id);
      if (res.data.length > 0) {
        setIsSubCat(true);
      } else {
        setIsSubCat(false);
      }
    };
    getSubCat();
  }, [id]);

  switch (name) {
    case 'tourismAndVacation':
      imageToLoad = turizam;
      icon = turizamIcon;
      break;
    case 'catering':
      imageToLoad = ugostiteljstvo;
      icon = ugostiteljstvoIcon;
      break;
    case 'vehiclesMachinesAndTools':
      imageToLoad = vozila;
      icon = vozilaIcon;
      break;
    case 'services':
      imageToLoad = usluge;
      icon = uslugeIcon;
      break;
    case 'exchangeGiveAway':
      imageToLoad = exchange;
      icon = exchangeIcon;
      break;
    case 'other':
      imageToLoad = other;
      icon = otherIcon;
      break;
    default:
      imageToLoad = imageMissing;
      break;
  }

  return (
    <Link
      to={path}
      key={id}
      className='grid place-items-center'
      onClick={() => {
        dispatch(storeNewAd({ categoryId: id, categoryName: name, isSubCat: isSubCat }));
      }}
    >
      <div className='relative mb-4 grid h-36 w-36 place-items-center rounded-full sm:h-52 sm:w-52 md:h-60  md:w-60 lg:mb-6 lg:h-64 lg:w-64'>
        <img
          src={imageToLoad}
          alt={title}
          className='mb-4 h-36 w-36 rounded-full sm:h-52 sm:w-52  md:h-60 md:w-60 lg:mb-6 lg:h-64 lg:w-64'
        />
        <div className='absolute grid h-full w-full place-items-center rounded-full bg-black bg-opacity-0 transition duration-300 hover:bg-opacity-40'>
          <div className='grid h-full w-full place-items-center rounded-full opacity-0 transition-all duration-300 hover:opacity-100'>
            <img src={icon} alt='text' className='h-10 w-10 sm:h-16 sm:w-16 md:h-20 md:w-20 xl:h-24 xl:w-24' />
          </div>
        </div>
      </div>
      <h1 className='text-center text-sm font-semibold text-[#131313] md:text-base lg:text-xl'>{t(name)}</h1>
    </Link>
  );
};

export default SingleCategory;
