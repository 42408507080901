import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import UserService from '../../../../services/UserServices';
import { getImageUrl } from '../../../../utils/config';
import profilePicture from '../../../../assets/profilePicture.png';
import { storeUser } from '../../../../store/userSlice';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userStore);

  const fileUpload = async (e) => {
    if (!e.target.files) {
      return;
    }

    const toSend = new FormData();
    toSend.append('file', e.target.files[0]);

    const response = await UserService.changeUserImage(toSend);
    dispatch(storeUser(response.data));
    localStorage.setItem('er_user', JSON.stringify(response.data));
  };

  return (
    <div className='w-full xl:p-5'>
      <h1 className='p-4 text-center text-3xl font-medium sm:text-left sm:text-4xl'>{t('settings')}</h1>
      <hr className='border-1.5' />
      <div className='w-full pt-6 text-xs xl:flex 2xl:text-lg'>
        <div className='flex flex-col gap-4 pt-4  xl:w-1/3'>
          <div className='flex flex-col  gap-5 text-center '>
            <div className='mx-auto w-36  overflow-hidden rounded-full border object-cover text-center xl:h-32 xl:w-32'>
              {user?.profileImage ? (
                <img alt='avatar' src={`${getImageUrl()}${user.profileImage}?${Date.now()}`} className='h-36 w-40 object-cover' />
              ) : (
                <img alt='profile-img' src={profilePicture} />
              )}
            </div>
            <input name='file' type='file' id='file' className='hidden' onChange={fileUpload} />
            <label htmlFor='file' className='mx-auto  w-max rounded bg-primary-yellow px-4 py-2 text-white hover:opacity-80'>
              {t('changeImage')}
            </label>

            <h2 className='text-xl font-medium xl:text-2xl'>
              {user.firstName} {user.lastName}
            </h2>
          </div>
          <div className='flex w-full flex-row items-center justify-center gap-4 py-5 sm:justify-start xl:flex-col xl:pl-10'>
            <NavLink
              to='/dashboard/settings'
              className={`${location.pathname !== '/dashboard/settings' ? '  text-black' : '  text-primary-yellow'}
               flex w-full justify-center font-medium xl:justify-start `}
            >
              {t('profile')}
            </NavLink>
            <NavLink
              to='password'
              className='flex w-full justify-center font-medium aria-[current=page]:text-primary-yellow xl:justify-start'
            >
              {t('password')}
            </NavLink>
            <NavLink
              to='legal-entities'
              className='flex w-full justify-center font-medium aria-[current=page]:text-primary-yellow xl:justify-start'
            >
              {t('legalEntities')}
            </NavLink>
          </div>
        </div>
        <div className='w-full '>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
