import { useTranslation } from 'react-i18next';

const AboutPageAboutUs = () => {
  const { t } = useTranslation();
  return (
    <section className='mx-auto max-w-4xl text-center xl:max-w-5xl'>
      <div className='my-8 w-full'>
        <h2 className='text-3xl font-bold text-gray-500'>{t('aboutPage.aboutUsTitle')}</h2>
      </div>

      <p className='my-4 px-4 lg:text-xl'>{t('aboutPage.aboutUsText1')}</p>
      <p className='my-4 px-4 lg:text-xl'>{t('aboutPage.aboutUsText2')}</p>
    </section>
  );
};

export default AboutPageAboutUs;
