import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import notFound from '../../assets/404.svg';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <main className='flex min-h-screen w-full flex-col items-center justify-center px-8 text-center '>
      <img src={notFound} alt='not found' className='w-full max-w-xl' />
      <h1 className='mt-8 text-2xl font-semibold break5:text-3xl md:text-4xl'>{t('pageNotFound')}</h1>
      <p className='my-4 text-xs md:text-sm'>{t('cantFindPage')}</p>
      <Link
        to={'/'}
        className='flex items-center justify-center gap-1 rounded-md bg-[#F1C40F] px-4 py-3 text-sm transition-all duration-200 hover:bg-[#f1c40fcb] md:text-base'
      >
        <MdKeyboardDoubleArrowLeft className='md:text-[1.5rem]' />
        {t('backToHome')}
      </Link>
    </main>
  );
};

export default ErrorPage;
