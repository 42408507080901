import { createSlice } from '@reduxjs/toolkit';

export const ITEMS_PER_PAGE = 12;

const defaultState = {
  limit: ITEMS_PER_PAGE,
  page: 1,
  free: false,
  city: '',
  fields: [],
};

const filtersSlice = createSlice({
  name: 'filterObject',
  initialState: {
    limit: ITEMS_PER_PAGE,
    page: 1,
    free: false,
    city: '',
    fields: [],
    isSubmit: true,
  },
  reducers: {
    setFilterObjectState: (_state, action) => {
      const newState = action.payload;
      return newState;
    },
    resetIsSubmit: (state) => {
      return { ...state, isSubmit: false };
    },
    setIsSubmit: (state) => {
      return { ...state, isSubmit: true };
    },
    resetFilterObjectState: (_state, action) => {
      const newState = { ...defaultState, ...action.payload };
      return newState;
    },
    setFields: (state, action) => {
      const newState = {
        ...state,
        fields: action.payload,
      };
      return newState;
    },
  },
});

export const { setFilterObjectState, resetFilterObjectState, setFields, resetIsSubmit, setIsSubmit } = filtersSlice.actions;
export default filtersSlice.reducer;
