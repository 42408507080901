import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useEffect, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

const Dropdown = ({ className, text, noP, options, defaultValue, titleClass, dropdownClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (dropdownRef.current.contains(e.target)) return;
      if (!dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <div
      ref={dropdownRef}
      defaultValue={defaultValue}
      onClick={() => setIsOpen((prev) => !prev)}
      className={twMerge(`relative flex items-center rounded-md bg-white py-2 shadow-md ${className}`)}
    >
      {!noP ? <p className={twMerge(`pl-4 ${titleClass}`)}>{text}</p> : ''}
      {isOpen ? <BiChevronUp /> : <BiChevronDown />}
      {isOpen ? (
        <div className={twMerge(`absolute z-10 flex justify-center rounded-md bg-white shadow-md  ${dropdownClass}`)}>
          <ul className='flex w-full cursor-pointer flex-col gap-1'>{options}</ul>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
