import { MdClose } from 'react-icons/md';
import { RiSave3Line } from 'react-icons/ri';
import { twMerge } from 'tailwind-merge';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Modal = ({
  isOpen,
  children,
  title = '',
  markerPosition,
  setLatitude,
  setLogitude,
  setIsOpen,
  modalClass,
  modalType,
  modalWidth,
  modalFlag,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className='fixed inset-0 z-50 items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none'>
          {/* Background Overlay */}
          <div className='fixed inset-0 bg-black opacity-50'></div>
          <div className={`relative mx-auto my-6 h-auto ${modalWidth ? modalWidth : 'w-auto max-w-3xl'} `}>
            {/* Modal content */}
            <div className='flex h-full w-full flex-col rounded-lg border-0 bg-white px-6 shadow-lg outline-none focus:outline-none'>
              {/* Header */}
              {modalFlag === 'editUser' || modalFlag === 'editAd' ? null : (
                <div className='flex items-center justify-between rounded-t border-b border-solid border-[#272D3B] border-opacity-20 p-5'>
                  <h3 className={twMerge(`text-2xl font-semibold`)}>{title}</h3>
                  {/* <button
                    type='button'
                    className='border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none'
                    onClick={onClose}
                  >
                    <span className='block h-6 w-6 bg-transparent text-2xl text-black outline-none focus:outline-none'>×</span>
                  </button> */}
                </div>
              )}
              {/* Body */}
              <div className={twMerge(`${modalClass}  flex-auto `)}>{children}</div>
              {/* Footer */}
              {modalType !== 'edit' ? (
                <div className='flex justify-between gap-3 border-t border-solid border-[#272D3B] border-opacity-20 py-6'>
                  <button
                    onClick={() => setIsOpen(false)}
                    className='flex items-center justify-center rounded-md bg-primary-yellow px-6 py-2 text-xs font-medium text-black  '
                    type='button'
                  >
                    <MdClose className='text-lg' />
                    <p>{t('cancel')}</p>
                  </button>
                  <button
                    className='flex items-center justify-center gap-1 rounded-md bg-primary-yellow px-6 py-2 text-xs font-medium text-black  '
                    type='button'
                    onClick={() => {
                      setLatitude(markerPosition.lat);
                      setLogitude(markerPosition.lng);
                      setIsOpen(false);
                    }}
                  >
                    <RiSave3Line className='text-lg' />
                    <p>{t('setLocation')}</p>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
