function EditFormSkeleton() {
  return (
    <div className='flex animate-pulse flex-col pt-10'>
      <div className='mb-4 h-10 rounded-md border bg-gray-300 px-2 py-2 shadow-none'></div>
      <div className='mb-4 h-10 rounded-md border bg-gray-300 px-2 py-2 shadow-none'></div>
      <div className='mt-6 h-64 w-full bg-gray-300 md:h-48'></div>
    </div>
  );
}

export default EditFormSkeleton;
