import React, { useCallback, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import AboutPage from './pages/About/AboutPage.jsx';
import ProductPage from './pages/Ads/ProductPage.jsx';
import AddAdsPage from './pages/Dashboard/pages/AddAds/AddAdsPage.jsx';
import AdminGuard from './utils/AdminGuard.jsx';
import AdsListPage from './pages/Dashboard/pages/AdsList/AdsListPage';
import AuthGuard from './utils/AuthGuard.jsx';
// import BannersPage from './pages/Dashboard/pages/Banners/BannersPage.jsx';
// import ChangePasswordPage from './pages/Password/ChangePasswordPage.jsx';
import ContactPage from './pages/Contact/ContactPage.jsx';
// import DetailsPage from './pages/Details/DetailsPage.jsx';
import ErrorPage from './pages/Errors/ErrorPage.jsx';
// import HomePage from './pages/Home/HomePage';
// import InstructionPage from './pages/Dashboard/pages/Instruction/InstructionPage.jsx';
// import LegalEntitiesPage from './pages/Dashboard/pages/Settings/pages/LegalEntities/LegalEntitiesPage.jsx';
import LoginPage from './pages/Login/LoginPage';
// import NewPasswordPage from './pages/Password/NewPasswordPage';
// import PasswordPage from './pages/Dashboard/pages/Settings/pages/Password/PasswordPage.jsx';
import PaymentsPage from './pages/Dashboard/pages/Payments/PaymentsPage.jsx';
import ReactGA from 'react-ga4';
import RegisterPage from './pages/Register/RegisterPage';
import SavedAdsPage from './pages/SavedAds/SavedAdsPage.jsx';
import SettingsPage from './pages/Dashboard/pages/Settings/SettingsPage.jsx';

import StartLayout from './pages/Layouts/StartLayout';
import StepFourPage from './pages/Dashboard/pages/AddAds/pages/StepFour/StepFourPage.jsx';
import StepOne from './pages/Dashboard/pages/Payments/pages/StepOne.jsx';
import StepOnePage from './pages/Dashboard/pages/AddAds/pages/StepOne/StepOnePage.jsx';
import StepThreePage from './pages/Dashboard/pages/AddAds/pages/StepThree/StepThreePage.jsx';
import StepTwo from './pages/Dashboard/pages/Payments/pages/StepTwo.jsx';
import StepTwoPage from './pages/Dashboard/pages/AddAds/pages/StepTwo/StepTwoPage.jsx';
// import TermsAndConditionPage from './components/footer/pages/TermsAndConditionPage.jsx';
// import UserDashboard from './pages/Dashboard/UserDashboard.jsx';
// import UsersPage from './pages/Dashboard/pages/Users/UsersPage.jsx';
import { restoreCurrency } from './store/currencySlice.jsx';
import { restoreUser } from './store/userSlice';
import { setCategories } from '@/store/categorySlice.jsx';
import { useDispatch } from 'react-redux';
import LearnMorePage from '@/pages/LearnMore/LearnMorePage.jsx';
import ProductService from '@/services/ProductService.js';

const UserDashboard = React.lazy(() => import('./pages/Dashboard/UserDashboard.jsx'));
const UsersPage = React.lazy(() => import('./pages/Dashboard/pages/Users/UsersPage.jsx'));
const BannersPage = React.lazy(() => import('./pages/Dashboard/pages/Banners/BannersPage.jsx'));
const ChangePasswordPage = React.lazy(() => import('./pages/Password/ChangePasswordPage.jsx'));
const InstructionPage = React.lazy(() => import('./pages/Dashboard/pages/Instruction/InstructionPage.jsx'));
const LegalEntitiesPage = React.lazy(() => import('./pages/Dashboard/pages/Settings/pages/LegalEntities/LegalEntitiesPage.jsx'));
const NewPasswordPage = React.lazy(() => import('./pages/Password/NewPasswordPage.jsx'));
const PasswordPage = React.lazy(() => import('./pages/Dashboard/pages/Settings/pages/Password/PasswordPage.jsx'));
const PriceListPage = React.lazy(() => import('./components/footer/pages/PriceListPage.jsx'));
const ProfilePage = React.lazy(() => import('./pages/Dashboard/pages/Settings/pages/Profile/ProfilePage.jsx'));
const SliderPage = React.lazy(() => import('./pages/Dashboard/pages/Banners/pages/SliderPage.jsx'));
const TermsAndConditionPage = React.lazy(() => import('./components/footer/pages/TermsAndConditionPage.jsx'));
const DetailsPage = React.lazy(() => import('./pages/Details/DetailsPage.jsx'));
const HomePage = React.lazy(() => import('./pages/Home/HomePage.jsx'));

const TRACKING_ID = import.meta.env.VITE_GOOGLE_TRACKING_ID; // OUR_TRACKING_IDs
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter([
  {
    path: '/',
    element: <StartLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'learn-more',
        element: <LearnMorePage />,
      },
      {
        path: 'products/:categoryName?/:subCategoryName?',
        element: <ProductPage />,
      },
      {
        path: 'about',
        element: <AboutPage />,
      },
      {
        path: 'contact',
        element: <ContactPage />,
      },
      {
        path: 'saved',
        element: (
          <AuthGuard>
            <SavedAdsPage />,
          </AuthGuard>
        ),
      },
      {
        path: 'products/details/:id',
        element: <DetailsPage />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <UserDashboard />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <AdsListPage />,
      },
      {
        path: 'addAds',
        element: <AddAdsPage />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <StepOnePage />,
          },
          {
            path: 'stepTwo',
            element: <StepTwoPage />,
          },
          {
            path: 'StepThree',
            element: <StepThreePage />,
          },
          {
            path: 'StepFour',
            element: <StepFourPage />,
          },
        ],
      },
      {
        path: 'payments',
        element: <PaymentsPage />,
        children: [
          {
            index: true,
            element: <StepOne />,
          },
          {
            path: 'step-two',
            element: <StepTwo />,
          },
        ],
      },
      {
        path: 'users',
        element: (
          <AdminGuard>
            <UsersPage />
          </AdminGuard>
        ),
      },
      {
        path: 'banners',
        element: <BannersPage />,
        children: [
          {
            path: 'slider1',
            element: <SliderPage slider='slider1' />,
          },
          {
            path: 'slider2',
            element: <SliderPage slider='slider2' />,
          },
          {
            path: 'slider3',
            element: <SliderPage slider='slider3' />,
          },
          {
            path: 'slider4',
            element: <SliderPage slider='slider4' />,
          },
          {
            path: 'slider5',
            element: <SliderPage slider='slider5' />,
          },
        ],
      },
      {
        path: 'instructions',
        element: <InstructionPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
        children: [
          {
            index: true,

            element: <ProfilePage />,
          },
          {
            path: 'password',
            element: <PasswordPage />,
          },
          {
            path: 'legal-entities',
            element: <LegalEntitiesPage />,
          },
        ],
      },
    ],
  },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'change-password', element: <ChangePasswordPage /> },
  { path: 'new-password', element: <NewPasswordPage /> },
  { path: 'instruction', element: <InstructionPage /> },
  { path: 'ads-list', element: <AdsListPage /> },
  { path: 'terms-and-conditions', element: <TermsAndConditionPage /> },
  { path: 'price-list', element: <PriceListPage /> },
]);

const App = () => {
  const dispatch = useDispatch();
  const getCategories = useCallback(async () => {
    const response = await ProductService.getAllCategories();
    dispatch(setCategories(response.data));
  }, [dispatch]);

  useEffect(() => {
    getCategories().finally();
    dispatch(restoreUser());
    dispatch(restoreCurrency());
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    });
  }, [getCategories, dispatch]);

  return <RouterProvider router={router} />;
};

export default App;
