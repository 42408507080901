import axiosInstance from '../helpers/axiosConfig';
import axiosInstanceMultipart from '../helpers/axiosConfigMultipart';

class UserService {
  static me = async () => axiosInstance.get('/users/me');
  static getAllUsers = async (pagination = '', config = {}) => axiosInstance.get(`/users?${pagination}`, config);
  static getSingleUser = (id) => axiosInstance.get(`/users/${id}`);
  static loginUser = async (body) => axiosInstance.post('/auth/login', body);
  static loginWithGoogle = async (body) => axiosInstance.post('/auth/google', body);
  static registerUser = (body) => axiosInstance.post('/auth/register', body);
  static editUser = (body, id) => axiosInstance.put(`/users/${id}`, body);
  static changeUserStatus = (id) => axiosInstance.put(`/users/changeUserStatus/${id}`);
  static updateUserPassword = (body, id) => axiosInstance.put(`/users/${id}/change-password`, body);
  static userAdsDashboard = async (params, config) => axiosInstance.get(`/products/userAds?${params}`, config);
  static changeUserImage = async (formData) => axiosInstanceMultipart.post('/users/changeUserImage', formData);
  static deleteUser = async (id) => axiosInstance.delete(`/users/${id}`);
  static changeCompanyInfo = async (body, id) => axiosInstance.put(`/users/company-info/${id}`, body);
  static getExportedData = async () => axiosInstance.get('/users/export-users');
}

export default UserService;
