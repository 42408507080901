import SortableTable from './components/SortableTable';

function ImagesDnDGrid({ images, className, removeImage, setImages, setPosition, ...props }) {
  return (
    <SortableTable
      {...props}
      images={images}
      className={className}
      setPosition={setPosition}
      removeImage={removeImage}
      setImages={setImages}
    ></SortableTable>
  );
}

export default ImagesDnDGrid;
