import * as Yup from 'yup';

import ContactService from '../../../../services/ContactServices';
import FormRow from '../../../../components/formRow/FormRow';
import QuestionsAnswers from '../../utils/QuestionsAnswers';
import SingleQuestion from './SingleQuestion';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const ContactPageForm = () => {
  const { questionAnswer } = QuestionsAnswers();
  const { t } = useTranslation();
  const regexText = /^[a-zA-Z0-9./-_-žćšđčŽĆŠĐČ ]+$/;

  const contactSchema = Yup.object().shape({
    fullName: Yup.string()
      .test('is-valid', t('yupInvalidCharacters'), (value) => regexText.test(value))
      .required(t('yupRequired')),
    email: Yup.string()
      .test('is-valid', t('yupInvalidCharacters'), (value) => regexText.test(value))
      .email(t('yupEmail'))
      .required(t('yupRequired')),
    phone: Yup.string()
      .test('is-valid', t('yupInvalidCharacters'), (value) => regexText.test(value))
      .required(t('yupRequired')),
    theme: Yup.string()
      .test('is-valid', t('yupInvalidCharacters'), (value) => regexText.test(value))
      .required(t('yupRequired')),
    message: Yup.string()
      // .test('is-valid', t('yupInvalidCharacters'), (value) => regexText.test(value))
      .required(t('yupRequired')),
  });

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      theme: '',
      message: '',
    },

    validationSchema: contactSchema,

    onSubmit: async (values) => {
      try {
        await ContactService.createContact(values);
        toast.success(t('contactSendSuccessfully'));
      } catch (error) {
        console.log(error);
      }
    },
  });

  const showError = (name) => formik.errors[name] && formik.touched[name] && formik.errors[name];

  const handleFieldChange = (fieldName, value) => {
    formik.setFieldTouched(fieldName, true);
    formik.validateForm().then(() => {
      formik.setFieldValue(fieldName, value);
    });
  };

  return (
    <section className='grid w-full place-items-center px-4 pb-14 pt-4 md:px-8 lg:pt-[4.4rem]'>
      <div className='grid w-full max-w-[82.25rem] md:grid-cols-2 md:items-start md:justify-center'>
        <div className='w-full pr-8 pt-10 lg:pr-14'>
          <div className='border-b-[1px] border-b-[#D6D6D6] pb-9'>
            <h1 className=' text-[0.875rem] font-bold uppercase leading-[1.125rem] text-[#484848]'>{t('informationAndQuestion')}</h1>
            <h1 id='faq' className='mt-4  text-[1.6rem] font-bold uppercase leading-[1.875rem] text-[#484848]'>
              {t('faq')}
            </h1>
          </div>
          {/* FAQ */}
          <div>
            {questionAnswer.map((questAnsw) => {
              const { id, question, answer } = questAnsw;
              return <SingleQuestion key={id} question={question} answer={answer} />;
            })}
          </div>
        </div>
        <div className='w-full pt-10 md:border-l-[1px] md:border-l-[#D6D6D6] md:pl-14 '>
          <div className='pb-10'>
            <h1 className=' text-[0.875rem] font-bold uppercase leading-[1.125rem] text-[#484848]'>{t('forAllInformationAboutUs')}</h1>
            <h1 className='mt-4  text-[1.6rem] font-bold uppercase leading-[1.875rem] text-[#484848]'>{t('contactUs')}</h1>
          </div>
          <form onSubmit={formik.handleSubmit} className='w-full'>
            <div className='grid w-full items-center break55:grid-cols-2 break55:gap-4 md:grid-cols-1 md:gap-0 break11:grid-cols-2 break11:gap-9'>
              <FormRow
                labelText={t('yourName')}
                labelClassName={'text-[0.875rem] leading-[1.125rem] font-medium  text-[#484848] mb-[10px]'}
                showError={showError('fullName')}
                name={'fullName'}
                onChange={(e) => handleFieldChange('fullName', e.target.value)}
                type={'text'}
                value={formik.values.fullName}
                className={'mt-0 rounded-[1rem] border-[2px] border-[#707070] px-[1rem] py-[0.6rem]  text-[1rem] font-normal shadow-none'}
              />
              <div className='h-[10px] break55:hidden md:block break11:hidden'></div>
              <FormRow
                labelText={t('yourEmail')}
                labelClassName={'text-[0.875rem] leading-[1.125rem] font-medium  text-[#484848] mb-[10px]'}
                showError={showError('email')}
                name={'email'}
                type={'email'}
                value={formik.values.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                className={'mt-0 rounded-[1rem] border-[2px] border-[#707070] px-[1rem] py-[0.6rem]  text-[1rem] font-normal shadow-none'}
              />
            </div>
            <div className='mt-[10px] grid w-full items-center break55:mt-6 break55:grid-cols-2 break55:gap-4  md:mt-[10px] md:grid-cols-1 md:gap-0 break11:mt-6 break11:grid-cols-2 break11:gap-9'>
              <FormRow
                labelText={t('phone')}
                labelClassName={'text-[0.875rem] leading-[1.125rem] font-medium  text-[#484848] mb-[10px]'}
                showError={showError('phone')}
                name={'phone'}
                type={'text'}
                value={formik.values.phone}
                onChange={(e) => handleFieldChange('phone', e.target.value)}
                className={'mt-0 rounded-[1rem] border-[2px] border-[#707070] px-[1rem] py-[0.6rem]  text-[1rem] font-normal shadow-none'}
              />
              <div className='h-[10px] break55:hidden md:block break11:hidden'></div>
              <FormRow
                labelText={t('theme')}
                labelClassName={'text-[0.875rem] leading-[1.125rem] font-medium  text-[#484848] mb-[10px]'}
                showError={showError('theme')}
                name={'theme'}
                type={'text'}
                value={formik.values.theme}
                onChange={(e) => handleFieldChange('theme', e.target.value)}
                className={'mt-0 rounded-[1rem] border-[2px] border-[#707070] px-[1rem] py-[0.6rem]  text-[1rem] font-normal shadow-none'}
              />
            </div>
            <div className='mt-[10px] grid w-full items-center break55:mt-6 md:mt-[10px] break11:mt-6'>
              <label htmlFor='message' className='mb-[10px]  text-[0.875rem] font-medium leading-[1.125rem] text-[#484848]'>
                {t('message')} <span className='text-sm text-pink-500'>{showError('message')}</span>
              </label>
              <div>
                <textarea
                  name={'message'}
                  type={'text'}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  className='mt-0 w-full rounded-[1rem] border-[2px] border-[#707070] px-[1rem] py-[0.6rem]  text-[1rem] font-normal shadow-none'
                  rows={'8'}
                ></textarea>
              </div>
            </div>
            <div className='mt-3 grid w-full items-center justify-start'>
              <button
                type='submit'
                className='rounded-lg bg-primary-yellow px-12 py-3  text-[1rem] font-semibold leading-5 text-black transition-all duration-300 hover:bg-[#d3b000]'
              >
                {t('sendMessage')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactPageForm;
