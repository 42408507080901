import { useEffect, useState } from 'react';

import ProductService from '../../../../../../services/ProductService';
import SingleCategory from './SingleCategory';
import { get } from '../../../../../../utils/localStorage';
import { useTranslation } from 'react-i18next';

const StepOnePage = () => {
  const { t } = useTranslation();
  const [_selectedCategory, setSelectedCategory] = useState(null);
  const [allCategories, setAllCategories] = useState([]);

  const getCurrCategory = async () => {
    const ad = await get('er_ad');

    if (ad) setSelectedCategory(ad?.categoryId);
  };

  const getAllCat = async () => {
    const response = await ProductService.getAllCategories();
    setAllCategories(response.data);
  };

  useEffect(() => {
    getCurrCategory();
    getAllCat();
  }, []);

  // const handleWarning = () => {
  //   toast.warning(t('mustSelectCategory'));
  // };

  return (
    <div className='grid min-h-screen'>
      <div className='grid'>
        <nav className='grid w-full place-items-center py-[1.625rem] pb-6 xl:flex  xl:items-center'>
          <div className='grid place-items-center text-center xl:place-items-start'>
            <div className='relative w-48 bg-primary-yellow text-left'>
              <h1 className='pl-3  text-[2rem] font-semibold uppercase leading-[2.8125rem] text-[#000000]'>{t('step')} 1</h1>
              <div className='absolute -right-[2rem] top-0 h-[2.8125rem] w-[2.8125rem] rotate-45 bg-[#FAF9F5]'></div>
            </div>
            <h1 className='mt-2  text-[1.625rem] font-medium uppercase leading-[2.25rem] text-[#5A5A5A]'>{t('selectCategory')}</h1>
          </div>
          <h1 className='grow text-center text-xl font-medium leading-[3.6875rem] text-[#000000] md:text-2xl xl:pr-[16.1875rem] xl:text-3xl'>
            {t('addAds')}
          </h1>
        </nav>
        <hr />
        <section className='grid place-items-center'>
          <div className='grid grid-cols-2 items-start justify-center gap-6 py-3 xl:grid-cols-3'>
            {allCategories.map((category) => {
              const { _id, key, image } = category;
              return (
                <SingleCategory key={_id} id={_id} name={key} image={image} btnClass='hover:text-[#696969] transition-all duration-100' />
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default StepOnePage;
