import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import getSlugText from '@/utils/getSlugText.js';
import { Link } from 'react-router-dom';
import { getCategoryKey, getCategoryImage } from '@/utils/category.js';

const CategoriesList = ({
  textColor,
  imageClassName = 'border-4 border-primary-yellow h-40 w-40 xl:h-48 xl:w-48',
  textClassName = 'w-40 text-xs xl:w-48 xl:text-sm',
  gapClassName = 'gap-4 lg:gap-1',
  gridCols = 2,
}) => {
  const { t } = useTranslation();
  const { categories } = useSelector((state) => state.categoryStore);
  return (
    <div
      className={`z-5 mx-auto grid w-full max-w-[450px] grid-cols-${gridCols} items-start sm:max-w-[760px] sm:grid-cols-3 lg:max-w-[1200px] lg:grid-cols-6 ${gapClassName}`}
    >
      {categories &&
        categories.map((category) => (
          <div key={category.name} className='z-5 flex cursor-pointer flex-col items-center justify-center'>
            <Link
              to={`/products/${getSlugText(t(getCategoryKey(category.name)))}`}
              onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' })}
            >
              <div
                className={`rounded-xl bg-cover ${imageClassName}`}
                style={{ backgroundImage: `url(${getCategoryImage(category.name)})` }}
              />
              <div
                className={`inline-block overflow-hidden text-center font-semibold uppercase ${textClassName}`}
                style={{ color: textColor }}
              >
                {t(getCategoryKey(category.name))}
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default CategoriesList;
