import { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

const Slider = ({ className, imageClassName, link, banners, alt }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === banners?.length - 1 ? 0 : prevImage + 1));
    }, 7000);

    return () => clearInterval(interval);
  }, [banners?.length]);

  const currentLink = link && link[currentImage];

  return (
    <div className={twMerge(`${className}`)}>
      <a target='_blank' rel='noreferrer' href={currentLink}>
        <img
          alt={alt}
          src={banners?.length > 0 ? banners[currentImage] : null}
          // style={{
          //   transition: 'opacity 1s ease-in-out',
          // }}
          className={twMerge(`h-full w-full rounded-xl object-contain ${imageClassName}`)}
        />
      </a>
    </div>
  );
};

export default Slider;
