import * as Yup from 'yup';

import { AiFillEye, AiOutlineEye } from 'react-icons/ai';
import { BsArrowLeftCircle, BsFillPersonFill } from 'react-icons/bs';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import FormRow from '../../components/formRow/FormRow.jsx';
import { GiPadlock } from 'react-icons/gi';
import { IoIosMail } from 'react-icons/io';
import { IoLocationOutline } from 'react-icons/io5';
import SidePreview from '../../assets/side-preview.svg';
import UserService from '../../services/UserServices.js';
import logo from '../../assets/logoLogin1.png';
import { set } from '../../utils/localStorage.js';
import { storeUser } from '../../store/userSlice.jsx';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RegisterPage = () => {
  const [agree, setAgree] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const refFocus = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const regexName = /^[a-zA-ZžćšđčŽĆŠĐČ]+$/;

  useEffect(() => {
    refFocus.current.focus();
  }, [inputFocused]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .test('is-valid', t('yupFirstNameInvalidCharacters'), (value) => regexName.test(value))
        .required(t('yupRequired')),
      lastName: Yup.string()
        .test('is-valid', t('yupLastNameInvalidCharacters'), (value) => regexName.test(value))
        .required(t('yupRequired')),
      email: Yup.string().required(t('yupRequired')).email(t('yupEmail')),
      password: Yup.string().min(5, t('passwordLength')).required(t('yupRequired')),
    }),

    onSubmit: (values) => {
      if (!agree) return toast.warning(t('pleaseAcceptTerms'));
      const newValues = {
        ...values,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim(),
        password: values.password.trim(),
      };
      UserService.registerUser(newValues)
        .then(async (res) => {
          if (res.status === 200) {
            dispatch(storeUser(res.data.newUser));
            localStorage.setItem('er_token', res.data.token);
            await set('er_saved', res.data.wishList);
            navigate('/');
          } else {
            toast.warning(t('alreadyRegistered'));
          }
        })
        .catch((err) => console.log(err));
      setInputFocused((state) => !state);
      formik.resetForm();
    },
  });

  const showError = (name) => {
    return formik.touched[name] && formik.errors[name] ? <span className='text-[0.875rem] text-red-600'>{formik.errors[name]}</span> : null;
  };

  const handleFieldChange = (fieldName, value) => {
    formik.setFieldTouched(fieldName, true);
    formik.validateForm().then(() => {
      formik.setFieldValue(fieldName, value);
    });
  };

  return (
    <div className='grid h-[100svh] lg:grid-cols-2'>
      <div className='hidden lg:grid lg:w-full lg:place-content-center lg:bg-primary-yellow'>
        <img alt='side-preview' className='mx-auto w-full' src={SidePreview} />
      </div>
      <div className='grid w-full lg:gap-4'>
        <div className='mx-auto w-3/5 text-sm 2xl:text-base'>
          <Link to='/'>
            <img src={logo} alt='logo' className='sm:3/6 m-auto w-4/6 pt-6 md:w-3/6 lg:w-3/6 lg:pt-8' />
          </Link>
          <h1 className='flex w-full justify-center pt-2 font-semibold lg:pt-4'>{t('createYouERentAccount')}</h1>

          <div className='mx-auto pt-6'>
            <form onSubmit={formik.handleSubmit} className='flex w-full flex-col gap-2 lg:gap-4 '>
              <div className='flex w-full flex-col gap-2 lg:flex-row lg:gap-6'>
                <FormRow
                  labelText={t('firstName')}
                  showError={showError('firstName')}
                  type={'text'}
                  name={'firstName'}
                  placeholder={t('enterYourFirstName')}
                  value={formik.values.firstName}
                  onChange={(e) => handleFieldChange('firstName', e.target.value)}
                  onBlur={formik.handleBlur}
                  className={'h-10 rounded-md pl-10 font-light shadow-md  lg:placeholder:text-[10px] 2xl:placeholder:text-base '}
                  icon={<BsFillPersonFill />}
                  iconClassName='text-xl 2xl:text-2xl  text-primary-yellow'
                  refFocus={refFocus}
                />
                <FormRow
                  labelText={t('lastName')}
                  showError={showError('lastName')}
                  type='text'
                  name='lastName'
                  value={formik.values.lastName}
                  onChange={(e) => handleFieldChange('lastName', e.target.value)}
                  placeholder={t('enterYourLastName')}
                  className={'h-10 rounded-md pl-10 font-light shadow-md  lg:placeholder:text-[10px] 2xl:placeholder:text-base'}
                  icon={<BsFillPersonFill />}
                  iconClassName='text-xl 2xl:text-2xl  text-primary-yellow'
                />
              </div>
              <FormRow
                labelText={t('email')}
                showError={showError('email')}
                type='email'
                name='email'
                className='h-10 rounded-md shadow-md lg:placeholder:text-[10px]  2xl:placeholder:text-base '
                placeholder={t('enterYourEmail')}
                value={formik.values.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                icon={<IoIosMail />}
                iconClassName='text-xl 2xl:text-2xl  text-primary-yellow'
              />
              <FormRow
                labelText={t('password')}
                showError={showError('password')}
                className='h-10 rounded-md shadow-md  lg:placeholder:text-[10px] 2xl:placeholder:text-base '
                type={!viewPassword ? 'password' : 'text'}
                name='password'
                placeholder={t('enterYourPassword')}
                value={formik.values.password}
                onChange={(e) => handleFieldChange('password', e.target.value)}
                icon={<GiPadlock />}
                iconClassName='text-xl 2xl:text-2xl text-primary-yellow '
                iconView={
                  !viewPassword ? (
                    <AiOutlineEye onClick={() => setViewPassword(!viewPassword)} />
                  ) : (
                    <AiFillEye onClick={() => setViewPassword(!viewPassword)} />
                  )
                }
                iconViewClassName='cursor-pointer text-xl 2xl:text-2xl '
              />

              <div className=' flex items-center gap-2 px-2 '>
                <input
                  type='checkbox'
                  name='checkbox'
                  value={agree}
                  checked={agree}
                  onChange={() => setAgree(!agree)}
                  className=' checked:accent-white'
                />
                <p className='font-normal'>{t('acceptTerms')}</p>
              </div>
              <button type='submit' className='w-full rounded-2xl bg-primary-yellow px-2 py-2 text-black  hover:bg-[#E5BF01] 2xl:py-3 '>
                {t('signUp')}
              </button>
            </form>
            <div className='flex flex-col justify-center gap-2 pt-2 text-center lg:flex-row lg:pt-2'>
              <p>{t('alreadyHaveAccount')}</p>
              <NavLink className='text-primary-yellow ' to='/login'>
                {t('signIn')}
              </NavLink>
            </div>
          </div>
        </div>
        <div className='flex h-full w-full items-center justify-end lg:items-end'>
          <Link to='/' className='flex w-full items-center justify-center gap-1 pb-2 lg:justify-end lg:pr-4'>
            <BsArrowLeftCircle className='' />
            <p className='text-[10px] font-medium 2xl:text-xl'>{t('backToHome')}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
