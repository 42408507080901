const currencyRates = {
  success: true,
  timestamp: 1701034023,
  base: 'EUR',
  date: '2023-11-26',
  rates: {
    RSD: 118,
  },
};

const convertCurrency = (price = 0, currency, fixed = 0) => {
  if (typeof price === 'string' || price instanceof String) price = parseFloat(price);

  return currency === 'rsd' ? parseFloat(price * currencyRates.rates.RSD).toFixed(0) : parseFloat(price).toFixed(fixed);
};

const convertRSDCurrency = (price = 0, currency, fixed = 0) => {
  if (typeof price === 'string' || price instanceof String) price = parseFloat(price);

  return currency === 'eur' ? parseFloat(price / currencyRates.rates.RSD).toFixed(fixed) : parseFloat(price).toFixed(fixed);
};

export { convertCurrency, convertRSDCurrency };
