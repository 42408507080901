import { useTranslation } from 'react-i18next';

const SavedAdsPageHeader = () => {
  const { t } = useTranslation();
  return (
    <section className='w-full bg-[url("./assets/headerAboutUsContact.png")] bg-cover bg-no-repeat'>
      <div className='grid place-items-center py-32'>
        <h1 className='text-5xl font-medium text-primary-yellow  md:text-7xl'>{t('savedAds')}</h1>
      </div>
    </section>
  );
};

export default SavedAdsPageHeader;
