const CardSkeleton = () => {
  return (
    <div className='w-full  overflow-hidden rounded-lg text-xs shadow-md  2xl:text-lg'>
      <div className='overflow-hidden'>
        {/* <div className='h-[222px] w-full animate-pulse bg-gray-200 md:h-[190px]  xl:h-[238px] 2xl:h-[298px] '></div> */}
        <div className='h-[312px] w-full animate-pulse bg-gray-200 '></div>
      </div>
      <div className='p-5'>
        <div className='mb-2 flex items-center justify-between'>
          <div className='flex animate-pulse items-center justify-around gap-2 rounded-sm bg-gray-200 p-2'>
            <div className='h-4 w-4 animate-pulse rounded-full bg-gray-200'></div>
            <div className='h-4 w-4 animate-pulse rounded-full bg-gray-200'></div>
          </div>
          <div className='animate-pulse text-gray-200'></div>
        </div>
        <div className='mb-2 animate-pulse truncate text-left text-gray-200'></div>
        <div className='flex animate-pulse text-left text-gray-200'>
          <div className='mr-2 h-4 w-4 animate-pulse rounded-full bg-gray-200'></div>
          <div className='h-4 w-20 animate-pulse bg-gray-200'></div>
        </div>
      </div>
      <div className='flex h-8 2xl:h-12'>
        <button className='flex w-1/2 animate-pulse items-center justify-center gap-1 bg-[#EDEDED]'>
          <div className='h-4 w-4 animate-pulse rounded-full bg-gray-200'></div>
        </button>
        <div className='flex w-1/2 animate-pulse items-center justify-center bg-gray-300'>
          {/* <div className='mr-2 h-4 w-4 animate-pulse rounded-full bg-gray-200'></div> */}
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;
