import { MdClose, MdDelete } from 'react-icons/md';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ProductService from '../../../../services/ProductService';
import { AiOutlineSearch } from 'react-icons/ai';
import Dropdown from '../../../../components/dropdown/Dropdown';
import EditFormSkeleton from './components/EditFormSkeleton';
import FormRow from '../../../../components/formRow/FormRow';
import Modal from '../../../../components/modal/modal';
import NoResultMessage from '../../../../components/noResultMessage/NoResultMessage';
import PaginationContainer from '../../../Ads/components/PaginationContainer';
import SingleAdArticle from './components/SingleAdArticle';
import SingleAdTable from './components/SingleAdTable';
import UserService from '../../../../services/UserServices';
import axios from 'axios';
import formatDate from '../../../../helpers/formatDate';
import getLanguageVersion from '../../../../utils/getLanguageVersion';
import { toast } from 'react-toastify';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { SORT } from '../../../../constants/constants';

const ITEMS_PER_PAGE = 15;

const EditAdPage = React.lazy(() => import('../EditAds/EditAdPage'));

const AdsListPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalAds, setTotalAds] = useState(0);

  const [_adId, setAdId] = useState(null);

  const [adsData, setAdsData] = useState({ totalAds: 0, numOfPages: 0, currentPage: 1, ads: [] });
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [filterObject, setFilterObject] = useState({ limit: ITEMS_PER_PAGE, page: page, sort: 'createAt', order: -1 });
  const [dropdownText, setDropdownText] = useState('sort');
  const search = useDebounce(filterObject?.search, 300);
  const [modalFlag, setModalFlag] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedAd, setSelectedAd] = useState({});
  const { user } = useSelector((state) => state.userStore);
  const [selectedAds, setSelectedAds] = useState([]);

  const openModal = (id) => {
    setAdId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteAd = async () => {
    try {
      let source = axios.CancelToken.source();
      await ProductService.deleteAd(selectedAd._id);
      await filteredAds(source);
    } catch (err) {
      err.handleGlobally();
    }
    setIsModalOpen(false);
    toast.success(t('adDeleteSuccessfully'));
  };

  const handleFilterObject = (e) => {
    if (e.target.type === 'checkbox') e.target.value = e.target.checked;
    setFilterObject({ ...filterObject, [e.target.name]: e.target.value });
  };

  const filteredAds = useCallback(
    async (source) => {
      try {
        const queryParams = new URLSearchParams(filterObject).toString();
        const response = await UserService.userAdsDashboard(queryParams, { cancelToken: source.token });

        setTotalAds(response.data.totalCount);
        setAdsData({
          numberOfPages: Math.ceil(response.data.totalCount / ITEMS_PER_PAGE),
          currentPage: page,
          ads: response.data.data,
        });

        const res = await ProductService.getAllCategories();
        setAllCategories([{ _id: 'all', key: 'all' }, ...res.data]);
      } catch (err) {
        if (err.message !== 'canceled') console.log(err);
      }
    },
    [page, filterObject, search]
  );

  useEffect(() => {
    setFilterObject((prev) => ({ ...prev, page, category: selectedCategory }));
  }, [page, selectedCategory]);

  useEffect(() => {
    const params = searchParams.get('payment_status');
    if (params) {
      if (params === 'success') toast.success(t('paymentSuccessful'));
      else toast.error(t('somethingWrongWithPayment'));

      searchParams.delete('payment_status');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    let source = axios.CancelToken.source();
    filteredAds(source);
    return () => source.cancel();
  }, [filteredAds]);

  function handleModalClose() {
    let source = axios.CancelToken.source();

    setIsModalOpen(false);
    filteredAds(source);
  }

  const handleChangeStatus = async () => {
    setIsModalOpen((prev) => !prev);
    try {
      await ProductService.changeAdStatus(selectedAd._id);

      const adIndex = adsData.ads.findIndex((ad) => ad._id === selectedAd._id);

      const changedAd = adsData.ads[adIndex];

      changedAd.isPaused = !changedAd.isPaused;

      adsData.ads.splice(adIndex, 1, changedAd);

      setAdsData({ ...adsData });
    } catch (err) {
      console.log(err);
    }
  };

  const modalTitle = () => {
    if (modalFlag === 'deleteAd') {
      return t('deleteAd');
    }
    if (modalFlag === 'editAd') {
      return t('editAd');
    }
    if (modalFlag === 'active') {
      return t('deactivateAd');
    }
    if (modalFlag === 'inactive') {
      return t('activateAd');
    }
  };

  const handleSelectedAds = (id) => {
    setSelectedAds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((adId) => adId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const exportAds = async () => {
    try {
      let response = await ProductService.exportAds(selectedAds);

      const BOM = '\uFEFF';
      const blob = new Blob([BOM + response.data], { type: 'text/csv;charset=UTF-8' });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.download = `ads.csv`;

      // Attach the Blob to the download link
      downloadLink.href = window.URL.createObjectURL(blob);

      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(downloadLink.href);

      setSelectedAds([]);
    } catch (error) {
      console.error('Error downloading CSV:', error);
      // Handle the error as needed
    }
  };

  return (
    <main className='h-full p-5 text-base'>
      <nav className='grid gap-[1rem] pb-10 xl:flex xl:items-center xl:justify-between'>
        <h1 className='p-4 text-3xl font-medium sm:text-4xl'>{t('ads')}</h1>
        <FormRow
          layout={'w-full max-w-lg'}
          icon={<AiOutlineSearch />}
          iconClassName='left-5 '
          className={
            'mt-0 h-[3.625rem] w-full rounded-[1.5625rem] border-[1px] pl-[3.4375rem] placeholder:text-[1.125rem] placeholder:font-normal placeholder:leading-[1.375rem] placeholder:text-[#272D3B75]'
          }
          name='search'
          placeholder={t('searchAds')}
          onChange={handleFilterObject}
        />

        <div className='flex gap-[1rem] sm:flex'>
          {user.role === 'admin' ? (
            <button onClick={() => exportAds()} className='rounded-3xl bg-primary-yellow px-4 py-2 lg:w-40'>
              {t('exportAds')}
            </button>
          ) : null}
          <div className='grid w-36 place-items-center rounded-[0.5rem] px-[0.7rem] '>
            <Dropdown
              className='h-12 w-full justify-around gap-2 text-base font-light shadow-md '
              dropdownClass='w-full top-14 px-2 py-2'
              options={
                <>
                  <li
                    className='w-full rounded-md px-2 hover:shadow-md'
                    onClick={() => {
                      // handleSort(SORT.PRICE_LOW);
                      setDropdownText('priceToLow');
                      setFilterObject({ ...filterObject, page: 1, sort: 'priceFrom', order: 1 });
                    }}
                  >
                    {t('priceToLow')}
                  </li>
                  <li
                    className='w-full rounded-md px-2 hover:shadow-md'
                    onClick={() => {
                      // handleSort(SORT.PRICE_HIGH);
                      setDropdownText('priceToHigh');
                      setFilterObject({ ...filterObject, page: 1, sort: 'priceFrom', order: -1 });
                    }}
                  >
                    {t('priceToHigh')}
                  </li>
                  <li
                    className='w-full rounded-md px-2 hover:shadow-md'
                    onClick={() => {
                      // handleSort(SORT.NEWEST);
                      setDropdownText('newest');
                      setFilterObject({ ...filterObject, sort: 'startDate', order: -1 });
                    }}
                  >
                    {t('newest')}
                  </li>
                  <li
                    className='w-full rounded-md px-2 hover:shadow-md'
                    onClick={() => {
                      // handleSort(SORT.OLDEST);
                      setDropdownText('oldest');
                      setFilterObject({ ...filterObject, sort: 'startDate', order: 1 });
                    }}
                  >
                    {t('oldest')}
                  </li>
                </>
              }
              text={t(dropdownText)}
            ></Dropdown>
            {/* <select
                id='sort'
                name='sort'
                type='text'
                className='cursor-pointer border-none bg-transparent   font-normal '
                onChange={handleSort}
                // defaultValue={SORT.NEWEST}
              >
                <option className='text-[#272D3B75]'>{t('sort')}</option>
                <option value={SORT.NEWEST}>{t(SORT.NEWEST)}</option>
                <option value={SORT.OLDEST}>{t(SORT.OLDEST)}</option>
                <option value={SORT.PRICE_HIGH}>{t(SORT.PRICE_HIGH)}</option>
                <option value={SORT.PRICE_LOW}>{t(SORT.PRICE_LOW)}</option>
              </select> */}
          </div>
          {/* button container */}
          <div className='grid gap-4 sm:flex'>
            {/* <div>
                <button
                  type='button'
                  className='rounded-[1.25rem] bg-[#FED400] px-[2.625rem] py-[0.8125rem]  text-[1.25rem] font-semibold leading-[1.5rem] text-[#FFFFFF] transition-all duration-300 hover:bg-[#d3b000]'
                >
                  {t('filter')}
                </button>
              </div> */}
            <div>
              <button
                type='button'
                className='rounded-3xl bg-[#FED400] px-7 py-[0.8125rem]   font-medium  text-black transition-all duration-300 hover:bg-[#d3b000]'
                onClick={() => navigate('/dashboard/addAds')}
              >
                + {t('addAds')}
              </button>
            </div>
          </div>
        </div>
      </nav>
      <hr />
      {/* List of ads */}
      <section>
        {/* Table view */}
        <table
          className={
            'hidden text-sm  xl:table  xl:w-full xl:table-auto xl:border-separate xl:border-spacing-y-6 xl:border-y xl:text-center'
          }
        >
          {/* Header of table */}
          <thead className='font-semibold '>
            <tr>
              <th className='w-[20%]'>
                <h1 className='pr-7'>{t('adName')}</h1>
              </th>
              {user.role === 'admin' ? (
                <th>
                  <h1 className='w-[80%] pr-3'>{t('email')}</h1>
                </th>
              ) : null}
              <th>
                <div className='flex w-full gap-1'>
                  <h1>{t('category')}</h1>

                  <Dropdown
                    noP
                    dropdownClass='top-6 2xl:top-8 -left-26 w-52 p-2'
                    className=' border border-[#707070] py-0 shadow-none'
                    options={allCategories.map((category) => (
                      <FormRow
                        onClick={() => {
                          setSelectedCategory(category._id);
                        }}
                        labelClick={() => {
                          setSelectedCategory(category._id);
                        }}
                        value={category._id}
                        name={category.key}
                        type='radio'
                        readOnly={true}
                        checked={selectedCategory === category._id}
                        key={category._id}
                        layout='flex gap-1 p-0'
                        labelText={t(category.key)}
                        labelClassName='order-2 w-full cursor-pointer font-medium'
                        className='m-0  bg-none p-0 shadow-none'
                        inputClass='w-4'
                      />
                    ))}
                  />
                </div>
              </th>
              <th>
                <h1>{t('created')}</h1>
              </th>
              <th>
                <h1>{t('validUntil')}</h1>
              </th>
              <th>
                <h1>{t('paid')}</h1>
              </th>
              <th>
                <h1>
                  {t('status')}
                  {user.role === 'admin' && (
                    <input
                      className='ml-2'
                      type='checkbox'
                      name='isPaused'
                      value={filterObject.isPaused === 'true'}
                      checked={filterObject.isPaused === 'true'}
                      onChange={handleFilterObject}
                    ></input>
                  )}
                </h1>
              </th>
              <th>
                <h1>{t('uniqueId')}</h1>
              </th>
              <th>
                <h1>{t('action')}</h1>
              </th>
            </tr>
          </thead>
          {adsData.ads.length !== 0
            ? adsData.ads.map((add, index) => {
                return (
                  <SingleAdTable
                    id={add._id}
                    key={index + add._id}
                    adName={getLanguageVersion(i18n.language, add.title, add.title_en)}
                    image={add.images[0] ? `${import.meta.env.VITE_IMAGE_URL}${add.images[0]}` : null}
                    created={formatDate(add.createAt)}
                    isPaused={add.isPaused}
                    category={t(add.category[0]?.key)}
                    paid={t(add.adType)}
                    endDate={formatDate(add.endDate)}
                    openModal={() => {
                      openModal();
                      setSelectedAd(add);
                    }}
                    role={user?.role}
                    actions
                    setModalFlag={setModalFlag}
                    uniqueId={add.uniqueId}
                    email={add.email}
                    check={false}
                    handleAds={handleSelectedAds}
                    selectedAd={selectedAds.find((adId) => adId === add._id)}
                  />
                );
              })
            : null}
        </table>
        {adsData.ads.length === 0 ? <NoResultMessage /> : null}
        <div className='xl:hidden'>
          {/* Article view */}
          {adsData.ads.length !== 0 ? (
            <div className={'grid gap-3 pt-2 sm:grid-cols-2 lg:grid-cols-3'}>
              {adsData.ads.map((add, index) => {
                return (
                  <SingleAdArticle
                    id={add._id}
                    key={index}
                    adName={getLanguageVersion(i18n.language, add.title, add.title_en)}
                    image={add.images[0] ? `${import.meta.env.VITE_IMAGE_URL}${add.images[0]}` : null}
                    created={formatDate(add.startDate)}
                    isPaused={add.isPaused}
                    category={t(add.category[0]?.key)}
                    paid={t(add.adType)}
                    openModal={() => {
                      openModal();
                      setSelectedAd(add);
                    }}
                    role={user?.role}
                    email={add.email}
                    actions
                    edit
                    setModalFlag={setModalFlag}
                    // check
                    handleAds={handleSelectedAds}
                    selectedAd={selectedAds.includes(add._id)}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </section>
      {/*Num of ads and pagination */}
      <footer className='grid place-items-center gap-[1rem]  py-[2.4375rem] sm:flex sm:items-center sm:justify-between'>
        {adsData.ads.length === 0 ? null : (
          <h1 className='text-[#272D3B4F]'>
            {t('displayed')}
            <span className='text-[#272D3B]'> {adsData.ads.length * page} </span>
            {t('of')}
            <span className='text-[#272D3B]'> {totalAds}</span>
          </h1>
        )}
        <div className='max-w-min'>
          <PaginationContainer setCurrentPage={setPage} totalCount={totalAds} currentPage={page} pageSize={ITEMS_PER_PAGE} />
        </div>
      </footer>
      <hr />
      <Modal
        modalType='edit'
        modalFlag={modalFlag}
        isOpen={isModalOpen}
        onClose={closeModal}
        modalWidth={modalFlag !== 'editAd' ? 'w-[600px] top-[30%]' : 'w-[80%]'}
        title={modalTitle()}
      >
        {modalFlag === 'editAd' ? (
          <Suspense fallback={<EditFormSkeleton></EditFormSkeleton>}>
            <EditAdPage id={selectedAd._id} handleModalClose={handleModalClose}></EditAdPage>
          </Suspense>
        ) : (
          <div className='flex flex-col items-center gap-10'>
            <div className='flex gap-20 py-8'>
              <button
                className='flex items-center justify-center gap-1 rounded-lg bg-primary-yellow px-8 py-2 text-base font-medium'
                onClick={() => setIsModalOpen(false)}
              >
                <MdClose size={26} />
                {t('cancel')}
              </button>
              <button
                className='flex items-center justify-center gap-1 rounded-lg bg-primary-yellow px-8 py-2 text-base font-medium'
                onClick={() => {
                  if (modalFlag === 'deleteAd') {
                    deleteAd();
                  } else {
                    handleChangeStatus();
                  }
                }}
              >
                <MdDelete size={26} />
                {t('delete')}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </main>
  );
};

export default AdsListPage;
