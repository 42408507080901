import { CSS } from '@dnd-kit/utilities';
import { Item } from './Item';
import { defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';

function customAnimateLayoutChanges(args) {
  if (args.isSorting || args.wasDragging) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
}

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
    animateLayoutChanges: customAnimateLayoutChanges,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return <Item ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} activeid={props.activeid.toString()}></Item>;
}
