import { useCallback, useEffect, useState } from 'react';

import ProductService from '../../../services/ProductService';
import Card from '../../../components/Card/Card';
import PaginationContainer from '../../Ads/components/PaginationContainer';
import { get } from '../../../utils/localStorage';
import { isLoggedIn } from '../../../utils/auth';
import { useTranslation } from 'react-i18next';

const ITEMS_PER_PAGE = 12;

const SavedAdsContent = () => {
  const { t } = useTranslation();
  const [savedAds, setSavedAds] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const getData = useCallback(async () => {
    if (isLoggedIn()) {
      const response = await ProductService.getUserSavedAds(page, ITEMS_PER_PAGE);
      const data = response.data;

      setSavedAds(data.data);
      setTotal(data.count);
    } else {
      const data = await get('er_saved');
      if (data) {
        const response = await ProductService.getAdsArrayInfo({ adsArray: data });
        setSavedAds(response.data);
      }
    }
  }, [page]);

  useEffect(() => {
    getData();
  }, [getData]);

  const refreshData = () => getData();

  if (savedAds.length === 0)
    return (
      <section className='flex h-screen  justify-center'>
        <h1 className='m-auto text-2xl font-medium'>{t('noSavedAds')}</h1>
      </section>
    );
  return (
    <section className='mx-auto max-w-screen-xl py-20'>
      <div className='grid w-full place-items-center justify-around gap-7 px-3 sm:px-6 md:grid-cols-3 lg:grid-cols-4'>
        {savedAds.map((ad) => {
          return (
            <Card mainClass='relative' savedCard key={ad._id} ad={ad} type={ad.type} canRemove={true} refreshData={refreshData}></Card>
          );
        })}
      </div>
      {/* Pagination*/}
      <PaginationContainer
        paginationClass={'justify-center pt-5'}
        currentPage={page}
        setCurrentPage={setPage}
        pageSize={ITEMS_PER_PAGE}
        totalCount={total}
      ></PaginationContainer>
      {/* <div className='flex w-full justify-center py-12 md:pl-6'>
        {pageAds.numOfPages > 1 && <BtnPageContainer pageAds={pageAds} setPageAds={setPageAds} setCurrPage={setCurrPage} />}
      </div>  */}
    </section>
  );
};

export default SavedAdsContent;
