import { openDB } from 'idb';

const dbName = 'erent';
const storeName = 'erent';
const dbVersion = 1;

const dbPromise = openDB(dbName, dbVersion, {
  upgrade(db) {
    db.createObjectStore(storeName);
  },
});

export async function get(key) {
  return (await dbPromise).get(storeName, key);
}
export async function set(key, val) {
  return (await dbPromise).put(storeName, val, key);
}
export async function del(key) {
  return (await dbPromise).delete(storeName, key);
}
export async function clear() {
  return (await dbPromise).clear(storeName);
}
export async function keys() {
  return (await dbPromise).getAllKeys(storeName);
}
