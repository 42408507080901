import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import { AiOutlineRotateRight } from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';
import { Item } from './Item';
import { MdCancel } from 'react-icons/md';
import { SortableItem } from './SortableItem';
import dataURLtoFile from '../../../utils/dataURLtoFile';
import { generateRandomString } from '../../../utils/getRandomName';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { AD_IMAGES_LIMIT } from '../../../constants/constants';

const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

const LIMIT_OF_FILES_TO_UPLOAD = 15;

const SortableTable = ({ images, removeImage, className, setImages, setPosition, ...props }) => {
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState(images);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 1250,
        tolerance: 5,
      },
    })
  );

  /////////ROTATE IMAGE///////////
  async function rotateImage(imageIndex) {
    const rotatedItems = [...items];
    let imageToRotate = rotatedItems[imageIndex];

    if (!imageToRotate?.uploaded) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const img = new Image();
      img.src = `${import.meta.env.VITE_IMAGE_URL}${imageToRotate}`;
      img.crossOrigin = 'anonymous';
      const extension = `image/${imageToRotate.split('.')[1]}`;
      img.onload = () => {
        removeitem(imageToRotate);

        canvas.width = img.height;
        canvas.height = img.width;
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((90 * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        imageToRotate = canvas.toDataURL(extension); //'image/jpg'
        const name = generateRandomString(7) + `.${imageToRotate.split('.')[1]}`;
        rotatedItems[imageIndex] = {
          uploaded: true,
          name: name,
          type: extension,
          result: imageToRotate,
          lastModifiedDate: Date(),
          size: '1000',
          file: dataURLtoFile(imageToRotate, name),
        };

        setImages([...rotatedItems]);
        setItems([...rotatedItems]);
      };
    } else {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const img = new Image();
      img.src = imageToRotate.result;
      img.onload = () => {
        canvas.width = img.height;
        canvas.height = img.width;

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((90 * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        imageToRotate.result = canvas.toDataURL(imageToRotate.type);

        rotatedItems[imageIndex] = imageToRotate;

        setImages([...rotatedItems]);
        setItems([...rotatedItems]);
      };
    }
  }

  /////////ROTATE IMAGE///////////
  function removeitem(index) {
    if (!index?.uploaded) {
      const index2 = items.findIndex((i) => i === index);
      removeImage(index);
      items.splice(index2, 1);
    } else {
      const index2 = items.findIndex((i) => i.name === index.name);
      images.splice(index2, 1);
      items.splice(index2, 1);
    }
    setImages([...items]);
    setItems([...items]);
  }

  function handleFileChange(e) {
    let notUploaded = '';
    const filesToUpload = e.target.files;

    const numberOfElements = items.length + (filesToUpload?.length ?? 0);

    // const toRemoveFiles = numberOfElements > 10 ? numberOfElements % LIMIT_OF_FILES_TO_UPLOAD : 0;
    let toRemoveFiles = numberOfElements > LIMIT_OF_FILES_TO_UPLOAD ? numberOfElements % LIMIT_OF_FILES_TO_UPLOAD : 0;
    toRemoveFiles = numberOfElements === LIMIT_OF_FILES_TO_UPLOAD ? LIMIT_OF_FILES_TO_UPLOAD : toRemoveFiles;
    if (numberOfElements > LIMIT_OF_FILES_TO_UPLOAD) {
      toast.warning(t('cantAddMoreThenImages', { count: LIMIT_OF_FILES_TO_UPLOAD }), { autoClose: 7000 });
    }
    const numToIterate = e.target.files.length - toRemoveFiles;

    for (var i = 0; i < numToIterate; i++) {
      const file = e.target.files[i];
      if (!allowedFormats.includes(file.type)) {
        notUploaded += '  ' + file.name;
        continue;
      }
      let reader = new FileReader();
      reader.onloadend = () => {
        const image = {
          uploaded: true,
          name: file.name,
          type: file.type,
          result: reader.result,
          lastModifiedDate: file.lastModifiedDate?.toLocaleString('en-IN') ?? Date.now(),
          size: file.size,
          file: file,
        };
        setImages((prev) => [...prev, image]);
        setItems((prev) => [...prev, image]);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    if (notUploaded) {
      toast.warning(notUploaded + ' ' + t('invalidFormat'), { autoClose: 10000 });
    }
  }
  function handleDragStart(event) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (over === null) return;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  useEffect(() => {
    const n = items.map((i, index) => {
      return i.uploaded ? { index, name: i.name } : { index, name: i };
    });
    setPosition(n);
  }, [items, setPosition]);

  useEffect(() => {
    setItems(images);
  }, [images]);

  return (
    <div
      className={twMerge(
        `card card-compact max-h-auto scrollbar-hide bg-base-100 mt-5 h-fit min-h-[400px] w-full rounded-sm px-3 pt-3 lg:max-h-[70vh] lg:min-h-[400px] ${className} lg:overflow-scroll`
      )}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <div className='grid gap-4 break5:grid-cols-2 break9:grid-cols-3 break14:grid-cols-4'>
            {items.map((image, index) => (
              <div key={index} className='mb-1 flex flex-col'>
                <div
                  className={
                    'mb-1 h-6 flex-grow px-3 ' +
                    (Number(image) < 5 && ' bg-base-200 ') +
                    (Number(image) >= 5 && ' bg-base-300 ') +
                    (Number(image) == 4 && ' rounded-tr-2xl ') +
                    (Number(image) == 5 && ' ml-6 ')
                  }
                ></div>
                <div className='flex flex-row items-center gap-4 pb-2'>
                  {/* <button className='btn btn-ghost btn-xs mx-1 h-10 w-1 text-lg font-black'>+</button> */}
                  {/* <SortableItem removeItem={removeItem} key={id} id={id} activeid={(activeId == id).toString()} /> */}
                  <div className='w-full'>
                    <div className='flex flex-row items-center gap-4'>
                      <div className='grid w-full place-items-start pb-1'>
                        <button type='button' title='btnRotate' onClick={() => rotateImage(index)}>
                          <AiOutlineRotateRight className='text-[22px]' />
                        </button>
                      </div>
                      <div className='grid w-full place-items-end pb-1 text-red-500 opacity-30 hover:opacity-100'>
                        <button type='button' title='btnRotate' onClick={() => removeitem(image)}>
                          <MdCancel className='text-xl' />
                        </button>
                      </div>
                    </div>

                    <SortableItem
                      key={image}
                      id={image}
                      index={index}
                      imageUrl={image}
                      activeid={(activeId == image).toString()}
                      {...props}
                    />
                  </div>
                </div>
              </div>
            ))}
            {items.length < AD_IMAGES_LIMIT && (
              <div className='mb-1 ml-6 flex flex-col'>
                <div className='bg-base-100 mb-1 h-6 flex-grow px-3'></div>
                {/* <div className="flex-grow h-6 bg-base-100 mb-1 px-3"></div> */}
                <div className='flex flex-row items-center pb-2'>
                  <label
                    htmlFor={'imageInput'}
                    className='btn btn-outline btn-neutral flex h-28 flex-grow items-center justify-center rounded-md  border-2 border-dashed border-black border-opacity-50 bg-black bg-opacity-10 text-2xl'
                  >
                    <HiPlus size={25} />
                  </label>
                  <input
                    accept='.jpg, .jpeg, .png'
                    multiple
                    type='file'
                    id='imageInput'
                    onChange={handleFileChange}
                    style={{ display: 'none', visibility: 'hidden  ' }}
                  />
                </div>
              </div>
            )}
          </div>
        </SortableContext>
        <DragOverlay>{activeId ? <Item id={activeId} drag={'true'} /> : null}</DragOverlay>
      </DndContext>
    </div>
  );
};

export default SortableTable;
