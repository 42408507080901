import { Autocomplete, GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';

import FormRow from '../formRow/FormRow';
import logo from '../../assets/marker.png';
import { t } from 'i18next';
import { twMerge } from 'tailwind-merge';

const libraries = ['places'];

export default function MapComponent({
  markerPosition,
  setMarkerPosition,
  isDraggable = false,
  showSearch = false,
  classNameMap = 'h-full',
  address = '',
  city = '',
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });

  const setAddressIfNotEmpty = useCallback(() => {
    let addressToSearch = '';
    if (address && city) {
      addressToSearch = `${address}, ${city}`;
    }
    if (!address && city) {
      addressToSearch = `${city}`;
    }
    if (addressToSearch) {
      if (isLoaded) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder?.geocode({ address: addressToSearch }, (results, status) => {
          if (status === 'OK' && results.length > 0) {
            const location = results[0].geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            setMarkerPosition({ lat, lng });
          } else {
            console.error('Geocode was not successful for the following reason:', status);
          }
        });
      }
    }
  }, [city, address, isLoaded, setMarkerPosition]);

  useEffect(() => {
    setAddressIfNotEmpty();
  }, [setAddressIfNotEmpty]);

  const [autocomplete, setAutocomplete] = useState(null);

  if (loadError) return <div>Error loading Google Maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      {showSearch ? (
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={() => {
            if (autocomplete) {
              const place = autocomplete.getPlace();
              const newPosition = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              setMarkerPosition(newPosition);
            }
          }}
        >
          <FormRow className='my-2 shadow-md' placeholder={t('searchPlaceholder')}></FormRow>
        </Autocomplete>
      ) : null}
      <Map
        markerPosition={markerPosition}
        setMarkerPosition={setMarkerPosition}
        center={markerPosition}
        isDraggable={isDraggable}
        classNameMap={classNameMap}
      />
    </>
  );
}

function Map({ markerPosition, setMarkerPosition, center, isDraggable, classNameMap = 'h-full' }) {
  const customIcon = {
    url: logo,
    scaledSize: new window.google.maps.Size(31, 40),
  };

  const handleDragEnd = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setMarkerPosition(newPosition);
  };
  const options = isDraggable ? { streetViewControl: false } : { streetViewControl: false, mapTypeControl: false };
  if (!markerPosition.lat || !markerPosition.lng) return null;
  return (
    <GoogleMap zoom={10} center={center} options={options} mapContainerClassName={twMerge(`w-full ${classNameMap}`)}>
      <MarkerF draggable={isDraggable} onDragEnd={handleDragEnd} clickable icon={customIcon} key={1} position={markerPosition} />
    </GoogleMap>
  );
}
