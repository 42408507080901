import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
  },
  reducers: {
    storeUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('er_user', JSON.stringify(action.payload));
    },
    restoreUser: (state) => {
      state.user = JSON.parse(localStorage.getItem('er_user')) ?? null;
    },
    logoutUser: (state) => {
      state.user = {};
      localStorage.removeItem('er_user');
      localStorage.removeItem('er_token');
    },
  },
});

export const { storeUser, restoreUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
