import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn } from './auth';

function AuthGuard({ children }) {
  const location = useLocation();
  const from = location.pathname + location.search;
  return isLoggedIn() ? children : <Navigate to='/login' state={from} />;
}

export default AuthGuard;
