const Layout = ({ children }) => {
  return (
    <div style={{ backgroundColor: '#FAF3E0' }}>
      <div className='mx-auto max-w-[100%] xl:max-w-[90%] 2xl:max-w-[90%]'>
        <div className='bg-primary-gray'>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
